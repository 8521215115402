import React, { useRef, useState } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { removeIdiomaProfesor } from "../../../../components/utils/api";
import Cookies from "js-cookie";

const EliminarHabla = ({ idiomaBanderasItem, usuario, setUsuario }: any) => {
  const toast = useToast();
  let id_usuario = Cookies.get(`usuario`);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [selectedIdioma, setSelectedIdioma] = useState("");
  const [selectedIdiomaBandera, setselectedIdiomaBandera] = useState("");
  const removeLanguage = async (idiomaBanderasItem: any) => {
    setselectedIdiomaBandera(idiomaBanderasItem);
    setSelectedIdioma(idiomaBanderasItem.idioma);
    setShowConfirmationDialog(true);
  };
  const removeIdiomaPage = async () => {
    try {
      const data = await removeIdiomaProfesor(
        selectedIdiomaBandera,
        id_usuario
      );
      setUsuario(() => ({
        ...usuario,
        idiomas_habla: data?.idiomas_habla,
        idiomas_ensenha: data?.idiomas_ensenha,
      }));
      toast({
        title: "Éxito",
        description:
          "El idioma " + selectedIdioma + " ha sido eliminado correctamente",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Hubo un problema al eliminar el idioma " + selectedIdioma,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(error);
    }
  };
  return (
    <>
      <Button
        colorScheme="red"
        mr="3%"
        onClick={() => removeLanguage(idiomaBanderasItem)}
      >
        Eliminar
      </Button>

      {showConfirmationDialog && (
        <AlertDialog
          isOpen={showConfirmationDialog}
          leastDestructiveRef={cancelRef}
          onClose={() => setShowConfirmationDialog(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Eliminar Idioma que habla
              </AlertDialogHeader>

              <AlertDialogBody>
                ¿Estás seguro de que deseas eliminar el idioma{" "}
                <b>'{selectedIdioma}'?</b>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => setShowConfirmationDialog(false)}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    removeIdiomaPage();
                    setShowConfirmationDialog(false);
                  }}
                  ml={3}
                >
                  Eliminar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default EliminarHabla;
