import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Box, Grid, Text, Flex } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { flag } from "../../third-party/icons/flags";
import TituloSubtitulo from "../common/TituloSubtitulo";

function Languages() {
  const { languageData } = useSelector((state: RootState) => state.language);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, []);

  let templateColumns = "repeat(2, 1fr)"; // Default templateColumns for mobile

  if (window.innerWidth >= 768) {
    templateColumns = "repeat(4, 1fr)"; // 4 columns for tablet
  }

  if (window.innerWidth >= 1024) {
    templateColumns = "repeat(6, 1fr)"; // 6 columns for desktop
  }
  return (
    <>
      <TituloSubtitulo
        titulo={languageData.titulo_idioma}
        subtitulo={languageData.texto_idioma}
      ></TituloSubtitulo>

      <Grid templateColumns={templateColumns} gap={4}>
        {Array.from({ length: 6 })?.map((_, index) => (
          <Box
            key={index}
            p={6}
            borderWidth={1}
            borderStyle="solid"
            borderRadius="10px"
            borderColor="#e2e8f0"
            fontFamily='"Poppins", sans-serif'
            w="150px"
            h="130px"
            mb="15%"
          >
            <Flex height="100%" alignItems="center" justifyContent="center">
              <Box mb={3}>
                <Text fontSize="2xl" color="gray.400">
                  {flag.ua}
                </Text>
                <Text fontSize="12px" color="gray.600" fontWeight="600">
                  Українська
                </Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </Grid>
    </>
  );
}

export default Languages;
