import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import es from "../../components/languages/langport-es-CO.json";
import en from "../../components/languages/langport-en-US.json";
import ua from "../../components/languages/langport-uk-UA.json";
import Cookies from "js-cookie";
interface Idioma {
  mi_perfil: string;
  home: string;
  profesores: string;
  iniciar_sesion: string;
  registrarse: string;
  mensaje_bienvenidos: string;
  reservar_clase: string;
  acerca_de_nosotros: string;
  idiomas: string;
  elige_el_idioma: string;
  ingles: string;
  nuestroes_profesores: string;
  brindamos_el_mejor_servicio: string;
  acceso_rapido: string;
  quiero_ser_profesor: string;
  sobre_langport: string;
  contactanos: string;
  terminos_y_condiciones: string;
  profesor: string;
  preguntas_frecuentes: string;
  slide_titulo: string;
  slide_texto: string;
  titulo_idioma: string;
  texto_idioma: string;
  precio: string;
  boton_slide_reserva: string;
  boton_slide_nosotros: string;
  nuestro_profesores_titulo: string;
  nuestro_profesores_texto: string;
  espanhol: string;
  aleman: string;
  italiano: string;
  frances: string;
  portugues: string;
  chino: string;
  japones: string;
  arabe: string;
  polaco: string;
  turco: string;
  acerca_de_mi: string;
  una_clase: string;
  ocho_clases: string;
  clase_de_prueba: string;
  minutos: string;
  clases_a_reservar: string;
  reservar: string;
  informacion_del_profesor: string;
  se_unio_a_langport: string;
  clases_totales: string;
  puntaje_promedio: string;
  calendario: string;
  lunes: string;
  martes: string;
  miercoles: string;
  jueves: string;
  viernes: string;
  sabado: string;
  domingo: string;
  no_posee_horarios_disponibles: string;
  dejar_un_comentario: string;
  mensaje: string;
  enviar: string;
  comentarios: string;
  enero: string;
  febrero: string;
  marzo: string;
  abril: string;
  mayo: string;
  junio: string;
  julio: string;
  agosto: string;
  septiembre: string;
  octubre: string;
  noviembre: string;
  diciembre: string;
  usuario: string;
  mensaje_error_usuario: string;
  mensaje_error_vacio_usuario: string;
  mensaje_login_usuario_ok: string;
  contrasenha: string;
  olvido_su_contrasenha: string;
  texto_contactanos: string;
  terminos_y_condiciones_titulo: string;
  terminos_y_condiciones_texto: string;
  acerca_de_nosotros_texto: string;
}
interface LanguageData {
  [key: string]: string;
}

interface LanguageState {
  selectedLanguage: string;
  languageData: LanguageData;
  icon: string;
}

const languageMap: Record<string, Idioma> = {
  es,
  en,
  ua,
};
interface Idioma extends LanguageData {
  // add index signature for type 'string'
  [key: string]: any;
  // other properties and methods
}
function getLanguageData(languageCode: string): LanguageData {
  return languageMap[languageCode] || en;
}

let selectedLanguage: string = navigator.language.substring(0, 2);

const isValidLanguage =
  selectedLanguage.startsWith("es") ||
  selectedLanguage.startsWith("en") ||
  selectedLanguage.startsWith("ua");

if (!isValidLanguage) {
  selectedLanguage = "en";
}

const initialState: LanguageState = {
  selectedLanguage,
  languageData: getLanguageData(selectedLanguage),
  icon: selectedLanguage === "en" ? "us.png" : selectedLanguage + ".png",
};
export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setSelectedLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
      state.languageData = getLanguageData(action.payload);
      if (action.payload === "en") {
        state.icon = "us.png";
      } else {
        state.icon = action.payload + ".png";
      }
      Cookies.set(`language`, action.payload);
      Cookies.set(`icon`, state.icon);
    },
  },
});

export const { setSelectedLanguage } = languageSlice.actions;

export default languageSlice.reducer;
