import { Box, Text, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import TimezoneSelect from "../../components/common/TimezoneSelect ";
import { fetchTimezones } from "../../components/utils/api";
import { Formik } from "formik";
import * as Yup from "yup";
import useUserIdFromToken from "../../utils/useUserIdFromToken";
import { useUser } from "../../components/utils/userApi";
import { ProfesorInformacion } from "../../components/teacher/ProfesorInformacion";
import { useProfesor } from "../../components/utils/teacherApi";
import { useParams } from "react-router-dom";
import { ComentariosProfesor } from "../../components/teacher/ComentariosProfesor";
import { InformacionProfesor } from "../../components/teacher/InformacionProfesor";
import { CursosProfesor } from "../../components/teacher/CursosProfesor";
import { AcercaDelProfesor } from "../../components/teacher/AcercaDelProfesor";
import Calendario from "../../components/dashboard/calendario/Calendario";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
import { useAuthStore } from "../../app/authStore";

export const DetalleProfesor = () => {
  const { profesor, idProfesor } = useParams();

  const { user } = useAuthStore((state: any) => state);
  const { data: usuario } = useUser(user?.id);
  const [timezones, setTimezones] = useState([]);
  const isFirstRender = useRef(true);

  const [currentFecha, setCurrentFecha] = useState<string>("");

  const obtenerTimezones = async () => {
    try {
      const data = await fetchTimezones();
      setTimezones(data);
    } catch (error) {
      console.error(error);
    }
  };

  //const { data: profesor } = useProfesor(idProfesor, "");

  const handleSubmit = (values: any) => {
    const { timezone } = values as {
      timezone: number;
    };
  };
  const validationSchema = Yup.object({
    timezone: Yup.string().required("Campo requerido"),
  });
  useEffect(() => {
    if (isFirstRender.current) {
      obtenerTimezones();
      //pageViewed("");
      isFirstRender.current = false;
    }
  }, []);

  const { data: infoProfesor } = useProfesor(idProfesor, currentFecha);

  return (
    <Box marginLeft="5%" marginTop="5%" marginRight="5%">
      <Box className="container">
        <Box
          p={6}
          borderWidth={1}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#e2e8f0"
          fontFamily='"Poppins", sans-serif'
        >
          <Text fontSize="2xl" color="gray.400">
            Zona horaria
          </Text>

          <Formik
            enableReinitialize={true}
            initialValues={{
              timezone: usuario?.timezone,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <TimezoneSelect
              name="timezone"
              timezones={timezones}
              disabled={""}
            />
          </Formik>
        </Box>
        <Box
          p={6}
          borderWidth={1}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#e2e8f0"
          fontFamily='"Poppins", sans-serif'
        >
          <ProfesorInformacion profesor={infoProfesor}></ProfesorInformacion>
        </Box>
        <Box
          p={6}
          borderWidth={1}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#e2e8f0"
          fontFamily='"Poppins", sans-serif'
        >
          <Text fontSize="2xl" color="gray.400">
            Reserva
          </Text>
          {Number(usuario?.tipo_usuario) !== 2 && infoProfesor ? (
            <Calendario profesor={infoProfesor} alumno={usuario} />
          ) : (
            <Alert status="warning">
              <AlertIcon />
              <AlertTitle>
                No puedes reservar clase siendo profesor. Debes crear una cuenta
                como alumno.
              </AlertTitle>
            </Alert>
          )}
        </Box>
        <Box
          p={6}
          borderWidth={1}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#e2e8f0"
          fontFamily='"Poppins", sans-serif'
        >
          <AcercaDelProfesor profesor={infoProfesor}></AcercaDelProfesor>
        </Box>
        <Box
          p={6}
          borderWidth={1}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#e2e8f0"
          fontFamily='"Poppins", sans-serif'
        >
          <CursosProfesor profesor={infoProfesor}></CursosProfesor>
        </Box>
        <Box
          p={6}
          borderWidth={1}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#e2e8f0"
          fontFamily='"Poppins", sans-serif'
        >
          <InformacionProfesor profesor={infoProfesor}></InformacionProfesor>
        </Box>
        <Box
          p={6}
          borderWidth={1}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#e2e8f0"
          fontFamily='"Poppins", sans-serif'
        >
          <ComentariosProfesor profesor={infoProfesor}></ComentariosProfesor>
        </Box>
      </Box>
    </Box>
  );
};
