import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { QueryClient, QueryClientProvider } from "react-query";
import LayoutUsuario from "./components/LayoutUsuario";
import AppRoutes from "./Routes";
import Layout from "./components/Layout";
const AppContent = () => {
  const location = useLocation();
  const isDashboard = location.pathname.includes("dashboard");

  return (
    <React.Fragment>
      {isDashboard ? (
        <LayoutUsuario>
          <AppRoutes />
        </LayoutUsuario>
      ) : (
        <Layout>
          <AppRoutes />
        </Layout>
      )}
    </React.Fragment>
  );
};

const App = () => {
  const queryClient = new QueryClient();
  return (
    <Provider store={store}>
      <ChakraProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </QueryClientProvider>
      </ChakraProvider>
    </Provider>
  );
};

export default App;
