import React from "react";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import EliminarHabla from "./EliminarHabla";
import EditarHabla from "./EditarHabla";
const imgFlag = {
  width: "10%",
};

const IdiomasHabla = ({ usuario, setUsuario }: any) => {
  return (
    <Box>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Idioma</Th>
            <Th>Nivel</Th>
            <Th>Acciones</Th>
          </Tr>
        </Thead>
        <Tbody>
          {usuario?.idiomas_habla?.map(
            (idiomaBanderasItem: any, index: number) => (
              <Tr key={index}>
                <Td>
                  <img
                    src={`https://www.langport.net/images/flags-medium/${idiomaBanderasItem.bandera}`}
                    alt={idiomaBanderasItem.idioma}
                    width={imgFlag.width}
                  />
                  {idiomaBanderasItem.idioma}
                </Td>
                <Td>{idiomaBanderasItem.idioma_nivel}</Td>
                <Td>
                  <EliminarHabla
                    idiomaBanderasItem={idiomaBanderasItem}
                    setUsuario={setUsuario}
                    usuario={usuario}
                  ></EliminarHabla>
                  <EditarHabla
                    idiomaBanderasItem={idiomaBanderasItem}
                    setUsuario={setUsuario}
                    usuario={usuario}
                  ></EditarHabla>
                </Td>
              </Tr>
            )
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default IdiomasHabla;
