import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import Comision from "../../../components/dashboard/contacto/Comision";
import Contactos from "../../../components/dashboard/contacto/Contactos";
import { getComision } from "../../../components/utils/api";
import { perfilUsuario } from "../../../interfaces/TipoUsuario.interface";
import ProtectedRoute from "../../../components/ProtectedRoute";

const Configuraciones = () => {
  const allowedTypes = [perfilUsuario.superAdministrador];
  const [comision, setComision] = useState(0);
  const fetchComision = async () => {
    try {
      const comision = await getComision();
      setComision(comision || "");
    } catch (error) {
      console.error("Error al obtener la comisión:", error);
    }
  };

  useEffect(() => {
    fetchComision();
  }, []);

  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box>
        <Box marginLeft="5%" marginTop="5%" marginRight="5%">
          {comision && <Comision comision={comision}></Comision>}
        </Box>
        <Box marginLeft="5%" marginTop="5%" marginRight="5%">
          {comision && <Contactos comision={comision}></Contactos>}
        </Box>
      </Box>
    </ProtectedRoute>
  );
};

export default Configuraciones;
