import React, { useEffect, useRef, useState } from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";

interface PdfInputProps {
  onChange: (pdfBase64: string) => void;
}

const PdfInput: React.FC<PdfInputProps> = ({ onChange }) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState<string | null>(null);

  // Función para convertir el archivo PDF a una cadena Base64
  const convertToBase64 = (
    file: File
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handlePdfChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const maxSize = 1 * 1024 * 1024; // 1MB in bytes
      if (selectedFile.size > maxSize) {
        alert("El archivo PDF debe tener un tamaño máximo de 1MB.");
        setPdfFile(null);

        // Reset the file input value
        if (inputFileRef.current) {
          inputFileRef.current.value = "";
        }
        return;
      } else {
        setPdfFile(selectedFile);

        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          setPdfPreviewUrl(fileReader.result as string);
        };
        fileReader.readAsDataURL(selectedFile);
        const pdfBase64 = (await convertToBase64(selectedFile)) as string;
        onChange(pdfBase64);
      }
    }
  };

  return (
    <FormControl>
      <FormLabel>PDF</FormLabel>
      <Input
        type="file"
        accept=".pdf"
        onChange={handlePdfChange}
        ref={inputFileRef}
      />
      {pdfPreviewUrl && <embed src={pdfPreviewUrl} width="100%" height="500" />}
    </FormControl>
  );
};

export default PdfInput;
