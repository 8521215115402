import axios from "axios";
import { env } from "./env";
const API_URL = env("REACT_APP_API_URL");

export const fecthPaises = async () => {
  try {
    const response = await axios.post(`${API_URL}pais/get_all_pais`, {});
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener los paises: ${error}`);
  }
};
