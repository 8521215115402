import React from "react";
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";

export const CursosProfesor = ({ profesor }: any) => {
  const tieneCursos = profesor?.cursos && profesor?.cursos?.length > 0;

  return (
    <Box mt={5}>
      <Text fontSize="2xl" color="gray.400" mb={4}>
        Cursos
      </Text>

      {tieneCursos ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Nombre Escuela</Th>
              <Th>Nombre Curso</Th>
              <Th>Año</Th>
            </Tr>
          </Thead>
          <Tbody>
            {profesor?.cursos?.map((curso: any, index: number) => (
              <Tr key={index}>
                <Td>{curso.nombre_escuela}</Td>
                <Td>{curso.nombre_curso}</Td>
                <Td>{curso.anho}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text color="red.500">No posee cursos ingresados.</Text>
      )}
    </Box>
  );
};
