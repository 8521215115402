import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Curso } from "../../../interfaces/Usuario.interface";
import TablaCursos from "./TablaCursos";
import AgregarCurso from "./AgregarCurso";
import { useUser } from "../../utils/userApi";
import { useAuthStore } from "../../../app/authStore";

const CursosUsuario = () => {
  const { user } = useAuthStore((state: any) => state);
  const { data: usuario } = useUser(user?.id);
  const isFirstRender = useRef(true);
  const [cursos, setCursos] = useState<Curso[]>([]);

  const obtenerCursos = useCallback(() => {
    try {
      if (usuario?.validaciones) {
        setCursos(
          usuario?.validaciones?.cursos ? usuario.validaciones.cursos : []
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [usuario]);

  useEffect(() => {
    obtenerCursos();
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [usuario, obtenerCursos]);

  return (
    <Box marginLeft="5%" marginTop="5%" marginRight="5%">
      <Box className="container">
        <Box
          p={6}
          borderWidth={1}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#e2e8f0"
          fontFamily='"Poppins", sans-serif'
        >
          <Box mb={3}>
            <Text fontSize="2xl" color="gray.400" mb={5}>
              Cursos
            </Text>
            <AgregarCurso cursos={cursos} setCursos={setCursos}></AgregarCurso>
          </Box>
          <TablaCursos cursos={cursos} setCursos={setCursos}></TablaCursos>
        </Box>
      </Box>
    </Box>
  );
};

export default CursosUsuario;
