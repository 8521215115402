import React from "react";
import { Box, Select, Text } from "@chakra-ui/react";
import { Field, ErrorMessage } from "formik";
import FormLabelInput from "../dashboard/FormLabelInput";

interface Country {
  countrycode: string;
  countryname: string;
}
interface CountrySelectProps {
  id: string;
  name: string;
  placeholder: string;
  title: string;
  paises: any;
}
const CountrySelect: React.FC<CountrySelectProps> = ({
  id,
  name,
  placeholder,
  title,
  paises,
}) => {
  return (
    <Box flex={{ md: 1 }}>
      <FormLabelInput required>{title} </FormLabelInput>
      <Field id={id} as={Select} name={name} placeholder={placeholder}>
        {paises?.map((pais: Country) => (
          <option key={pais?.countrycode} value={pais?.countryname}>
            {pais?.countryname}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name}>
        {(errorMsg) => (
          <Text color="red.500" mt={2}>
            {errorMsg}
          </Text>
        )}
      </ErrorMessage>
    </Box>
  );
};

export default CountrySelect;
