import { createSlice } from "@reduxjs/toolkit";
import { registerUser } from "./authActions";
const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

const initialState = {
  loading: false,
  userInfo: {
    email: "",
    userId: "",
    userType: "",
  }, // for user object
  token, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: {
    [registerUser.pending as any]: (state: any) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled as any]: (state: any, { payload }: any) => {
      state.loading = false;
      state.success = true;
    },
    [registerUser.rejected as any]: (state: any, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default authSlice.reducer;
export const { setUserInfo } = authSlice.actions;
