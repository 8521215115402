import {
  Table,
  Text,
  Box,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Divider,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { fetchHorarioProfesor } from "../../utils/userApi";
interface Horario {
  id: string;
  hora_inicio: string;
  hora_fin: string;
  id_dia: string;
  id_hora: string;
  nombre_dia: string;
  reg_estado: string;
  fecha_creacion: string;
  usuario_id: string;
  horario_informacion_id: string;
  fecha_dia: string;
  // ... cualquier otra propiedad que pueda tener horario
}
const TableBody = ({ profesor, onUpdateSelectedHours }: any) => {
  const [selectedHours, setSelectedHours] = useState<Record<string, Horario>>(
    {}
  );

  // Manejador de clic en una celda de hora
  const handleCellClick = (horario: any) => {
    const key = `${horario.fecha_dia}-${horario.hora_inicio}`;
    let updatedHours;

    if (selectedHours[key]) {
      // Si está seleccionada, eliminarla del objeto
      updatedHours = { ...selectedHours };
      delete updatedHours[key];
    } else {
      // Si no está seleccionada, agregarla al objeto
      updatedHours = {
        ...selectedHours,
        [key]: horario,
      };
    }
    // Convertir el objeto en un array y ordenarlo
    const sortedArray = Object.entries(updatedHours).sort((a, b) => {
      // Comparar por fecha
      if (a[1].fecha_dia < b[1].fecha_dia) return -1;
      if (a[1].fecha_dia > b[1].fecha_dia) return 1;

      // Si las fechas son iguales, comparar por hora
      if (a[1].hora_inicio < b[1].hora_inicio) return -1;
      if (a[1].hora_inicio > b[1].hora_inicio) return 1;

      return 0;
    });

    // Convertir el array ordenado de nuevo en un objeto
    const sortedObject: { [key: string]: any } = {};
    sortedArray.forEach(([key, value]) => {
      sortedObject[key] = value;
    });
    setSelectedHours(sortedObject);
    onUpdateSelectedHours(sortedObject);
  };

  // Divide los horarios en grupos de 7
  const horariosGrupos = [];
  for (let i = 0; i < profesor?.horarios?.length; i += 7) {
    horariosGrupos.push(profesor?.horarios?.slice(i, i + 7));
  }

  return (
    <>
      <Tbody>
        {horariosGrupos?.map((grupo, index) => (
          <Tr key={index} _hover={{ backgroundColor: "green.100" }}>
            {grupo.map((horario: any) => (
              <Td
                key={horario.hora_inicio + horario.id}
                onClick={() => {
                  if (horario.reg_estado === "0") {
                    return;
                  }
                  handleCellClick(horario);
                }}
              >
                <Box textAlign="center">
                  <Button
                    bg={
                      selectedHours[
                        `${horario.fecha_dia}-${horario.hora_inicio}`
                      ]
                        ? "orange"
                        : horario.reg_estado === "1"
                        ? "#08964c"
                        : "gray"
                    }
                    color="white"
                  >
                    {horario.hora_inicio}
                    {/* solo para pruebas - {horario.reg_estado}*/}
                  </Button>
                </Box>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </>
  );
};

export default TableBody;
