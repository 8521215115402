import { Grid, Box, Icon, Text, Flex } from "@chakra-ui/react";
import { StarIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { FaRegCalendar, FaChalkboard, FaStar } from "react-icons/fa";

import React from "react";
import RatingStars from "../common/RatingStars";

export const InformacionProfesor = ({ profesor }: any) => {
  return (
    <>
      <Text fontSize="2xl" color="gray.400">
        Informacion del profesor
      </Text>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <Box textAlign="center">
          <Icon as={FaRegCalendar} boxSize={6} mb={2} />
          <Text fontWeight="bold" color="#717f94">
            Se unió a langport
          </Text>
          <Text fontSize="14" mt={2} color="#717f94">
            2023
          </Text>
        </Box>

        <Box textAlign="center">
          <Icon as={FaChalkboard} boxSize={6} mb={2} />
          <Text fontWeight="bold" color="#717f94">
            Clases totales
          </Text>
          <Text fontSize="14" mt={2} color="#717f94">
            1
          </Text>
        </Box>

        <Box textAlign="center">
          <StarIcon as={FaStar} boxSize={6} mb={2} color="yellow.400" />
          <Text fontWeight="bold" color="#717f94">
            Puntaje promedio
          </Text>{" "}
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <RatingStars rating={profesor?.rating_promedio + 2} />
          </Box>
        </Box>
      </Grid>
    </>
  );
};
