import React from "react";
import { Box, Link, UnorderedList, Text } from "@chakra-ui/react";
import { RootState } from "../app/store";
import { useSelector } from "react-redux";
import { Facebook, Instagram } from "../third-party/icons";
import { useAuthStore } from "../app/authStore";
type LinkLangportProps = {
  url: string;
  nombrelink: string;
};
const LinkLangport = ({ url, nombrelink }: LinkLangportProps) => {
  return (
    <li>
      <a href={url}>
        <span className="icon ti-angle-left"></span>
        <Text className="text" color="#717f94" fontSize="12px" fontWeight="610">
          {nombrelink}
        </Text>
      </a>
    </li>
  );
};
const Footer = () => {
  const { languageData } = useSelector((state: RootState) => state.language);
  const { user } = useAuthStore((state: any) => state);

  return (
    <Box>
      <Box display="flex" height="100%">
        <Box width="25%"></Box>
        <Box width="25%">
          <Box className="widget widget-links">
            <Box className="widget-title">
              <Text
                color="#5b70f9"
                fontSize="16px"
                position="relative"
                fontWeight="600"
                dangerouslySetInnerHTML={{
                  __html: languageData.acceso_rapido,
                }}
              ></Text>
            </Box>
            <Box className="widget-body">
              <UnorderedList listStyleType="none">
                <LinkLangport
                  url="#"
                  nombrelink={languageData.quiero_ser_profesor}
                />
                <LinkLangport
                  url={"/profesores"}
                  nombrelink={languageData.profesores}
                />

                {!user?.id ? (
                  <>
                    <LinkLangport
                      url={"/login"}
                      nombrelink={languageData.iniciar_sesion}
                    />
                    <LinkLangport
                      url={"/registro"}
                      nombrelink={languageData.registrarse}
                    />
                  </>
                ) : (
                  <LinkLangport
                    url={"/dashboard"}
                    nombrelink={languageData.mi_perfil}
                  />
                )}
              </UnorderedList>
            </Box>
          </Box>
        </Box>
        <Box width="25%">
          <Box className="widget widget-links">
            <Box className="widget-title">
              <Text
                color="#5b70f9"
                fontSize="16px"
                position="relative"
                fontWeight="600"
                dangerouslySetInnerHTML={{
                  __html: languageData.sobre_langport,
                }}
              ></Text>
            </Box>
            <Box className="widget-body">
              <UnorderedList listStyleType="none">
                <LinkLangport
                  url={"/acerca_nosotros"}
                  nombrelink={languageData.acerca_de_nosotros}
                />
                <LinkLangport
                  url={"/contacto"}
                  nombrelink={languageData.contactanos}
                />
                <LinkLangport
                  url={"/terminos_y_condiciones"}
                  nombrelink={languageData.terminos_y_condiciones}
                />
                <LinkLangport
                  url={"/politica_de_privacidad"}
                  nombrelink={languageData.politica_de_privacidad}
                />
              </UnorderedList>
            </Box>
          </Box>
        </Box>
        <Box width="25%"></Box>
      </Box>
      <FooterCopyright />
    </Box>
  );
};

const FooterCopyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Box marginLeft="5%" marginRight="5%">
      <Box
        w="100%"
        h="2px"
        display="block"
        mb="15px"
        bgColor="#e6e6e6"
        mt="3%"
      ></Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Text as="p" color="#717f94">
            © {currentYear} All Rights Reserved
          </Text>
        </Box>
        <Box display="flex">
          <Box margin="12px">
            <Link href="https://www.facebook.com/langport_es/">
              <Facebook fontSize="30px" color="blue" />
            </Link>
          </Box>
          <Box margin="12px">
            <Link href="https://www.instagram.com/langport_es/">
              <Instagram fontSize="30px" color="blue" />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
