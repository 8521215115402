import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { Switch } from "@chakra-ui/react";
import { addHorarioProfesor, fetchHorario } from "../../utils/api";
import useUserIdFromToken from "../../../utils/useUserIdFromToken";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { useAuthStore } from "../../../app/authStore";

interface TimeSlot {
  id: number;
  id_dia: number;
  nombre_dia: string;
  start: string;
  end: string;
  reg_estado: number;
}

interface CustomCheckboxProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

const Horario = () => {
  const toast = useToast();
  const [timeSlotsByWeek, setTimeSlotsByWeek] = useState<TimeSlot[][]>([]); // Agregar esta línea

  //const timeSlotsByWeek = generateTimeSlotsByWeek();

  const [selectedTimeSlots, setSelectedTimeSlots] = useState<{
    [id: number]: boolean;
  }>({});
  const [selectedTimeSlotInfo, setSelectedTimeSlotInfo] = useState<{
    [id: number]: TimeSlot;
  }>({});

  const { user } = useAuthStore((state: any) => state);
  const handleTimeSlotToggle = (timeSlot: TimeSlot, isChecked: boolean) => {
    setSelectedTimeSlotInfo((prevSelectedTimeSlotInfo) => {
      // Eliminar la entrada del timeSlot si isChecked es false
      if (isChecked) {
        return {
          ...prevSelectedTimeSlotInfo,
          [timeSlot.id]: timeSlot,
        };
      } else {
        const { [timeSlot.id]: omitTimeSlot, ...updatedSelectedTimeSlotInfo } =
          prevSelectedTimeSlotInfo;
        return updatedSelectedTimeSlotInfo;
      }
    });
    setSelectedTimeSlots((prevSelectedTimeSlots) => {
      if (isChecked) {
        return {
          ...prevSelectedTimeSlots,
          [timeSlot.id]: true,
        };
      } else {
        const { [timeSlot.id]: omitTimeSlot, ...updatedSelectedTimeSlots } =
          prevSelectedTimeSlots;
        return updatedSelectedTimeSlots;
      }
    });
  };
  // Agregar función para actualizar reg_estado en timeSlotsByWeek
  const updateTimeSlotsByHorarios = useCallback(
    (timeSlotsByWeek: TimeSlot[][], horariosProfesor: any) => {
      const updatedTimeSlotsByWeek = timeSlotsByWeek?.map((weekSlots) => {
        return weekSlots?.map((timeSlot) => {
          const matchingHorario = horariosProfesor.find(
            (horario: any) =>
              Number(horario.id_horario_informacion) === timeSlot.id
          );
          if (matchingHorario) {
            handleTimeSlotToggle(timeSlot, true);
            return {
              ...timeSlot,
              reg_estado: 1,
              isChecked: true, // Actualizar isChecked a true si hay un matchingHorario
            };
          } else {
            return timeSlot;
          }
        });
      });

      return updatedTimeSlotsByWeek;
    },
    [] // Add dependencies here
  );

  useEffect(() => {}, [selectedTimeSlots]);

  useEffect(() => {}, [selectedTimeSlotInfo]);
  useEffect(() => {
    // Generar los timeSlots una vez que el componente esté montado
    const generatedTimeSlots = generateTimeSlotsByWeek();

    setTimeSlotsByWeek(generatedTimeSlots);
  }, []); // El segundo argumento del useEffect es un array vacío para que solo se ejecute una vez al montar el componente

  // Agrega la función handleSave para guardar los horarios seleccionados
  const handleSave = async () => {
    try {
      // Guardar los horarios seleccionados
      await addHorarioProfesor(selectedTimeSlotInfo, user?.id);
      toast({
        title: "Éxito",
        description: "El horario fue actualizado",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Hubo un error al actualizar el horario",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const getHorarios = useCallback(async () => {
    try {
      const data = await fetchHorario(user?.id);
      // Actualiza la información en timeSlotsByWeek según los horarios del profesor
      updateTimeSlotsByHorarios(timeSlotsByWeek, data);
    } catch (error) {
      console.error("Error al guardar los horarios:", error);
    }
  }, [user?.id, timeSlotsByWeek, updateTimeSlotsByHorarios]);

  useEffect(() => {
    getHorarios();
  }, [getHorarios, user?.id]);

  return (
    <>
      <Box textAlign="center">
        <Button onClick={handleSave} colorScheme="blue" mt="4">
          Save
        </Button>
      </Box>
      <Box>
        <Table>
          <Thead>
            <Tr>
              <Th textAlign="center">Horas</Th>
              <Th textAlign="center">Lu</Th>
              <Th textAlign="center">Ma</Th>
              <Th textAlign="center">Mié</Th>
              <Th textAlign="center">Jue</Th>
              <Th textAlign="center">Vie</Th>
              <Th textAlign="center">Sáb</Th>
              <Th textAlign="center">Dom</Th>
            </Tr>
          </Thead>
          <Tbody>
            {timeSlotsByWeek?.map((weekSlots, weekIndex) => (
              <Tr key={weekIndex}>
                <Td textAlign="center">
                  {weekSlots[0].start} - {weekSlots[0].end}
                </Td>
                {weekSlots?.map((timeSlot, timeSlotIndex) => (
                  <Td key={timeSlotIndex} textAlign="center">
                    <CustomCheckbox
                      isChecked={selectedTimeSlots[timeSlot.id] ?? false}
                      onChange={(isChecked: boolean) =>
                        handleTimeSlotToggle(timeSlot, isChecked)
                      }
                    />
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

// Helper function to generate time slots for each 30 minutes
const generateTimeSlotsByWeek = () => {
  const daysOfWeek = [
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
    "domingo",
  ];
  const timeSlots: TimeSlot[] = [];
  const startTime = new Date();
  startTime.setHours(0, 0, 0, 0); // Set the start time to 00:00

  for (let i = 0; i < 48; i++) {
    for (let day = 0; day < 7; day++) {
      const start = new Date(
        startTime.getTime() + (day * 48 + i) * 30 * 60 * 1000
      );
      const end = new Date(start.getTime() + 25 * 60 * 1000);
      const startTimeFormatted = formatTime(start);
      const endTimeFormatted = formatTime(end);
      const dayOfWeek = daysOfWeek[day];
      const timeSlot: TimeSlot = {
        id: day * 48 + i + 1,
        id_dia: day + 1,
        nombre_dia: dayOfWeek,
        start: startTimeFormatted,
        end: endTimeFormatted,
        reg_estado: 0,
      };
      timeSlots.push(timeSlot);
    }
  }

  // Agrupar los timeSlots por semana (cada 7 registros)
  const timeSlotsByWeek: TimeSlot[][] = [];
  for (let i = 0; i < timeSlots.length; i += 7) {
    const weekSlots = timeSlots.slice(i, i + 7);
    timeSlotsByWeek.push(weekSlots);
  }
  /*
  let insertQuery = [];
  for (const weekSlots of timeSlotsByWeek) {
    for (const timeSlot of weekSlots) {
      const { id, id_dia, nombre_dia, start, end, reg_estado } = timeSlot;

      insertQuery[
        id
      ] = `INSERT INTO tu_tabla (id, id_dia, nombre_dia, horario_inicio, hora_fin, reg_estado) VALUES (${id}, ${id_dia}, '${nombre_dia}', '${start}', '${end}', ${reg_estado});`;

      // Aquí puedes usar la consulta insertQuery para ejecutarla en tu base de datos
      // Por ejemplo, si estás usando una biblioteca como node-mysql, sería algo como:
      // connection.query(insertQuery, (error, result) => {
      //   if (error) throw error;
      //   console.log("Registro insertado correctamente");
      // });
    }
  }
  console.log(insertQuery);
  */
  return timeSlotsByWeek;
};

// Helper function to format time as HH:MM
const formatTime = (time: Date) => {
  const hours = String(time.getHours()).padStart(2, "0");
  const minutes = String(time.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  isChecked,
  onChange,
}) => {
  const handleToggle = () => {
    onChange(!isChecked);
  };

  return (
    <Switch
      isChecked={isChecked}
      onChange={handleToggle}
      colorScheme={isChecked ? "green" : "red"}
      size="lg"
    />
  );
};

export default Horario;
