import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  VStack,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { actualizar_clase_profesor } from "../../../../components/utils/api";
import ClasesPrecios from "./ClasesPrecios";
import Cookies from "js-cookie";
import SelectIdioma from "../../../../components/common/idiomasDisponibles";
import Niveles from "../../../../components/common/Niveles";
import { Form, Formik } from "formik";
import * as Yup from "yup";

interface MyModalProps {
  usuario: any;
  setUsuario: any;
  idioma_ensenha: any;
}

const buttonSize = {
  width: "132px",
  height: "40px",
};

// Radio de los bordes de los botones
const buttonBorderRadius = "6px";

const EditarEnsenha: React.FC<MyModalProps> = ({
  usuario,
  setUsuario,
  idioma_ensenha,
}) => {
  const toast = useToast();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const onChangeClase = (clases: any) => {
    setPruebaClase(clases.ClasedePrueba.data);
    setUnaClase(clases.PaquetedeClases.data);
    setPaqueteClases(clases.UnaClase.data);
  };

  const id_usuario = Cookies.get(`usuario`);

  const [selectedNivel, setSelectedNivel] = useState<{
    value: string;
    id: number;
  }>({ value: idioma_ensenha?.idioma_nivel, id: idioma_ensenha?.nivel });

  const [pruebaClase, setPruebaClase] = React.useState(
    idioma_ensenha.clase_prueba
  );

  const [unaClase, setUnaClase] = React.useState(idioma_ensenha.una_clase);

  const [paqueteClases, setPaqueteClases] = React.useState(
    idioma_ensenha.ocho_clases
  );

  /* fin idiomas */
  const [selectedIdioma, setSelectedIdioma] = useState<{
    value: string;
    id: string;
  }>({ value: idioma_ensenha.idioma, id: idioma_ensenha.id_idioma });

  const handleGuardar = async (values: any) => {
    try {
      const newLanguage = {
        idioma: idioma_ensenha?.id_idioma,
        nivel: selectedNivel?.id,
        pruebaClase: pruebaClase,
        paqueteClases: paqueteClases,
        unaClase: unaClase,
      };
      const data = await actualizar_clase_profesor(newLanguage, id_usuario);
      setUsuario((prevUsuario: any) => ({
        ...prevUsuario,
        idiomas_ensenha: data?.idiomas_ensenha,
      }));
      toast({
        title: "Éxito",
        description:
          "El idioma " + idioma_ensenha.idioma + " ha sido modificado",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          "Hubo un error modificar el idioma " + idioma_ensenha.idioma,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(error);
    }

    handleCloseModal();
  };

  const validationSchema = Yup.object({
    idioma: Yup.string().required("Idioma is required"),
    nivel: Yup.string().required("Nivel is required"),
    /*
    clasesPrecios: Yup.array().of(
      Yup.object().shape({
        precio: Yup.number()
          .typeError("padrePrecio debe ser un número")
          .required("padrePrecio es requerido"),
        / *
          .test("is-pruebaClase", "precio pruebaClase is required", (value) => {
            console.log(
              "🚀 ~ file: AgregarEnsenha.tsx:118 ~ .test ~ value:",
              value
            );
            // Add your custom validation logic here
            // You can check if the value is required based on the "clase_prueba" field
            //const clasePrueba = value?.clase_prueba;
            //return clasePrueba === "1" ? !!value?.precio : true;
            //),
          }),
          * /
      })
      ),
      */
  });
  return (
    <>
      <Button
        variant="solid"
        backgroundColor="#28a745"
        color="white"
        size="sm"
        w={buttonSize.width}
        h={buttonSize.height}
        borderRadius={buttonBorderRadius}
        borderColor="#28a745"
        onClick={handleOpenModal}
      >
        Editar
      </Button>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar idiomas que enseña</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Box w="100%">
                <HStack spacing={4}>
                  <Formik
                    initialValues={{
                      idioma: selectedIdioma.value,
                      nivel: selectedNivel.value,
                      clasesPrecios: {
                        ClasedePrueba: {
                          data: pruebaClase,
                          set: setPruebaClase,
                        },
                        UnaClase: { data: unaClase, set: setUnaClase },
                        PaquetedeClases: {
                          data: paqueteClases,
                          set: setPaqueteClases,
                        },
                      },
                      ClasedePrueba: [
                        {
                          precio: pruebaClase[0]?.precio ?? "",
                        },
                        {
                          precio: pruebaClase[1]?.precio ?? "",
                        },
                      ],
                      UnaClase: [
                        {
                          precio: pruebaClase[0]?.precio ?? "",
                        },
                        {
                          precio: pruebaClase[1]?.precio ?? "",
                        },
                      ],
                      PaquetedeClases: [
                        {
                          precio: paqueteClases[0]?.precio ?? "",
                        },
                        {
                          precio: paqueteClases[1]?.precio ?? "",
                        },
                      ],
                    }}
                    onSubmit={handleGuardar}
                    validationSchema={validationSchema}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <SelectIdioma
                          id="idioma"
                          name="idioma"
                          placeholder={"Seleccione Idioma"}
                          idiomasData=""
                          selectedIdioma={selectedIdioma}
                          usuario={usuario}
                          ensenha={1}
                          isEditing={true}
                        />
                        <Niveles
                          id="nivel"
                          name="nivel"
                          placeholder={"Seleccione Nivel"}
                          idiomaBanderasItem={[]}
                          ensenha={1}
                          selectedNivel={selectedNivel}
                        ></Niveles>
                        <ClasesPrecios
                          id="clasesPrecios"
                          name="clasesPrecios"
                          onChangeClase={onChangeClase}
                          idioma_ensenha={idioma_ensenha}
                        ></ClasesPrecios>
                        <Button type="submit" colorScheme="green">
                          Guardar
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </HStack>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCloseModal}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarEnsenha;
