import React, { MouseEventHandler, useState } from "react";
import {
  Box,
  Button,
  Image,
  Flex,
  useMediaQuery,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "./app/store";
import { Link as RouterLink, useLocation } from "react-router-dom";
import axios from "axios";
import { env } from "./components/utils/env";
import {
  FiHome,
  FiUser,
  FiClock,
  FiUsers,
  FiDollarSign,
  FiBookOpen,
  FiCreditCard,
  FiUserCheck,
  FiSettings,
  FiCheckCircle,
  FiUserPlus,
  FiLogOut,
} from "react-icons/fi";
import { CloseIcon } from "@chakra-ui/icons";
import BotonSaldo from "./components/BotonSaldo";
import { IconType } from "react-icons";
import { useUser } from "./components/utils/userApi";
import { useAuthStore } from "./app/authStore";

const CollapseMenu = () => {
  const { user } = useAuthStore((state: any) => state);
  const { data: usuario } = useUser(user?.id);

  const widthLink = "8px";
  const { languageData } = useSelector((state: RootState) => state.language);
  const [isOpen, setIsOpen] = useState(false);
  const apiUrl = env("REACT_APP_API_URL");

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    axios
      .post(apiUrl + "login/cerrar_sesion")
      .then((response) => {
        if (response.data.status === "OK") {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userInfo");
          window.location.href = "/";
        }
      })
      .catch((error) => {
        // Handle errors or perform any other necessary actions
        console.error(error);
      });
  };
  const [isSmallerThan2000, isSmallerThan1400, isSmallerThan700] =
    useMediaQuery([
      "(max-width: 2000px)",
      "(max-width: 1400px)",
      "(max-width: 700px)",
    ]);

  let width = "25%";
  if (isSmallerThan2000) {
    width = "30%";
  }

  if (isSmallerThan1400) {
    width = "60%";
  }

  if (isSmallerThan700) {
    width = "100%";
  }

  interface ListItemProps {
    to: string;
    icon: IconType;
    text: string;
    onClick: MouseEventHandler<HTMLAnchorElement>;
  }

  const location = useLocation();

  const ListItem: React.FC<ListItemProps> = ({ to, icon, text, onClick }) => {
    const isLinkActive = (to: any) => {
      return location.pathname === to;
    };
    const active = isLinkActive(to) ? "green" : "";

    return (
      <li style={{ padding: "8px" }}>
        <RouterLink to={to} onClick={onClick}>
          <span>
            <Flex
              alignItems="center"
              bg={active ? "#08964C" : ""}
              height={active ? "7vh" : ""}
              paddingLeft={"5%"}
              color={active ? "white" : ""}
            >
              <Box as={icon as React.ElementType} marginRight="2%" />
              <span className="text">{text}</span>
            </Flex>
          </span>
        </RouterLink>
      </li>
    );
  };
  const menuItems = [
    {
      to: "/dashboard",
      icon: FiHome,
      text: "Dashboard",
    },
    {
      to: "/dashboard/perfil",
      icon: FiUser,
      text: "Perfil",
    },
    {
      to: "/dashboard/proximas_clases",
      icon: FiClock,
      text: "Proximas Clases",
    },
    {
      to: "/dashboard/profesores",
      icon: FiUsers,
      text: "Profesores",
    },
    {
      to: "/dashboard/idiomas",
      icon: FiDollarSign,
      text: "Idiomas y precios",
    },
    {
      to: "/dashboard/estudios",
      icon: FiBookOpen,
      text: "Estudios",
    },
    {
      to: "/dashboard/pagos",
      icon: FiCreditCard,
      text: "Pagos",
    },
    {
      to: "/dashboard/alumnos",
      icon: FiUserCheck,
      text: "Alumnos",
    },
    {
      to: "/dashboard/horario",
      icon: FiClock,
      text: "Horario",
    },
    {
      to: "/dashboard/configuraciones",
      icon: FiSettings,
      text: "Configuraciones",
    },
    {
      to: "/dashboard/validaciones",
      icon: FiCheckCircle,
      text: "Validaciones",
    },
    {
      to: "/dashboard/usuarios",
      icon: FiUserPlus,
      text: "Usuarios",
    },
    /*
    {
      to: "/idioma/traducciones",
      icon: FiGlobe,
      text: "Idioma del sitio",
    },
    */
  ];

  const filteredMenuItems = menuItems.filter((item) => {
    const allowedMenus = [];

    // Super Administrador
    if (Number(usuario?.tipo_usuario) === 1) {
      allowedMenus.push("/dashboard");
      allowedMenus.push("/dashboard/perfil");
      allowedMenus.push("/dashboard/profesores");
      allowedMenus.push("/dashboard/idiomas");
      allowedMenus.push("/dashboard/pagos");
      allowedMenus.push("/dashboard/horario");
      allowedMenus.push("/dashboard/configuraciones");
      allowedMenus.push("/dashboard/validaciones");
      allowedMenus.push("/dashboard/usuarios");
    }

    // Profesor
    if (Number(usuario?.tipo_usuario) === 2) {
      allowedMenus.push("/dashboard");
      allowedMenus.push("/dashboard/perfil");
      allowedMenus.push("/dashboard/proximas_clases");
      allowedMenus.push("/dashboard/profesores");
      allowedMenus.push("/dashboard/idiomas");
      allowedMenus.push("/dashboard/estudios");
      allowedMenus.push("/dashboard/pagos");
      allowedMenus.push("/dashboard/Alumnos");
      allowedMenus.push("/dashboard/horario");
    }

    // Alumno
    if (Number(usuario?.tipo_usuario) === 3) {
      allowedMenus.push("/dashboard");
      allowedMenus.push("/dashboard/perfil");
      allowedMenus.push("/dashboard/proximas_clases");
      allowedMenus.push("/dashboard/profesores");
    }

    // Administrador
    if (Number(usuario?.tipo_usuario) === 4) {
      allowedMenus.push("/dashboard");
      allowedMenus.push("/dashboard/perfil");
      allowedMenus.push("/dashboard/profesores");
    }

    return allowedMenus.includes(item.to);
  });

  const CustomList = ({ handleOptionClick, widthLink }: any) => {
    return (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {filteredMenuItems?.map((item, index) => (
          <ListItem
            key={index}
            to={item.to}
            icon={item.icon}
            text={item.text}
            onClick={handleOptionClick}
          />
        ))}
        <li style={{ padding: widthLink }}>
          <Button
            colorScheme="red"
            size="lg"
            width="100%"
            onClick={handleLogout}
          >
            <Flex alignItems="center">
              <Box as={FiLogOut as React.ElementType} marginRight="1%" />
              <span className="text">Cerrar Sesión</span>
            </Flex>
          </Button>
        </li>
      </ul>
    );
  };

  return (
    <>
      <Button
        backgroundColor="#5b70f9"
        color="white"
        mr="1%"
        onClick={handleToggle}
      >
        {languageData.ver_menu} Ver Menu
      </Button>
      {isOpen && (
        <Box
          p={4}
          bg="white"
          position="fixed"
          left="0"
          top="0"
          bottom="0"
          width={width}
          overflowY="auto"
          zIndex="3"
        >
          <Flex
            direction="column"
            align="center"
            justify="center"
            className="sidenav-head"
          >
            <Image
              src="https://www.langport.net/assets/images/langport.jpg"
              alt="sidenav banner"
              height="120px"
              width="180px"
            />
            <Button
              variant="ghost"
              color="#5b70f9"
              top="27px"
              right="27px"
              width="30px"
              height="30px"
              zIndex="999"
              display="block"
              cursor="pointer"
              position="absolute"
              borderRadius="50%"
              border="0 !important"
              onClick={handleToggle}
            >
              <CloseIcon width="30px" height="30px" />
            </Button>
          </Flex>

          <Box marginLeft="5%" marginTop="5%" marginRight="5%">
            <Box borderWidth={1} borderRadius="lg" overflow="hidden">
              <Text textAlign="center">
                {Number(usuario?.tipo_usuario) === 1 && "Super Administrador"}
                {Number(usuario?.tipo_usuario) === 2 && "Profesor"}
                {Number(usuario?.tipo_usuario) === 3 && "Alumno"}
                {Number(usuario?.tipo_usuario) === 4 && "Administrador"}
              </Text>
              <Text textAlign="center">
                {usuario?.nombre} {usuario?.apellido}
              </Text>
              <hr />
              <CustomList
                handleOptionClick={handleOptionClick}
                widthLink={widthLink}
              />
              <BotonSaldo></BotonSaldo>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CollapseMenu;
