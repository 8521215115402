import { RootState } from "../../app/store";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Grid, Image, useMediaQuery, Text } from "@chakra-ui/react";
import { pageViewed } from "../../mixpanel/mixpanel";
import { useEffect, useRef } from "react";
import { Info, Success } from "../../utils/buttons";

function SectionHero() {
  const { languageData } = useSelector((state: RootState) => state.language);
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      pageViewed("");
      isFirstRender.current = false;
    }
  }, []);

  const deviceConfig = {
    desktop: {
      heightWelcome: "60vh",
      topTexto: "20%",
      leftTexto: "14%",
      zindexTexto: "",
      headingTituloFontSize: "45px",
      headingTextoFontSize: "18px",
      topImage: "5%",
      opacityImage: "1",
      heightImage: "500px",
      rightImage: "2%",
      zindexImage: "",
    },
    tablet: {
      heightWelcome: "50vh",
      topTexto: "24%",
      leftTexto: "44%",
      zindexTexto: "0",
      headingTituloFontSize: "36px",
      headingTextoFontSize: "14px",
      topImage: "8vw",
      opacityImage: "0.15",
      heightImage: "",
      rightImage: "10%",
      zindexImage: "-1",
    },
    mobile: {
      heightWelcome: "70vh",
      topTexto: "8%",
      leftTexto: "5%",
      zindexTexto: "0",
      headingTituloFontSize: "24px",
      headingTextoFontSize: "14px",
      topImage: "2%",
      opacityImage: "0.15",
      heightImage: "",
      rightImage: "0",
      zindexImage: "-1",
    },
  };

  const [isDesktop] = useMediaQuery("(min-width: 993px)");
  const [isTablet] = useMediaQuery("(min-width: 768px) and (max-width: 992px)");
  //const [isMobile] = useMediaQuery("(max-width: 767px)");

  const config = isDesktop
    ? deviceConfig.desktop
    : isTablet
    ? deviceConfig.tablet
    : deviceConfig.mobile;

  return (
    <section style={{ height: config.heightWelcome }}>
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={4}
        bg="blackAlpha.50"
        h="100%"
      >
        <Box
          top={config.topTexto}
          left={config.leftTexto}
          position="relative"
          zIndex={config.zindexTexto}
        >
          <Text
            size="xl"
            dangerouslySetInnerHTML={{ __html: languageData.slide_titulo }}
            fontSize={config.headingTituloFontSize}
            fontFamily='"Poppins", sans-serif;'
          ></Text>
          <Text
            size="xs"
            dangerouslySetInnerHTML={{ __html: languageData.slide_texto }}
            fontSize={config.headingTextoFontSize}
            color="gray.500"
            marginBottom="30px"
            fontWeight="semibold"
          ></Text>
          <Link to="/profesores">
            <Info message={languageData.boton_slide_reserva}></Info>
          </Link>
          <Link to="/acerca_nosotros">
            <Success message={languageData.boton_slide_nosotros}></Success>
          </Link>
        </Box>

        <Box>
          <Image
            src={`/images/personas-removebg-preview.png`}
            alt="personas"
            height={config.heightImage}
            right={config.rightImage}
            top={config.topImage}
            position="absolute"
            opacity={config.opacityImage}
            zIndex={config.zindexImage}
          />
        </Box>
      </Grid>
    </section>
  );
}

export default SectionHero;
