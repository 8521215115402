import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Box } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { fetchIdioma } from "../utils/languageApi";

function Language({
  id,
  qtyProfesores,
}: {
  id: number;
  qtyProfesores: number;
}) {
  const { languageData } = useSelector((state: RootState) => state.language);
  const isFirstRender = useRef(true);
  interface Idioma {
    id: string;
    pais: string;
    bandera: string;
    idioma: string;
    reg_estado: string;
    fecha_creacion: string;
    id_idioma: string;
    nivel: string;
    id_usuario: string;
    habla: string;
    ensenha: string;
    id_idioma_profe: string;
  }

  const [idioma, setIdioma] = useState<Idioma | undefined>(undefined);

  const obtenerIdiomas = useCallback(async () => {
    try {
      const data = await fetchIdioma(id);
      setIdioma(data);
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    if (isFirstRender.current) {
      obtenerIdiomas();
      isFirstRender.current = false;
    }
  }, [obtenerIdiomas]);

  return (
    <section
      className="section section-features section-features-1"
      style={{ backgroundColor: "white" }}
    >
      <div className="container">
        <header className="el-heading center">
          <h2>{languageData.nuestro_profesores_titulo}</h2>
          <p>{languageData.nuestro_profesores_texto}</p>
          <div className="divider divider-line"></div>
        </header>
        <Box as="h5" textAlign="center">
          <span>
            {qtyProfesores} Profesor(es) enseña(n) el idioma {idioma?.idioma}.
          </span>
        </Box>
      </div>
    </section>
  );
}

export default Language;
