import React, { MouseEventHandler, useEffect } from "react";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { setSelectedLanguage } from "../features/language/languageSlice";
import Cookies from "js-cookie";
import { env } from "../components/utils/env";
import CollapseMenu from "../CollapseMenu";

const HeaderUsuario = () => {
  const URL = env("REACT_APP_URL");
  const dispatch = useDispatch();
  const { selectedLanguage, languageData, icon } = useSelector(
    (state: RootState) => state.language
  );

  const handleLanguageChange: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const lang = event.currentTarget.getAttribute("data-value");
    dispatch(setSelectedLanguage(lang!));
  };

  const bg = useColorModeValue("var(--base-1)", "gray.800");

  const { pathname } = useLocation();
  const isActive = (path: string): boolean => {
    return pathname === path;
  };

  useEffect(() => {
    const languageFromCookie = Cookies.get("language");
    const iconFromCookie = Cookies.get("icon");
    if (languageFromCookie && iconFromCookie) {
      dispatch(setSelectedLanguage(languageFromCookie));
    }
  }, [dispatch]);

  return (
    <Box bg={bg} boxShadow="md">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className="app-header app-header-1 boxed"
      >
        <Flex alignItems="center">
          <Flex pl="100%">
            <RouterLink
              to="/"
              className={isActive("/") ? "active" : ""}
              style={{
                color: isActive("/") ? "#2E3192" : "inherit",
                marginLeft: "20px",
                fontWeight: "bold",
                borderBottom: `2px solid ${
                  isActive("/") ? "#2E3192" : "transparent"
                }`,
              }}
            >
              {languageData.home}
            </RouterLink>
            <RouterLink
              to="/profesores"
              className={isActive("/profesores") ? "active" : ""}
              style={{
                color: isActive("/profesores") ? "#2E3192" : "inherit",
                marginLeft: "20px",
                fontWeight: "bold",
                borderBottom: `2px solid ${
                  isActive("/profesores") ? "#2E3192" : "transparent"
                }`,
              }}
            >
              {languageData.profesores}
            </RouterLink>
          </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="center" paddingLeft="30%">
          <RouterLink to="/">
            <img src="/images/Langport70x70.png" alt="Langport" />
          </RouterLink>
        </Flex>
        <Flex alignItems="center" paddingRight="25%">
          <Menu>
            <MenuButton color="red">
              <img
                src={`${URL}images/flags/${icon}`}
                alt={selectedLanguage}
                width="35px"
                style={{ marginRight: "10px" }}
              />

              <ChevronDownIcon style={{ display: "initial" }} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleLanguageChange} data-value="es">
                <img
                  src={`${URL}images/flags/es.png`}
                  alt="Español"
                  width="35px"
                  style={{ marginRight: "10px" }}
                ></img>
                Español
              </MenuItem>
              <MenuItem onClick={handleLanguageChange} data-value="en">
                <img
                  src={`${URL}images/flags/us.png`}
                  alt="English"
                  width="35px"
                  style={{ marginRight: "10px" }}
                ></img>
                English
              </MenuItem>
              <MenuItem onClick={handleLanguageChange} data-value="ua">
                <img
                  src={`${URL}images/flags/ua.png`}
                  alt="Українська"
                  width="35px"
                  style={{ marginRight: "10px" }}
                ></img>
                Українська
              </MenuItem>
            </MenuList>
          </Menu>
          <CollapseMenu></CollapseMenu>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HeaderUsuario;
