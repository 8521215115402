import {
  Box,
  Image,
  Heading,
  UnorderedList,
  ListItem,
  HStack,
  Text,
  Flex,
  Divider,
} from "@chakra-ui/react";

import { Usuario } from "../../interfaces/Usuario.interface";
import React from "react";
import { env } from "../utils/env";

export const ProfesorInformacion = ({ profesor }: any) => {
  const idiomasHabla = profesor?.profesor?.idiomas_profe_habla
    ?.map((obj: any) => obj.idioma)
    .join(", ");

  const URL = env("REACT_APP_URL");
  return (
    <Box className="teacher">
      <Text fontSize="2xl" color="gray.400">
        Profesor
      </Text>
      <Flex align="center" padding="4">
        <Box position="relative" marginRight="4">
          <Image
            src={profesor?.profesor?.foto}
            alt={profesor?.profesor?.nombre || "Teacher"}
            boxSize="100px"
            borderRadius="full"
            objectFit="cover"
          />
          {profesor?.idiomas_profesor_habla
            ?.slice(0, 3)
            .map((idioma: any, index: any) => (
              <Box
                mt="3"
                key={index}
                position="absolute"
                left="0"
                top={`${index * 28}px`}
              >
                <Image
                  src={`${URL}/images/flags/${idioma.bandera}`}
                  alt={idioma.idioma}
                  boxSize="24px"
                />
              </Box>
            ))}
        </Box>

        <Box>
          {profesor?.profesor?.nombre && (
            <Heading marginY="1" fontSize="16">
              {profesor?.profesor?.nombre} {profesor?.profesor?.apellido}
            </Heading>
          )}
          {profesor?.profesor?.texto && (
            <Heading fontStyle="italic" color="#717f94" fontSize="12">
              {profesor?.profesor?.texto}
            </Heading>
          )}
        </Box>
      </Flex>
      <Divider />
      <Box display="flex" alignItems="center">
        <Heading marginY="1" fontSize="16" marginRight="1">
          Idioma(s) que habla:
        </Heading>
        <Text marginY="1" fontSize="14">
          {idiomasHabla}
        </Text>
      </Box>
    </Box>
  );
};
