import React from "react";
import { Box, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserDetailsQuery } from "../app/services/auth/authService";
import { RootState } from "../app/store";

const Header = () => {
  const bg = useColorModeValue("white", "gray.800");
  const [isDesktop] = useMediaQuery("(min-width: 993px)");
  //const [isTablet] = useMediaQuery("(min-width: 768px) and (max-width: 992px)");
  //const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <Box bg={bg} boxShadow="md">
      {isDesktop ? <MenuDesktop /> : <MenuMobile />}
    </Box>
  );
};

export default Header;
