import { IconProps, styled } from "@chakra-ui/react";
import React from "react";
import { isString } from "../validators/index";

import { getFontByCode, TypeCode } from "./codes";

export type WithIconsOverrideProps = Omit<
  IconProps,
  "icon" | "width" | "height" | "size"
>;
export type WithIconsProps = WithIconsOverrideProps & { iconVariant: TypeCode };

export const defaultFontSize = "16px";

export const fontSizeOptions = [
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "48px",
  "64px",
  "80px",
  "96px",
  "112px",
  "128px",
  "144px",
  "160px",
];

const IconByPseudoElements = styled("i", {});

const getSize = (fontSize: string | number) => {
  const newFontSize = isString(fontSize)
    ? fontSize
    : `${fontSize?.toString()}px`;
  const override = {
    fontSize: newFontSize,
    width: newFontSize,
    height: newFontSize,
    display: "contents",
  };
  return override;
};

export const withIcon =
  (CustomIcon?: any, code?: string) =>
  ({ fontSize = defaultFontSize, iconVariant, ...props }: WithIconsProps) => {
    const override = getSize(fontSize as any);
    const combinedProps: any = {
      ...props,
      ...override,
    };

    if (code) {
      const iconToPseudoElement = getFontByCode(iconVariant);

      const before = {
        font: iconToPseudoElement,
        content: code,
        ...override,
        display: "inline-block",
      };

      return <IconByPseudoElements {...combinedProps} _before={before} />;
    }

    if (CustomIcon) {
      return <CustomIcon {...combinedProps} />;
    }

    return <IconByPseudoElements />;
  };

export const withBrand =
  (code: string) => (props: Omit<WithIconsProps, "iconVariant">) => {
    const Wrapper = withIcon(null, code);
    return <Wrapper {...props} iconVariant={"brand" as any} />;
  };
