import { Box } from "@chakra-ui/react";
import React from "react";
import Cursos from "../../../components/dashboard/estudios/Cursos";
import { perfilUsuario } from "../../../interfaces/TipoUsuario.interface";
import ProtectedRoute from "../../../components/ProtectedRoute";

const Estudio = () => {
  const allowedTypes = [perfilUsuario.profesor];
  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box>
        <Cursos></Cursos>
      </Box>
    </ProtectedRoute>
  );
};

export default Estudio;
