import { useEffect, useRef, useState } from "react";
import {
  Button,
  Textarea,
  Input,
  FormErrorMessage,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import axios from "axios";
import { env } from "../utils/env";
import { pageViewed } from "../../mixpanel/mixpanel";

interface Contacto {
  nombre: string | null;
  email: string | null;
  mensaje: string | null;
}

const Contactanos = () => {
  const url = env("REACT_APP_URL");
  const [contacto, setContacto] = useState<Contacto>({
    nombre: "",
    email: "",
    mensaje: "",
  });

  const [error, setError] = useState<number>(0);
  const [mensajeError, setMensajeError] = useState<string | null>(null);
  const [tipoAlerta, setTipoAlerta] = useState<string | null>(null);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      pageViewed("");
      isFirstRender.current = false;
    }
  }, []);
  const enviarEmail = () => {
    setError(0);
    setMensajeError(null);
    if (!contacto.nombre) {
      setError(error + 1);
      setMensajeError("El campo nombre es obligatorio <br />");
    }
    if (!contacto.email) {
      setError(error + 1);
      setMensajeError("El campo e-mail es obligatorio <br />");
    }
    if (contacto.email) {
      if (
        !contacto.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ) {
        setError(error + 1);
        setMensajeError("El campo e-mail es invalido <br />");
      }
    }
    if (!contacto.mensaje) {
      setError(error + 1);
      setMensajeError("El campo mensaje es obligatorio <br />");
    }
    if (error === 0) {
      axios
        .post(url + "contacto/enviar_email_contacto/", {
          contacto: contacto,
        })
        .then((response) => {
          if (response.data.status === "ERROR") {
            setMensajeError(
              "Hubo un aerror al enviar el mensaje contacte con el administrador a info@langport.net <br />"
            );
            setTipoAlerta("error");
          } else if (response.data.status === "OK") {
            setMensajeError("El mensaje ha sido enviado correctamente <br />");
            setTipoAlerta("success");
            setContacto({
              nombre: null,
              email: null,
              mensaje: null,
            });
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    } else {
      setTipoAlerta("error");
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setContacto((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(contacto);
    // Aquí podrías enviar los datos del formulario al servidor utilizando fetch() u otra librería.
  };
  return (
    <div className="page page-inferior page-faq" id="vue">
      <section className="section section-faq section-faq-1">
        <div className="display-spacing">
          <form onSubmit={handleSubmit}>
            <FormControl isInvalid={!contacto.nombre}>
              <FormLabel htmlFor="nombre">Nombre:</FormLabel>
              <Input
                type="text"
                id="nombre"
                name="nombre"
                value={contacto.nombre || ""}
                onChange={handleChange}
                placeholder="Escribe tu nombre"
              />
              <FormErrorMessage>Este campo es obligatorio</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!contacto.email}>
              <FormLabel htmlFor="email">Email:</FormLabel>
              <Input
                type="email"
                id="email"
                name="email"
                value={contacto.email || ""}
                onChange={handleChange}
                placeholder="Escribe tu email"
              />
              <FormErrorMessage>Este campo es obligatorio</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!contacto.mensaje}>
              <FormLabel htmlFor="mensaje">Mensaje:</FormLabel>
              <Textarea
                id="mensaje"
                name="mensaje"
                value={contacto.mensaje || ""}
                onChange={handleChange}
                placeholder="Escribe tu mensaje"
              />
              <FormErrorMessage>Este campo es obligatorio</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              disabled={
                !contacto.nombre || !contacto.email || !contacto.mensaje
              }
            >
              Enviar
            </Button>
          </form>
        </div>
      </section>
    </div>
  );
};
export default Contactanos;
