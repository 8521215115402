import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";

import IdiomasHabla from "./TablaIdiomasHabla";
import AgregarHabla from "./AgregarHabla";
import { Usuario } from "../../../../interfaces/Usuario.interface";
import { useUser } from "../../../../components/utils/userApi";
import { useAuthStore } from "../../../../app/authStore";

const Habla = () => {
  const [usuario, setUsuario] = useState<Usuario>([]); // Initialize the usuario state
  const { user } = useAuthStore((state: any) => state);
  const { data: usuarioData } = useUser(user?.id);
  useEffect(() => {
    if (usuarioData) {
      setUsuario(usuarioData);
    }
  }, [usuarioData]);

  return (
    <Box marginLeft="5%" marginTop="5%" marginRight="5%">
      <Box className="container">
        <Box
          p={6}
          borderWidth={1}
          borderStyle="solid"
          borderRadius="10px"
          borderColor="#e2e8f0"
          fontFamily='"Poppins", sans-serif'
        >
          <Box mb={3}>
            <Text fontSize="2xl" color="gray.400" mb={5}>
              Idiomas que habla
            </Text>
            <hr />
            <br />
            <AgregarHabla
              usuario={usuario}
              setUsuario={setUsuario}
            ></AgregarHabla>
          </Box>
          <IdiomasHabla
            usuario={usuario}
            setUsuario={setUsuario}
          ></IdiomasHabla>
        </Box>
      </Box>
    </Box>
  );
};

export default Habla;
