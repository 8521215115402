import { BoxProps } from "@chakra-ui/react";
import { Badge, Text } from "@chakra-ui/react";

interface FormLabelProps extends BoxProps {
  children: React.ReactNode;
  required?: boolean;
}

const FormLabelInput: React.FC<FormLabelProps> = ({
  children,
  required = false,
}) => {
  const labelStyle = {
    position: "absolute",
    transform: "translateY(-10px)",
    backgroundColor: "white",
    marginLeft: "15px",
    zIndex: 2,
    fontSize: "12px",
    fontWeight: "bold",
    display: "inline-block",
    padding: "0 9px",
  };
  return (
    <Text sx={labelStyle}>
      {children}{" "}
      {required && (
        <Badge ml={2} colorScheme="red">
          *
        </Badge>
      )}
    </Text>
  );
};

export default FormLabelInput;
