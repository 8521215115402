import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";

interface RatingStarsProps {
  rating: number;
}

const RatingStars: React.FC<RatingStarsProps> = ({ rating }) => {
  const HalfStar = () => (
    <Flex alignItems="center" position="relative">
      <StarIcon color="gray.300" mr={1} />
      <Box position="absolute">
        <StarIcon
          color="yellow.400"
          clipPath="polygon(0 0, 50% 0, 50% 100%, 0 100%)"
        />
      </Box>
    </Flex>
  );

  const renderStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const halfStar =
      rating - fullStars >= 0.25 && rating - fullStars <= 0.75 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={i} color="yellow.400" mr={1} />);
    }

    for (let i = 0; i < halfStar; i++) {
      stars.push(<HalfStar key={fullStars + i} />);
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <StarIcon key={fullStars + halfStar + i} color="gray.300" mr={1} />
      );
    }

    return <Flex>{stars}</Flex>;
  };

  return renderStars(rating);
};

export default RatingStars;
