import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useUser } from "../../../components/utils/userApi";
import { perfilUsuario } from "../../../interfaces/TipoUsuario.interface";
import ProtectedRoute from "../../../components/ProtectedRoute";
import useUserIdFromToken from "../../../utils/useUserIdFromToken";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { useAuthStore } from "../../../app/authStore";

const Pagos = () => {
  const { user } = useAuthStore((state: any) => state);
  const { data: usuario } = useUser(user?.id);

  const allowedTypes = [
    perfilUsuario.superAdministrador,
    perfilUsuario.profesor,
  ];
  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box marginLeft="5%" marginTop="5%" marginRight="5%">
        <Box className="container">
          <Text>Pendiente implementación de pagos</Text>
        </Box>
      </Box>
    </ProtectedRoute>
  );
};

export default Pagos;
