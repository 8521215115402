import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { env } from "../components/utils/env";
//import { allLanguages, MixpanelEventsP1 } from "utils/consts";
//import { translateTerm } from "utils/hooks/useTriggerPointTriggerLocation";
mixpanel.init(env("REACT_APP_MIXPANEL") || "", {
  debug: false,
  api_host: "https://api.mixpanel.com",
});
export default mixpanel;

//pageViewed
export const pageViewed = (pageName: string) => {
  mixpanel.track("page viewed", {
    //"page name": pageName,
  });
};
