import React, { useEffect, useState, useMemo } from "react";

import { FormControl, Select, Text } from "@chakra-ui/react";
import FormLabelInput from "../dashboard/FormLabelInput";
import { ErrorMessage, Field } from "formik";

type NivelType = {
  id: number;
  nombre: string;
  selected: number;
};
interface Props {
  id: any;
  name: any;
  placeholder: string;
  idiomaBanderasItem: any;
  ensenha: number;
  selectedNivel: any;
}
const Niveles: React.FC<Props> = ({
  id,
  name,
  placeholder,
  idiomaBanderasItem,
  ensenha,
  selectedNivel,
}: Props) => {
  const [nivelesToShow, setNivelesToShow] = useState<NivelType[]>([]);
  const [selectedLevel, setselectedLevel] = useState<NivelType | null>(null); // Change the state type to NivelType | null

  const nivelesEnsenha = useMemo(
    () => [
      { id: 3, nombre: "Avanzado", selected: 0 },
      { id: 4, nombre: "Nativo", selected: 0 },
    ],
    []
  );

  const nivelesHabla = useMemo(
    () => [
      { id: 1, nombre: "Basico", selected: 0 },
      { id: 2, nombre: "Intermedio", selected: 0 },
      ...nivelesEnsenha,
    ],
    [nivelesEnsenha]
  );

  useEffect(() => {
    setNivelesToShow(ensenha === 1 ? nivelesEnsenha : nivelesHabla);

    const updatedNivelesToShow =
      ensenha === 1
        ? nivelesEnsenha?.map((nivel) =>
            nivel.id === selectedLevel?.id
              ? { ...nivel, selected: 1 }
              : { ...nivel, selected: 0 }
          )
        : nivelesHabla?.map((nivel) =>
            nivel.id === selectedLevel?.id
              ? { ...nivel, selected: 1 }
              : { ...nivel, selected: 0 }
          );
    const updatedNivelesHabla = updatedNivelesToShow?.map((nivel) =>
      nivel.id === Number(selectedNivel.id)
        ? { ...nivel, selected: 1 }
        : { ...nivel, selected: 0 }
    );
    setNivelesToShow(updatedNivelesHabla);
  }, [
    ensenha,
    idiomaBanderasItem,
    nivelesEnsenha,
    nivelesHabla,
    selectedLevel?.id,
    selectedNivel.id,
  ]);

  return (
    <FormControl mt="10">
      <FormLabelInput required>Nivel </FormLabelInput>
      <Field id={id} as={Select} name={name} placeholder={placeholder}>
        {nivelesToShow?.map((nivel: NivelType) => (
          <option
            key={nivel.id}
            value={nivel.nombre}
            data-id={nivel.id}
            selected={nivel.selected === 1}
          >
            {nivel.nombre}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name}>
        {(errorMsg) => {
          return (
            <Text color="red.500" mt={2}>
              {errorMsg}
            </Text>
          );
        }}
      </ErrorMessage>
    </FormControl>
  );
};

export default Niveles;
