import axios from "axios";
import { env } from "./env";
import { UseQueryResult, useQuery } from "react-query";

import { Idioma } from "../../interfaces/Usuario.interface";
const API_URL = env("REACT_APP_API_URL");

type UseIdiomaResult = UseQueryResult<Idioma, unknown>;
export const useIdioma = (id_idioma: any): UseIdiomaResult => {
  return useQuery<Idioma, unknown>(["idioma", id_idioma], () =>
    fetchIdioma(id_idioma)
  );
};
export const useIdiomas = () => {
  return useQuery<Idioma, unknown>([], () => fetchIdiomas());
};

export const fetchIdiomas = async () => {
  try {
    const response = await axios.get(`${API_URL}idioma/get_all_idioma`);
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener idiomas: ${error}`);
  }
};

export const fetchIdioma = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}idioma/get_idioma/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener idioma: ${error}`);
  }
};
