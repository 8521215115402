import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Usuario } from "../../../interfaces/Usuario.interface";
import { fetchCursosPendientes } from "../../../components/utils/api";
import TablaCursos from "../../../components/common/TablaCursos";
import ProtectedRoute from "../../../components/ProtectedRoute";
import { perfilUsuario } from "../../../interfaces/TipoUsuario.interface";

const Validacion = () => {
  const allowedTypes = [perfilUsuario.superAdministrador];
  const [cursos, setCursos] = useState<Usuario[]>([]);
  const isFirstRender = useRef(true);

  const tableHeaders = [
    { label: "Nombre escuela", key: "nombre_escuela" },
    { label: "Año", key: "anho" },
    { label: "Nombre del curso", key: "nombre_del_curso" },
    { label: "Pdf", key: "pdf" },
    { label: "Estado", key: "estado" },
    { label: "Acción", key: "accion" },
  ];

  const dataTabla = {
    cursos: cursos,
    setCursos: setCursos,
    tableHeaders: tableHeaders,
  };

  const obtenerCursosPendientes = useCallback(async () => {
    try {
      const data = await fetchCursosPendientes();
      setCursos(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      obtenerCursosPendientes();
      isFirstRender.current = false;
    }
  }, [obtenerCursosPendientes, cursos]);

  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box marginLeft="5%" marginTop="5%" marginRight="5%">
        <Box className="container" mb={3}>
          <TablaCursos dataTabla={dataTabla}></TablaCursos>
        </Box>
      </Box>
    </ProtectedRoute>
  );
};

export default Validacion;
