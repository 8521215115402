import React from "react";
import { Field, ErrorMessage } from "formik";
import { Text, Select } from "@chakra-ui/react";

const TimezoneSelect = ({ name, timezones, disabled }: any) => {
  return (
    <>
      <Field
        as={Select}
        name={name}
        placeholder="Seleccione Timezone"
        disabled={disabled}
      >
        {timezones?.map((timezone: any) => (
          <option key={timezone?.id} value={timezone?.timezone}>
            {timezone?.timezone}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name}>
        {(errorMsg) => (
          <Text color="red.500" mt={2}>
            {errorMsg}
          </Text>
        )}
      </ErrorMessage>
    </>
  );
};

export default TimezoneSelect;
