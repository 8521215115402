import React, { useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Switch } from "@chakra-ui/react";
import Horario from "./Horario";
import TimezoneSelect from "../../common/TimezoneSelect ";
import { fetchTimezones } from "../../utils/api";
import { Formik } from "formik";
import * as Yup from "yup";
import { useUser } from "../../utils/userApi";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { useAuthStore } from "../../../app/authStore";

const InfoHorario = () => {
  const { user } = useAuthStore((state: any) => state);
  const { data: usuario } = useUser(user?.id);

  const [timezones, setTimezones] = useState([]);
  const isFirstRender = useRef(true);

  const obtenerTimezones = async () => {
    try {
      const data = await fetchTimezones();
      setTimezones(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (values: any) => {
    const { timezone } = values as {
      timezone: number;
    };
  };
  const validationSchema = Yup.object({
    timezone: Yup.string().required("Campo requerido"),
  });
  useEffect(() => {
    if (isFirstRender.current) {
      obtenerTimezones();
      //pageViewed("");
      isFirstRender.current = false;
    }
  }, []);
  return (
    <Box marginLeft="5%" marginTop="5%" marginRight="5%">
      <Box
        borderWidth={1}
        borderRadius="lg"
        overflow="hidden"
        pl="10"
        pr="10"
        pt="10"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            timezone: usuario?.timezone,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <TimezoneSelect
            name="timezone"
            timezones={timezones}
            disabled={"disabled"}
          />
        </Formik>
        <Horario></Horario>
      </Box>
    </Box>
  );
};

export default InfoHorario;
