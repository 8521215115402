import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Box, Flex, Grid, Text, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import TituloSubtitulo from "../common/TituloSubtitulo";
import { Info } from "../../utils/buttons";
import { useLocation } from "react-router-dom";

const Teachers = ({ profesores }: any) => {
  const { languageData } = useSelector((state: RootState) => state.language);
  const { pathname } = useLocation();
  const isProfesores = pathname === "/profesores" ? 1 : 0;
  let templateColumns = "repeat(1, 1fr)"; // Default templateColumns for mobile

  if (window.innerWidth >= 768) {
    templateColumns = "repeat(2, 1fr)"; // 2 columns for tablet
  }

  if (window.innerWidth >= 1024) {
    templateColumns = "repeat(4, 1fr)"; // 4 columns for desktop
  }
  return (
    <>
      <TituloSubtitulo
        titulo={languageData.nuestro_profesores_titulo}
        subtitulo={languageData.nuestro_profesores_texto}
      ></TituloSubtitulo>
      <Grid templateColumns={templateColumns} gap={4}>
        {Array.isArray(profesores) &&
          profesores?.map((user: any) => (
            <Box
              key={user?.id}
              p={6}
              borderWidth={1}
              borderStyle="solid"
              borderRadius="10px"
              borderColor="#e2e8f0"
              fontFamily='"Poppins", sans-serif'
              w="260px"
              h="220px"
              bg="white"
            >
              <Flex height="100%" alignItems="center" justifyContent="center">
                <Box mb={3}>
                  <Box position="absolute">
                    {user?.idiomas_profe?.map((idioma: any) => (
                      <Image
                        key={idioma.id}
                        src={`https://www.langport.net/images/flags/${idioma.bandera}`}
                        h="30px"
                        w="30px"
                        position="relative"
                        top="-18px"
                        left="-50px"
                      />
                    ))}
                  </Box>
                  <Link to={`/profesores/${user.nombre_slug}/${user.id}`}>
                    <Box
                      h="90px"
                      w="90px"
                      backgroundImage={user?.foto}
                      backgroundSize="cover"
                      borderRadius="full"
                      cursor="pointer"
                      ml="10%"
                    ></Box>
                  </Link>
                  <Link to={`/profesores/${user.nombre_slug}/${user.id}`}>
                    <Text
                      fontSize="16px"
                      color="#151812"
                      fontWeight="600"
                      textAlign="center"
                      mt="15%"
                    >
                      {user?.nombre}
                    </Text>
                  </Link>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    fontSize="12px"
                    color="#717f94"
                    textAlign="center"
                    mt="5%"
                  >
                    <Text fontSize="12px" color="#717f94">
                      Precio desde
                    </Text>
                    <Text fontSize="12px" fontWeight="1000">
                      ${user?.precio_menor?.toFixed(2)} USD
                    </Text>
                  </Box>
                </Box>
              </Flex>
            </Box>
          ))}
      </Grid>

      {!isProfesores && (
        <Box textAlign="center" mt="3%">
          <Link to="/profesores">
            <Info message={languageData.profesores}></Info>
          </Link>
        </Box>
      )}
    </>
  );
};

export default Teachers;
