import axios from "axios";
import { env } from "./env";
import { useQuery, UseQueryResult } from "react-query";

import { Usuario } from "../../interfaces/Usuario.interface";
const API_URL = env("REACT_APP_API_URL");

type UseUserResult = UseQueryResult<Usuario, unknown>;
export const useUser = (id_usuario: any): UseUserResult => {
  return useQuery<Usuario, unknown>(["usuario", id_usuario], () => {
    if (!id_usuario) {
      return "";
    } else {
      return fetchUsuario(id_usuario);
    }
  });
};
export const fetchUsuario = async (id_usuario: any) => {
  try {
    const response = await axios.post(`${API_URL}usuario/get_usuario`, {
      params: {
        id_usuario: id_usuario,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener el usuario: ${error}`);
  }
};
export const fetchHorarioProfesor = async (id_profesor: any) => {
  try {
    const response = await axios.post(
      `${API_URL}usuario/get_horario_profesor`,
      {
        params: {
          id_profesor: id_profesor,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener el usuario: ${error}`);
  }
};
export const fetchUsuarios = async () => {
  try {
    const response = await axios.get(`${API_URL}usuario/get_all_usuario`, {});
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener el usuario: ${error}`);
  }
};

export const fetchAlumnos = async () => {
  try {
    const response = await axios.get(`${API_URL}usuario/get_all_alumnos`, {});
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener los alumnos: ${error}`);
  }
};

export const AddUsuario = async (usuario: any) => {
  try {
    const response = await axios.post(`${API_URL}usuario/add_usuario`, {
      params: {
        usuario: usuario,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al agregar el usuario: ${error}`);
  }
};

export const UpdateUsuario = async (usuario: any) => {
  try {
    const response = await axios.post(`${API_URL}usuario/update_usuario`, {
      params: {
        usuario: usuario,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al actualizar el usuario: ${error}`);
  }
};

export const obtenerHorariosAlumno = async (id_usuario: any) => {
  /*
  axios.interceptors.request.use((request) => {
    console.log("Comenzando Petición", request);
    return request;
  });
  axios.interceptors.response.use((response) => {
    console.log("Respuesta:", response);
    return response;
  });
  */
  try {
    const response = await axios.post(
      `${API_URL}horario/obtenerHorariosAlumno`,
      {
        params: {
          id_usuario: id_usuario,
        },
      }
    );
    return response.data;
  } catch (error) {
    //throw new Error(`Error al obtener el usuario: ${error}`);

    if (error instanceof Error) {
      console.error("Error completo:", error.message);
      throw new Error(`Error al obtener el usuario: ${error.message}`);
    } else {
      // Si llegas a este punto, 'error' no es una instancia del tipo Error y deberías manejarlo de otra manera.
      console.error("Error desconocido:", error);
      throw new Error("Error desconocido al obtener el usuario.");
    }
  }
};
