/**
 * DOCS
 * @fortawesome => https://fontawesome.com/search
 */

export type TypeCode = "solid" | "regular" | "light" | "thin";

const fontByType = {
  solid: "var(--fa-font-solid)",
  regular: "var(--fa-font-regular)",
  light: "var(--fa-font-light)",
  thin: "var(--fa-font-thin)",
  duotone: "var(--fa-font-duotone)",
  brand: "var(--fa-font-brands)",
};

export const getFontByCode = (code: TypeCode = "solid") => fontByType[code];

const generateCode = (code: string) => `"\\${code}"`;

export const brandsByCode = {
  facebook: generateCode("f09a"),
  linkedin: generateCode("f08c"),
  twitter: generateCode("f099"),
  linkedinIn: generateCode("f0e1"),
  instagram: generateCode("f16d"),
};

export const iconsByCode = {
  envelope: generateCode("f0e0"),
  circlePlay: generateCode("f144"),
  backwardStep: generateCode("f048"),
  building: generateCode("f1ad"),
  circle: generateCode("f111"),
  forwardStep: generateCode("f051"),
  listUl: generateCode("f0ca"),
  pencil: generateCode("f303"),
  play: generateCode("f04b"),
  angleRight: generateCode("f105"),
  bars: generateCode("f0c9"),
  chevronsLeft: generateCode("f323"),
  chevronsRight: generateCode("f324"),
  chevronDown: generateCode("f078"),
  chevronLeft: generateCode("f053"),
  chevronRight: generateCode("f054"),
  chevronUp: generateCode("f077"),
  circleCheck: generateCode("f058"),
  circleChevronDown: generateCode("f13a"),
  circleChevronLeft: generateCode("f137"),
  circleChevronUp: generateCode("f139"),
  circleInfo: generateCode("f05a"),
  circleMinus: generateCode("f056"),
  circlePlus: generateCode("f055"),
  circleXmark: generateCode("f057"),
  clockRotateLeft: generateCode("f1da"),
  cloudArrowUp: generateCode("f0ee"),
  compass: generateCode("f14e"),
  doorOpen: generateCode("f52b"),
  filePen: generateCode("f31c"),
  flaskRoundPotion: generateCode("f6e1"),
  gripLines: generateCode("f7a4"),
  loader: generateCode("e1d4"),
  magnifyingGlass: generateCode("f002"),
  minus: generateCode("f068"),
  pen: generateCode("f304"),
  penField: generateCode("e211"),
  penToSquare: generateCode("f044"),
  plus: generateCode("2b"),
  rightToBracket: generateCode("f2f6"),
  slidersUp: generateCode("f3f1"),
  squarePlus: generateCode("f0fe"),
  timer: generateCode("e29e"),
  xmark: generateCode("f00d"),
  trashCan: generateCode("f2ed"),
  upToLine: generateCode("f34d"),
  userShield: generateCode("f505"),
  check: generateCode("f00c"),
  caretDown: generateCode("f0d7"),
  circleExclamation: generateCode("f06a"),
  stopwatch: generateCode("f2f2"),
  arrowRightLong: generateCode("f178"),
  clone: generateCode("f24d"),
  comments: generateCode("f086"),
  arrowLeft: generateCode("f060"),
  circleQuestion: generateCode("f059"),
  asterisk: generateCode("2a"),
  calendar: generateCode("f133"),
  squareCheck: generateCode("f14a"),
  square: generateCode("f0c8"),
  arrowRight: generateCode("f061"),
  book: generateCode("f02d"),
  bookOpen: generateCode("f518"),
  calendarDays: generateCode("f073"),
  caretLeft: generateCode("f0d9"),
  comment: generateCode("f075"),
  download: generateCode("f019"),
  upRightFromSquare: generateCode("f35d"),
  eye: generateCode("f06e"),
  fax: generateCode("f1ac"),
  globe: generateCode("f0ac"),
  graduationCap: generateCode("f19d"),
  info: generateCode("f129"),
  language: generateCode("f1ab"),
  link: generateCode("f0c1"),
  locationDot: generateCode("f3c5"),
  medal: generateCode("f5a2"),
  phoneFlip: generateCode("f5a2"),
  print: generateCode("f02f"),
  circleArrowRight: generateCode("f0a9"),
  clipboard: generateCode("f328"),
  commentDots: generateCode("f4ad"),
  star: generateCode("f005"),
  squareXmark: generateCode("f2d3"),
  share: generateCode("f064"),
  rightFromBracket: generateCode("f2f5"),
  buildingColumns: generateCode("f19c"),
  alarmClock: generateCode("f34e"),
  ballot: generateCode("f732"),
  usersLine: generateCode("e592"),
  arrowTurnDownLeft: generateCode("e2e1"),
  newsPaper: generateCode("f1ea"),
  ellipsisVertical: generateCode("f142"),
  shareAll: generateCode("f367"),
  chartMixed: generateCode("f643"),
  fileLines: generateCode("f15c"),
};
