import React from "react";
import { Box } from "@chakra-ui/react";
import TablaIdiomas from "./TablaIdiomas";
import EditarEnsenha from "./EditarEnsenha";
import EliminarEnsenha from "./EliminarEnsenha";
const imgFlag = {
  width: "3%",
};
const TablaIdiomasEnsenha = ({ usuario, setUsuario }: any) => {
  return (
    <Box display="flex" flexDirection="column">
      {usuario?.idiomas_ensenha?.map((idioma_ensenha: any, index: number) => (
        <Box key={index}>
          <img
            src={`https://www.langport.net/images/flags-medium/${idioma_ensenha.bandera}`}
            alt={idioma_ensenha.idioma}
            width={imgFlag.width}
          />
          {idioma_ensenha.idioma}
          <EditarEnsenha
            usuario={usuario}
            setUsuario={setUsuario}
            idioma_ensenha={idioma_ensenha}
          ></EditarEnsenha>
          <EliminarEnsenha
            idiomaBanderasItem={idioma_ensenha}
            setUsuario={setUsuario}
            usuario={usuario}
          ></EliminarEnsenha>
          <TablaIdiomas
            title="Clase de Prueba"
            data={idioma_ensenha?.clase_prueba}
          />
          <TablaIdiomas title="Una clase" data={idioma_ensenha?.una_clase} />
          <TablaIdiomas
            title="Ocho clases"
            data={idioma_ensenha?.ocho_clases}
          />
        </Box>
      ))}
    </Box>
  );
};

export default TablaIdiomasEnsenha;
