import React from "react";
import { Box } from "@chakra-ui/react";
import SectionHero from "../../components/home/Hero";
import Profesores from "../Profesores/Profesores";
import Idiomas from "../Idiomas/Idiomas";

const Homepage: React.FC = () => {
  console.log("🚀 ~ file: Homepage.tsx:16 ~ :");
  return (
    <Box w="100%">
      <SectionHero></SectionHero>
      <Idiomas></Idiomas>
      <Profesores></Profesores>
    </Box>
  );
};

export default Homepage;
