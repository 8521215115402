import axios from "axios";
import { env } from "./env";
import { useQuery } from "react-query";

import { Usuario } from "../../interfaces/Usuario.interface";
const API_URL = env("REACT_APP_API_URL");

export const useProfesores = () => {
  return useQuery<Usuario, unknown>([], () => fetchProfesores());
};

export const fetchProfesores = async () => {
  try {
    const response = await axios.get(`${API_URL}profesor/get_all_profesor`);
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener profesores: ${error}`);
  }
};
/*
export const useProfesor = (id_profesor: any, fecha: any) => {
  return useQuery<unknown>(
    [],
    () => {
      if (!id_profesor || id_profesor === null || id_profesor === undefined) {
        return "";
      } else {
        return fetchProfesor(id_profesor, fecha);
      }
    },
    {
      enabled: !!id_profesor, // Añade esta línea
    }
  );
};
*/
export const useProfesor = (id_profesor: any, fecha: any) => {
  return useQuery<unknown>(
    [],
    async () => {
      if (!id_profesor) {
        throw new Error("Invalid ID for profesor");
      }
      return fetchProfesor(id_profesor, fecha);
    },
    {
      enabled: !!id_profesor,
    }
  );
};
export const fetchProfesor = async (id_profesor: number, fecha: any) => {
  try {
    const response = await axios.post(`${API_URL}profesor/detalle_profesor/`, {
      params: {
        id_profesor: id_profesor,
        fecha: fecha,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener profesores: ${error}`);
  }
};

export const fetchProfesoresIdIdioma = async (id: number) => {
  try {
    const response = await axios.get(
      `${API_URL}profesor/get_all_id_idioma/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener profesores por idioma: ${error}`);
  }
};

export const ReservarClases = async (
  profesor: any,
  alumno: any,
  clases: any,
  idiomaProfesor: any,
  tiempo: any,
  informacionIdioma: any,
  precio: any
) => {
  try {
    const response = await axios.post(`${API_URL}profesor/reservar/`, {
      params: {
        profesor: profesor,
        alumno: alumno,
        clases: clases,
        idiomaProfesor: idiomaProfesor,
        tiempo: tiempo,
        informacionIdioma: informacionIdioma,
        precio: precio,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al ingresar la reserva de las clases: ${error}`);
  }
};
