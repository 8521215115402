import React, { FC } from "react";
import { Image } from "@chakra-ui/react";

const url = "http://localhost:3000";
interface FlagProps {
  countryCode: string;
  altText: string;
}
const flagSize = {
  width: "55px",
  height: "35px",
};
const Flag: FC<FlagProps> = ({ countryCode, altText }) => {
  const flagPath = `${url}/images/flags-medium/${countryCode}.png`; // Use the url prop
  return (
    <Image
      src={flagPath}
      alt={altText}
      width={flagSize.width}
      height={flagSize.height}
    />
  );
};

export const flag = {
  af: <Flag countryCode="af" altText="Afghanistan" />,
  ax: <Flag countryCode="ax" altText="Åland Islands" />,
  al: <Flag countryCode="al" altText="Albania" />,
  dz: <Flag countryCode="dz" altText="Algeria" />,
  as: <Flag countryCode="as" altText="American Samoa" />,
  ad: <Flag countryCode="ad" altText="Andorra" />,
  ao: <Flag countryCode="ao" altText="Angola" />,
  ai: <Flag countryCode="ai" altText="Anguilla" />,
  aq: <Flag countryCode="aq" altText="Antarctica" />,
  ag: <Flag countryCode="ag" altText="Antigua and Barbuda" />,
  ar: <Flag countryCode="ar" altText="Argentina" />,
  am: <Flag countryCode="am" altText="Armenia" />,
  aw: <Flag countryCode="aw" altText="Aruba" />,
  au: <Flag countryCode="au" altText="Australia" />,
  at: <Flag countryCode="at" altText="Austria" />,
  az: <Flag countryCode="az" altText="Azerbaijan" />,
  bs: <Flag countryCode="bs" altText="Bahamas" />,
  bh: <Flag countryCode="bh" altText="Bahrain" />,
  bd: <Flag countryCode="bd" altText="Bangladesh" />,
  bb: <Flag countryCode="bb" altText="Barbados" />,
  by: <Flag countryCode="by" altText="Belarus" />,
  be: <Flag countryCode="be" altText="Belgium" />,
  bz: <Flag countryCode="bz" altText="Belize" />,
  bj: <Flag countryCode="bj" altText="Benin" />,
  bm: <Flag countryCode="bm" altText="Bermuda" />,
  bt: <Flag countryCode="bt" altText="Bhutan" />,
  bo: <Flag countryCode="bo" altText="Bolivia" />,
  ba: <Flag countryCode="ba" altText="Bosnia and Herzegovina" />,
  bw: <Flag countryCode="bw" altText="Botswana" />,
  bv: <Flag countryCode="bv" altText="Bouvet Island" />,
  br: <Flag countryCode="br" altText="Brazil" />,
  io: <Flag countryCode="io" altText="British Indian Ocean Territory" />,
  bn: <Flag countryCode="bn" altText="Brunei Darussalam" />,
  bg: <Flag countryCode="bg" altText="Bulgaria" />,
  bf: <Flag countryCode="bf" altText="Burkina Faso" />,
  bi: <Flag countryCode="bi" altText="Burundi" />,
  kh: <Flag countryCode="kh" altText="Cambodia" />,
  cm: <Flag countryCode="cm" altText="Cameroon" />,
  ca: <Flag countryCode="ca" altText="Canada" />,
  cv: <Flag countryCode="cv" altText="Cape Verde" />,
  ky: <Flag countryCode="ky" altText="Cayman Islands" />,
  cf: <Flag countryCode="cf" altText="Central African Republic" />,
  td: <Flag countryCode="td" altText="Chad" />,
  cl: <Flag countryCode="cl" altText="Chile" />,
  cn: <Flag countryCode="cn" altText="China" />,
  cx: <Flag countryCode="cx" altText="Christmas Island" />,
  cc: <Flag countryCode="cc" altText="Cocos (Keeling) Islands" />,
  co: <Flag countryCode="co" altText="Colombia" />,
  km: <Flag countryCode="km" altText="Comoros" />,
  cg: <Flag countryCode="cg" altText="Congo" />,
  cd: <Flag countryCode="cd" altText="Congo, Democratic Republic of the" />,
  ck: <Flag countryCode="ck" altText="Cook Islands" />,
  cr: <Flag countryCode="cr" altText="Costa Rica" />,
  ci: <Flag countryCode="ci" altText="Côte d'Ivoire" />,
  hr: <Flag countryCode="hr" altText="Croatia" />,
  cu: <Flag countryCode="cu" altText="Cuba" />,
  cy: <Flag countryCode="cy" altText="Cyprus" />,
  cz: <Flag countryCode="cz" altText="Czech Republic" />,
  dk: <Flag countryCode="dk" altText="Denmark" />,
  dj: <Flag countryCode="dj" altText="Djibouti" />,
  dm: <Flag countryCode="dm" altText="Dominica" />,
  do: <Flag countryCode="do" altText="Dominican Republic" />,
  ec: <Flag countryCode="ec" altText="Ecuador" />,
  eg: <Flag countryCode="eg" altText="Egypt" />,
  sv: <Flag countryCode="sv" altText="El Salvador" />,
  gq: <Flag countryCode="gq" altText="Equatorial Guinea" />,
  er: <Flag countryCode="er" altText="Eritrea" />,
  ee: <Flag countryCode="ee" altText="Estonia" />,
  et: <Flag countryCode="et" altText="Ethiopia" />,
  fk: <Flag countryCode="fk" altText="Falkland Islands (Malvinas)" />,
  fo: <Flag countryCode="fo" altText="Faroe Islands" />,
  fj: <Flag countryCode="fj" altText="Fiji" />,
  fi: <Flag countryCode="fi" altText="Finland" />,
  fr: <Flag countryCode="fr" altText="France" />,
  gf: <Flag countryCode="gf" altText="French Guiana" />,
  pf: <Flag countryCode="pf" altText="French Polynesia" />,
  tf: <Flag countryCode="tf" altText="French Southern Territories" />,
  ga: <Flag countryCode="ga" altText="Gabon" />,
  gm: <Flag countryCode="gm" altText="Gambia" />,
  ge: <Flag countryCode="ge" altText="Georgia" />,
  de: <Flag countryCode="de" altText="Germany" />,
  gh: <Flag countryCode="gh" altText="Ghana" />,
  gi: <Flag countryCode="gi" altText="Gibraltar" />,
  gr: <Flag countryCode="gr" altText="Greece" />,
  gl: <Flag countryCode="gl" altText="Greenland" />,
  gd: <Flag countryCode="gd" altText="Grenada" />,
  gp: <Flag countryCode="gp" altText="Guadeloupe" />,
  gu: <Flag countryCode="gu" altText="Guam" />,
  gt: <Flag countryCode="gt" altText="Guatemala" />,
  gg: <Flag countryCode="gg" altText="Guernsey" />,
  gn: <Flag countryCode="gn" altText="Guinea" />,
  gw: <Flag countryCode="gw" altText="Guinea-Bissau" />,
  gy: <Flag countryCode="gy" altText="Guyana" />,
  ht: <Flag countryCode="ht" altText="Haiti" />,
  hm: <Flag countryCode="hm" altText="Heard Island and McDonald Islands" />,
  va: <Flag countryCode="va" altText="Holy See (Vatican City State)" />,
  hn: <Flag countryCode="hn" altText="Honduras" />,
  hk: <Flag countryCode="hk" altText="Hong Kong" />,
  hu: <Flag countryCode="hu" altText="Hungary" />,
  is: <Flag countryCode="is" altText="Iceland" />,
  in: <Flag countryCode="in" altText="India" />,
  id: <Flag countryCode="id" altText="Indonesia" />,
  ir: <Flag countryCode="ir" altText="Iran, Islamic Republic of" />,
  iq: <Flag countryCode="iq" altText="Iraq" />,
  ie: <Flag countryCode="ie" altText="Ireland" />,
  im: <Flag countryCode="im" altText="Isle of Man" />,
  il: <Flag countryCode="il" altText="Israel" />,
  it: <Flag countryCode="it" altText="Italy" />,
  jm: <Flag countryCode="jm" altText="Jamaica" />,
  jp: <Flag countryCode="jp" altText="Japan" />,
  je: <Flag countryCode="je" altText="Jersey" />,
  jo: <Flag countryCode="jo" altText="Jordan" />,
  kz: <Flag countryCode="kz" altText="Kazakhstan" />,
  ke: <Flag countryCode="ke" altText="Kenya" />,
  ki: <Flag countryCode="ki" altText="Kiribati" />,
  kp: (
    <Flag countryCode="kp" altText="Korea, Democratic People's Republic of" />
  ),
  kr: <Flag countryCode="kr" altText="Korea, Republic of" />,
  kw: <Flag countryCode="kw" altText="Kuwait" />,
  kg: <Flag countryCode="kg" altText="Kyrgyzstan" />,
  la: <Flag countryCode="la" altText="Lao People's Democratic Republic" />,
  lv: <Flag countryCode="lv" altText="Latvia" />,
  lb: <Flag countryCode="lb" altText="Lebanon" />,
  ls: <Flag countryCode="ls" altText="Lesotho" />,
  lr: <Flag countryCode="lr" altText="Liberia" />,
  ly: <Flag countryCode="ly" altText="Libyan Arab Jamahiriya" />,
  li: <Flag countryCode="li" altText="Liechtenstein" />,
  lt: <Flag countryCode="lt" altText="Lithuania" />,
  lu: <Flag countryCode="lu" altText="Luxembourg" />,
  mo: <Flag countryCode="mo" altText="Macao" />,
  mk: (
    <Flag
      countryCode="mk"
      altText="Macedonia, the former Yugoslav Republic of"
    />
  ),
  mg: <Flag countryCode="mg" altText="Madagascar" />,
  mw: <Flag countryCode="mw" altText="Malawi" />,
  my: <Flag countryCode="my" altText="Malaysia" />,
  mv: <Flag countryCode="mv" altText="Maldives" />,
  ml: <Flag countryCode="ml" altText="Mali" />,
  mt: <Flag countryCode="mt" altText="Malta" />,
  mh: <Flag countryCode="mh" altText="Marshall Islands" />,
  mq: <Flag countryCode="mq" altText="Martinique" />,
  mr: <Flag countryCode="mr" altText="Mauritania" />,
  mu: <Flag countryCode="mu" altText="Mauritius" />,
  yt: <Flag countryCode="yt" altText="Mayotte" />,
  mx: <Flag countryCode="mx" altText="Mexico" />,
  fm: <Flag countryCode="fm" altText="Micronesia, Federated States of" />,
  md: <Flag countryCode="md" altText="Moldova, Republic of" />,
  mc: <Flag countryCode="mc" altText="Monaco" />,
  mn: <Flag countryCode="mn" altText="Mongolia" />,
  me: <Flag countryCode="me" altText="Montenegro" />,
  ms: <Flag countryCode="ms" altText="Montserrat" />,
  ma: <Flag countryCode="ma" altText="Morocco" />,
  mz: <Flag countryCode="mz" altText="Mozambique" />,
  mm: <Flag countryCode="mm" altText="Myanmar" />,
  na: <Flag countryCode="na" altText="Namibia" />,
  nr: <Flag countryCode="nr" altText="Nauru" />,
  np: <Flag countryCode="np" altText="Nepal" />,
  nl: <Flag countryCode="nl" altText="Netherlands" />,
  an: <Flag countryCode="an" altText="Netherlands Antilles" />,
  nc: <Flag countryCode="nc" altText="New Caledonia" />,
  nz: <Flag countryCode="nz" altText="New Zealand" />,
  ni: <Flag countryCode="ni" altText="Nicaragua" />,
  ne: <Flag countryCode="ne" altText="Niger" />,
  ng: <Flag countryCode="ng" altText="Nigeria" />,
  nu: <Flag countryCode="nu" altText="Niue" />,
  nf: <Flag countryCode="nf" altText="Norfolk Island" />,
  mp: <Flag countryCode="mp" altText="Northern Mariana Islands" />,
  no: <Flag countryCode="no" altText="Norway" />,
  om: <Flag countryCode="om" altText="Oman" />,
  pk: <Flag countryCode="pk" altText="Pakistan" />,
  pw: <Flag countryCode="pw" altText="Palau" />,
  ps: <Flag countryCode="ps" altText="Palestinian Territory, Occupied" />,
  pa: <Flag countryCode="pa" altText="Panama" />,
  pg: <Flag countryCode="pg" altText="Papua New Guinea" />,
  py: <Flag countryCode="py" altText="Paraguay" />,
  pe: <Flag countryCode="pe" altText="Peru" />,
  ph: <Flag countryCode="ph" altText="Philippines" />,
  pn: <Flag countryCode="pn" altText="Pitcairn" />,
  pl: <Flag countryCode="pl" altText="Poland" />,
  pt: <Flag countryCode="pt" altText="Portugal" />,
  pr: <Flag countryCode="pr" altText="Puerto Rico" />,
  qa: <Flag countryCode="qa" altText="Qatar" />,
  re: <Flag countryCode="re" altText="Réunion" />,
  ro: <Flag countryCode="ro" altText="Romania" />,
  ru: <Flag countryCode="ru" altText="Russian Federation" />,
  rw: <Flag countryCode="rw" altText="Rwanda" />,
  bl: <Flag countryCode="bl" altText="Saint Barthélemy" />,
  sh: (
    <Flag
      countryCode="sh"
      altText="Saint Helena, Ascension and Tristan da Cunha"
    />
  ),
  kn: <Flag countryCode="kn" altText="Saint Kitts and Nevis" />,
  lc: <Flag countryCode="lc" altText="Saint Lucia" />,
  mf: <Flag countryCode="mf" altText="Saint Martin (French part)" />,
  pm: <Flag countryCode="pm" altText="Saint Pierre and Miquelon" />,
  vc: <Flag countryCode="vc" altText="Saint Vincent and the Grenadines" />,
  ws: <Flag countryCode="ws" altText="Samoa" />,
  sm: <Flag countryCode="sm" altText="San Marino" />,
  st: <Flag countryCode="st" altText="Sao Tome and Principe" />,
  sa: <Flag countryCode="sa" altText="Saudi Arabia" />,
  sn: <Flag countryCode="sn" altText="Senegal" />,
  rs: <Flag countryCode="rs" altText="Serbia" />,
  sc: <Flag countryCode="sc" altText="Seychelles" />,
  sl: <Flag countryCode="sl" altText="Sierra Leone" />,
  sg: <Flag countryCode="sg" altText="Singapore" />,
  sk: <Flag countryCode="sk" altText="Slovakia" />,
  si: <Flag countryCode="si" altText="Slovenia" />,
  sb: <Flag countryCode="sb" altText="Solomon Islands" />,
  so: <Flag countryCode="so" altText="Somalia" />,
  za: <Flag countryCode="za" altText="South Africa" />,
  gs: (
    <Flag
      countryCode="gs"
      altText="South Georgia and the South Sandwich Islands"
    />
  ),
  es: <Flag countryCode="es" altText="Spain" />,
  lk: <Flag countryCode="lk" altText="Sri Lanka" />,
  sd: <Flag countryCode="sd" altText="Sudan" />,
  sr: <Flag countryCode="sr" altText="Suriname" />,
  sj: <Flag countryCode="sj" altText="Svalbard and Jan Mayen" />,
  sz: <Flag countryCode="sz" altText="Swaziland" />,
  se: <Flag countryCode="se" altText="Sweden" />,
  ch: <Flag countryCode="ch" altText="Switzerland" />,
  sy: <Flag countryCode="sy" altText="Syrian Arab Republic" />,
  tw: <Flag countryCode="tw" altText="Taiwan, Province of China" />,
  tj: <Flag countryCode="tj" altText="Tajikistan" />,
  tz: <Flag countryCode="tz" altText="Tanzania, United Republic of" />,
  th: <Flag countryCode="th" altText="Thailand" />,
  tl: <Flag countryCode="tl" altText="Timor-Leste" />,
  tg: <Flag countryCode="tg" altText="Togo" />,
  tk: <Flag countryCode="tk" altText="Tokelau" />,
  to: <Flag countryCode="to" altText="Tonga" />,
  tt: <Flag countryCode="tt" altText="Trinidad and Tobago" />,
  tn: <Flag countryCode="tn" altText="Tunisia" />,
  tr: <Flag countryCode="tr" altText="Turkey" />,
  tm: <Flag countryCode="tm" altText="Turkmenistan" />,
  tc: <Flag countryCode="tc" altText="Turks and Caicos Islands" />,
  tv: <Flag countryCode="tv" altText="Tuvalu" />,
  ug: <Flag countryCode="ug" altText="Uganda" />,
  ua: <Flag countryCode="ua" altText="Ukraine" />,
  ae: <Flag countryCode="ae" altText="United Arab Emirates" />,
  gb: <Flag countryCode="gb" altText="United Kingdom" />,
  us: <Flag countryCode="us" altText="United States" />,
  um: <Flag countryCode="um" altText="United States Minor Outlying Islands" />,
  uy: <Flag countryCode="uy" altText="Uruguay" />,
  uz: <Flag countryCode="uz" altText="Uzbekistan" />,
  vu: <Flag countryCode="vu" altText="Vanuatu" />,
  ve: <Flag countryCode="ve" altText="Venezuela, Bolivarian Republic of" />,
  vn: <Flag countryCode="vn" altText="Viet Nam" />,
  vg: <Flag countryCode="vg" altText="Virgin Islands, British" />,
  vi: <Flag countryCode="vi" altText="Virgin Islands, U.S." />,
  wf: <Flag countryCode="wf" altText="Wallis and Futuna" />,
  eh: <Flag countryCode="eh" altText="Western Sahara" />,
  ye: <Flag countryCode="ye" altText="Yemen" />,
  zm: <Flag countryCode="zm" altText="Zambia" />,
  zw: <Flag countryCode="zw" altText="Zimbabwe" />,
};

export default Flag;
