import {
  Table,
  Text,
  Box,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Divider,
  Select,
  useToast,
  VStack,
  Heading,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import React, { useEffect, useState } from "react";
import { ReservarClases } from "../../utils/teacherApi";

interface Horario {
  id: string;
  hora_inicio: string;
  hora_fin: string;
  id_dia: string;
  id_hora: string;
  nombre_dia: string;
  reg_estado: string;
  fecha_creacion: string;
  usuario_id: string;
  horario_informacion_id: string;
  fecha_dia: string;
}
const Calendario = ({ profesor, alumno }: any) => {
  const toast = useToast();
  const [profe, setProfe] = useState(profesor);
  const [selectedHours, setSelectedHours] = useState<Record<string, Horario>>(
    {}
  );
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");

  const [selectedTime, setSelectedTime] = useState<string>("");

  const [selectedClassType, setSelectedClassType] = useState<string>("");

  const updateProfesorHorario = (newProfe: any[]) => {
    setProfe(newProfe);
  };
  const [selectedLanguageData, setSelectedLanguageData] = useState<any | null>(
    null
  );
  const handleSelectedHoursUpdate = (hours: Record<string, Horario>) => {
    if (
      (selectedClassType.includes("precio_una_clase") ||
        selectedClassType.includes("precio_clase_prueba")) &&
      Object.keys(hours).length > 1
    ) {
      toast({
        title: "Error",
        description:
          "Solo puedes reservar una clase con el tipo seleccionado o debes cambiar a ocho.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    } else {
      if (
        selectedClassType.includes("precio_ocho_clases") &&
        Object.keys(hours).length > 8
      ) {
        toast({
          title: "Error",
          description:
            "Solo puedes reservar maximo 8 clases con el tipo seleccionado.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }
    }
    setSelectedHours(hours);
  };

  const handleRemoveClick = (fecha: string, hora_inicio: string) => {
    const key = `${fecha}-${hora_inicio}`;
    const updatedHours = { ...selectedHours };
    delete updatedHours[key];
    setSelectedHours(updatedHours);
  };

  const handleReserveClick = async () => {
    try {
      const idiomaProfesor = profe.profesor.idiomas_profe.find(
        (idioma: any) => idioma.idioma === selectedLanguage
      );
      const [classType] = selectedClassType?.split("-");

      const precio = idiomaProfesor[classType]?.find(
        (precio: any) => precio.tiempo === selectedTime
      );
      const precioWithClassType = {
        ...precio,
        tipo_clase: classType,
      };

      try {
        // Guardar los horarios seleccionados
        await ReservarClases(
          profe.profesor,
          alumno,
          selectedHours,
          idiomaProfesor,
          selectedTime, //tiempo
          selectedLanguageData, //informacion idioma
          precioWithClassType
        );
        toast({
          title: "Éxito",
          description: "La(s) clase(s) ha(n) sido reservada(s) correctamente",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.log(
          "🚀 ~ file: Calendario.tsx:102 ~ handleReserveClick ~ error:",
          error
        );
      }
    } catch (error) {
      console.error("Hubo un error:", error);
    }
  };
  useEffect(() => {
    if (selectedLanguage) {
      const languageData = profe?.profesor?.idiomas_profe.find(
        (idioma: any) => idioma.idioma === selectedLanguage
      );
      setSelectedLanguageData(languageData);
    } else {
      setSelectedLanguageData(null);
    }
  }, [selectedLanguage, profe?.profesor?.idiomas_profe]);

  // Convert classType to display name
  const getClassTypeName = (classType: string) => {
    const baseClassType = classType.split("-")[0];

    switch (baseClassType) {
      case "precio_ocho_clases":
        return "8 Classes Price";
      case "precio_una_clase":
        return "Single Class Price";
      case "precio_clase_prueba":
        return "Trial Class Price";
      default:
        return classType;
    }
  };
  const getPrecio = () => {
    if (!selectedLanguageData || !selectedClassType || !selectedTime) return "";

    const [classType] = selectedClassType.split("-");

    const claseInfo = selectedLanguageData[classType]?.find(
      (clase: any) => clase.tiempo === selectedTime
    );

    return claseInfo?.precio_final || "";
  };
  return (
    <Box borderWidth={1} borderRadius="lg" overflow="hidden" mb="10">
      <Box p="6">
        <Box mb={3} textAlign="center">
          <Text fontSize="2xl">Calendario</Text>
        </Box>
        <Box>
          <Box mb={3} textAlign="center">
            <Text fontSize="2xl">Idioma</Text>
            <Select
              defaultValue={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            >
              <option value="">Seleccione idioma</option>
              {profe?.profesor?.idiomas_profe?.map(
                (idioma: any, index: any) => (
                  <option key={index} value={idioma.idioma}>
                    {idioma.idioma}
                  </option>
                )
              )}
            </Select>
          </Box>

          <Box mb={3} textAlign="center">
            <Text fontSize="2xl">Tipo de clase </Text>
            <Select
              value={selectedClassType}
              onChange={(e) => {
                const [classType, tiempo] = e.target.value.split("-");
                //setSelectedClassType(classType);
                setSelectedClassType(`${classType}-${tiempo}`);

                setSelectedTime(tiempo || "");
              }}
            >
              <option value="">Seleccione Tipo de clase</option>
              {selectedLanguageData?.precio_clase_prueba?.map((clase: any) => (
                <option
                  key={clase.id}
                  value={`precio_clase_prueba-${clase.tiempo}`}
                  data-tiempo={clase.tiempo}
                >
                  Clase de Prueba - {clase.tiempo} min - ${clase.precio_final}
                </option>
              ))}
              {selectedLanguageData?.precio_una_clase?.map((clase: any) => (
                <option
                  key={clase.id}
                  value={`precio_una_clase-${clase.tiempo}`}
                  data-tiempo={clase.tiempo}
                >
                  Una Clase - {clase.tiempo} min - ${clase.precio_final}
                </option>
              ))}
              {selectedLanguageData?.precio_ocho_clases?.map((clase: any) => (
                <option
                  key={clase.id}
                  value={`precio_ocho_clases-${clase.tiempo}`}
                  data-tiempo={clase.tiempo}
                >
                  Ocho Clases - {clase.tiempo} min - ${clase.precio_final}
                </option>
              ))}
            </Select>
          </Box>

          <Box maxW="100%" overflowX="auto">
            <Table minW="1200px">
              <TableHeader
                profesor={profe}
                updateProfesorHorario={updateProfesorHorario}
              ></TableHeader>
              <TableBody
                profesor={profe}
                onUpdateSelectedHours={handleSelectedHoursUpdate}
              ></TableBody>
            </Table>
          </Box>
          <Box textAlign="center" mt="5">
            <Button bg="gray" color="white" mr="5">
              No Disponible
            </Button>
            <Button bg="green" color="white" mr="5">
              Disponible
            </Button>
            <Button bg="orange" color="white">
              Reservada
            </Button>
          </Box>
          <Divider />
          <Box mb={3} mt="70px" textAlign="center">
            <Text fontSize="2xl">Horas seleccionadas:</Text>
          </Box>
          <Box maxHeight="310px" overflowY="auto"></Box>
          <VStack
            spacing={3}
            p={5}
            border="1px"
            borderColor="gray.200"
            borderRadius="lg"
            boxShadow="md"
            width="auto"
            align="start"
          >
            <Heading size="md">Reservation Details</Heading>
            {Object.keys(selectedHours).length > 0 &&
            selectedLanguage &&
            selectedTime &&
            selectedClassType ? (
              <>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Fecha</Th>
                      <Th>Hora de inicio</Th>
                      <Th>Hora de fin</Th>
                      <Th>Acciones</Th>
                    </Tr>
                  </Thead>
                </Table>
                <Table>
                  <Tbody>
                    {Object.entries(selectedHours)?.map(([key, horario]) => (
                      <Tr key={key} _hover={{ backgroundColor: "green.100" }}>
                        <Td>
                          {horario.nombre_dia} {horario.fecha_dia}
                        </Td>
                        <Td>{horario.hora_inicio}</Td>
                        <Td>{horario.hora_fin}</Td>
                        <Td>
                          <Button
                            colorScheme="red"
                            size="sm"
                            leftIcon={<CloseIcon />}
                            onClick={() =>
                              handleRemoveClick(
                                horario.fecha_dia,
                                horario.hora_inicio
                              )
                            }
                          >
                            Eliminar
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Box>
                  <Text fontWeight="semibold">Language:</Text>
                  <Text>{selectedLanguage}</Text>
                </Box>
                <Box>
                  <Text fontWeight="semibold">Time:</Text>
                  <Text>{selectedTime}</Text>
                </Box>
                <Box>
                  <Text fontWeight="semibold">Class Type:</Text>
                  <Text>{getClassTypeName(selectedClassType)}</Text>
                </Box>
                <Box>
                  <Text fontWeight="semibold">Price:</Text>
                  <Text>${getPrecio()}</Text>
                </Box>
                <Box>
                  <Button onClick={handleReserveClick} bg="green" color="white">
                    Reservar
                  </Button>
                </Box>
              </>
            ) : (
              <Alert status="warning">
                <AlertIcon />
                Need to select language, time and class type
              </Alert>
            )}
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default Calendario;
