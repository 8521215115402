import { Box } from "@chakra-ui/react";
import React from "react";
import PerfilUsuario from "../../../components/dashboard/PerfilUsuario";
import { perfilUsuario } from "../../../interfaces/TipoUsuario.interface";
import ProtectedRoute from "../../../components/ProtectedRoute";

const Perfil = () => {
  const allowedTypes = [
    perfilUsuario.superAdministrador,
    perfilUsuario.administrador,
    perfilUsuario.profesor,
    perfilUsuario.alumno,
  ];

  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box>
        <PerfilUsuario></PerfilUsuario>
      </Box>
    </ProtectedRoute>
  );
};

export default Perfil;
