import React from "react";
import { Box } from "@chakra-ui/react";
import IniciarSesion from "../../components/login/IniciarSesion";

const Login = () => {
  return (
    <Box>
      <IniciarSesion></IniciarSesion>
    </Box>
  );
};

export default Login;
