import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { AddUsuario } from "../utils/userApi";

const ModalAgregarUsuario = ({ usuarios, setusuarios }: any) => {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const registrarUsuario = async (values: any) => {
    try {
      const data = await AddUsuario(values);
      setusuarios(data?.usuarios);
      toast({
        title: "Éxito",
        description: "El usuario ha sido creado",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Hubo un error al crear el usuario",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre es obligatorio"),
    apellido: Yup.string().required("El apellido es obligatorio"),
    email: Yup.string()
      .email("Email inválido")
      .required("El email es obligatorio"),
    tipoUsuario: Yup.string().required("Selecciona el tipo de usuario"),
  });

  return (
    <>
      <Text>
        <Button colorScheme="green" onClick={handleOpenModal}>
          Agregar Usuario
        </Button>
      </Text>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registrar Usuario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                nombre: "",
                apellido: "",
                email: "",
                tipoUsuario: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                registrarUsuario(values);
                handleCloseModal(); // Close the modal after submitting the form
              }}
            >
              <Form>
                <Field name="nombre">
                  {({ field }: any) => (
                    <FormControl>
                      <FormLabel>Nombre</FormLabel>
                      <Input {...field} type="text" />
                      <ErrorMessage name="nombre">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="apellido">
                  {({ field }: any) => (
                    <FormControl>
                      <FormLabel>Apellido</FormLabel>
                      <Input {...field} type="text" />
                      <ErrorMessage name="apellido">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field }: any) => (
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <Input {...field} type="email" />
                      <ErrorMessage name="email">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="tipoUsuario">
                  {({ field }: any) => (
                    <FormControl>
                      <FormLabel>Tipo de Usuario</FormLabel>
                      <Select {...field}>
                        <option value="">Seleccionar tipo de usuario</option>
                        <option value="2">Profesor</option>
                        <option value="3">Alumno</option>
                        <option value="4">Administrador</option>
                      </Select>
                      <ErrorMessage name="tipoUsuario">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button colorScheme="red" mr={3} onClick={handleCloseModal}>
                  Cerrar
                </Button>
                <Button colorScheme="green" type="submit">
                  Registrar
                </Button>
              </Form>
            </Formik>
          </ModalBody>
          <ModalFooter justifyContent="center"></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAgregarUsuario;
