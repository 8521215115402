// AppRoutes.js (or AppRoutes.tsx if using TypeScript)

import React from "react";
import { Routes, Route } from "react-router-dom";

import Homepage from "./pages/Home/Homepage";
import Profesores from "./pages/Profesores/Profesores";
import Idioma from "./components/teacher/Idioma";
import Nosotros from "./components/footer/nosotros";
import TerminosCondiciones from "./components/footer/TerminosCondiciones";
import Contactanos from "./components/contactanos/contactanos";
import Login from "./pages/Login/login";
import Dashboard from "./components/dashboard/Dashboard";
import ProximasClasesView from "./components/dashboard/ProximasClasesView";
import ProfesoresView from "./components/dashboard/ProfesoresView";
import Perfil from "./pages/Dashboard/Perfil/Perfil";
import Usuarios from "./pages/Dashboard/Usuario/Usuarios";
import Estudio from "./pages/Dashboard/Estudio/Estudio";
import Idiomas from "./pages//Dashboard/Idioma/Idiomas";
import Configuraciones from "./pages/Dashboard/Configuraciones/Configuraciones";
import Alumnos from "./pages/Dashboard/Alumnos/Alumnos";
import Validacion from "./pages/Dashboard/Validacion/Validacion";
import Horario from "./pages/Dashboard/Horario/Horario";
import Pagos from "./pages/Dashboard/Pagos/Pagos";
import { DetalleProfesor } from "./pages/Profesores/DetalleProfesor";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/acerca_nosotros" element={<Nosotros />} />
      <Route path="/terminos_y_condiciones" element={<TerminosCondiciones />} />
      <Route path="/login" element={<Login />} />
      <Route path="/contacto" element={<Contactanos />} />
      <Route path="/profesores" element={<Profesores />} />
      <Route path="/profesor/idioma/:id" element={<Idioma />} />
      {/* Rutas restringidas */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/perfil" element={<Perfil />} />
      <Route
        path="/dashboard/proximas_clases"
        element={<ProximasClasesView />}
      />
      <Route path="/dashboard/profesores" element={<ProfesoresView />} />
      <Route
        path="/profesores/:profesor/:idProfesor"
        element={<DetalleProfesor />}
      />
      <Route path="/dashboard/idiomas" element={<Idiomas />} />
      <Route path="/dashboard/estudios" element={<Estudio />} />
      <Route path="/dashboard/pagos" element={<Pagos />} />
      <Route path="/dashboard/alumnos" element={<Alumnos />} />
      <Route path="/dashboard/horario" element={<Horario />} />
      <Route path="/dashboard/configuraciones" element={<Configuraciones />} />
      <Route path="/dashboard/validaciones" element={<Validacion />} />
      <Route path="/dashboard/usuarios" element={<Usuarios />} />
    </Routes>
  );
};

export default AppRoutes;
