// authStore.ts
import create from "zustand";
import axios from "axios";
import { login as loginUser } from "../components/utils/api";
import { env } from "../components/utils/env";

const API_URL = env("REACT_APP_API_URL");

type User = {
  id?: number;
  usuario?: string;
  email?: string;
  nombre?: string;
  apellido?: string;
  descripcion?: string;
  texto?: string;
  foto?: string;
  tipo_contacto?: string;
  celular?: string;
  tipo_video?: string;
  video?: string;
  pais_origen?: string;
  pais_residencia?: string;
  tipo_usuario?: number;
  timezone?: number;
  reg_estado?: number;
  fecha_creacion?: Date;
  validaciones?: {
    msg_estudios: string;
    msg_horarios: string;
    msg_idiomas_ensenha: string;
    msg_idiomas_habla: string;
    msg_perfil: string;
    cursos: [];
  };
  pais_origen_name?: string;
  pais_residencia_name?: string;
  idiomas_ensenha?: [];
  idiomas_habla?: [];
  token?: string;
};

type AuthState = {
  user: User | null;
  token: string | null;
  isLogged: boolean;
  error: string | null;
  register: (userInfo: any) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
};

type AxiosErrorType = {
  response?: {
    data?: {
      message?: string;
    };
  };
  message?: string;
};
const initialUser = JSON.parse(localStorage.getItem("user") || "null") || null;
const initialToken = localStorage.getItem("token") || null;
const initialUserInfo = localStorage.getItem("userInfo") || null;
export const useAuthStore = create<AuthState>((set) => ({
  user: initialUser,
  token: initialToken,
  userInfo: initialUserInfo,
  isLogged: initialToken !== null,
  error: null,

  register: async (userInfo: any) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post(`${API_URL}login/registrarse`, { userInfo }, config);
    } catch (rawError) {
      const error = rawError as AxiosErrorType;
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Unknown error occurred";
      set({ error: errorMessage });
    }
  },

  login: async (email: string, password: string) => {
    try {
      const data = await loginUser(email, password);
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.info_usuario));
      set({
        user: data.info_usuario,
        token: data.token,
        isLogged: true,
        error: null,
      });
    } catch (rawError) {
      const error = rawError as AxiosErrorType;
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Unknown error occurred";
      set({ error: errorMessage });
    }
  },

  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("userInfo");
    set({ user: null, token: null, isLogged: false });
  },
}));
