// api.ts
import axios from "axios";
import { env } from "./env";
const API_URL = env("REACT_APP_API_URL");

export const fetchCursos = async (id_usuario: any) => {
  try {
    const response = await axios.get(
      `${API_URL}curso/get_all_curso/${id_usuario}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener el usuario: ${error}`);
  }
};
export const fetchCursosPendientes = async () => {
  try {
    const response = await axios.get(
      `${API_URL}curso/get_all_curso_pend_validacion`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Error al obtener los cursos pendiente de validación: ${error}`
    );
  }
};

export const AddIdiomaProfesor = async (idioma: any, id_usuario: any) => {
  try {
    const response = await axios.post(
      `${API_URL}idioma_profesor/add_profesor_habla`,
      {
        params: {
          idioma: idioma,
          id_usuario: id_usuario,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al agregar el usuario: ${error}`);
  }
};
export const addHorarioProfesor = async (horario: any, id_profesor: any) => {
  try {
    const response = await axios.post(
      `${API_URL}horario_profesor/add_horario_profesor`,
      {
        params: {
          horario: horario,
          id_profesor: id_profesor,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al actualizar el horario del profesor: ${error}`);
  }
};

export const fetchHorario = async (id_usuario: any) => {
  try {
    const response = await axios.get(
      `${API_URL}horario_profesor/get_all_horario_profesor/${id_usuario}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener el usuario: ${error}`);
  }
};
export const AddIdiomaEnsenhaProfesor = async (
  idioma: any,
  id_usuario: any
) => {
  try {
    const response = await axios.post(
      `${API_URL}idioma_profesor/add_profesor_ensenha`,
      {
        params: {
          idioma: idioma,
          id_usuario: id_usuario,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al agregar el usuario: ${error}`);
  }
};

export const actualizar_clase_profesor = async (
  idioma: any,
  id_usuario: any
) => {
  try {
    const response = await axios.post(
      `${API_URL}clase_profesor/actualizar_clase_profesor`,
      {
        params: {
          idioma: idioma,
          id_usuario: id_usuario,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al agregar el usuario: ${error}`);
  }
};

export const UpdateCurso = async (curso: any) => {
  try {
    const response = await axios.post(`${API_URL}curso/deshabilitar_curso`, {
      params: {
        curso: curso,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al actualizar el curso: ${error}`);
  }
};

export const addCurso = async (curso: any) => {
  try {
    const response = await axios.post(`${API_URL}curso/add_curso`, {
      params: {
        curso: curso,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al actualizar el curso: ${error}`);
  }
};

export const rechazarCurso = async (curso: any) => {
  try {
    const response = await axios.post(`${API_URL}curso/rechazar_curso`, {
      params: {
        curso: curso,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al rechazar el curso: ${error}`);
  }
};
export const aprobarCurso = async (curso: any) => {
  try {
    const response = await axios.post(`${API_URL}curso/aprobar_curso`, {
      params: {
        curso: curso,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al aprobar el curso: ${error}`);
  }
};

export const updateIdiomaProfesor = async (
  idioma_profesor: any,
  nivel: any,
  id_usuario: any
) => {
  try {
    const response = await axios.post(
      `${API_URL}idioma_profesor/update_idioma_profesor`,
      {
        params: {
          idioma_profesor: idioma_profesor,
          nivel: nivel,
          id_usuario: id_usuario,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al actualizar el usuario: ${error}`);
  }
};

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${API_URL}login/iniciar_sesion`, {
      params: {
        email: email,
        password: password,
      },
    });
    if (response.data.status === "ERROR") {
      // setErrorUsuario(response.data.msg);
    } else if (response.data.status === "OK") {
      //window.location.href = "dashboard";
    }

    return response.data;
  } catch (error) {
    throw new Error(`Error al iniciar sesión: ${error}`);
  }
};

export const getToken = async (token: any) => {
  try {
    const response = await axios.post(`${API_URL}login/obtenerToken`, {
      params: {
        token: token,
      },
    });
    if (response.data.status === "ERROR") {
      // setErrorUsuario(response.data.msg);
    } else if (response.data.status === "OK") {
      //window.location.href = "dashboard";
    }

    return response.data;
  } catch (error) {
    //throw new Error(`Error al obtener el token: ${error}`);
    console.error("Error al obtener el token");
  }
};

export const fetchTimezones = async () => {
  try {
    const response = await axios.get(`${API_URL}timezones/get_all_timezones`);
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener las zonas horarias: ${error}`);
  }
};

export const updateImage = async (image: string, usuario: any) => {
  try {
    const response = await axios.post(`${API_URL}usuario/updateImage`, {
      params: {
        image: image,
        usuario: usuario,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al subir la imagen: ${error}`);
  }
};

export const updateContacto = async (contacto: any) => {
  try {
    const response = await axios.post(`${API_URL}comision/update_contacto`, {
      params: {
        contacto: contacto,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al actualizar contactos del sitio web: ${error}`);
  }
};

export const updateComision = async (
  porcentaje: any,
  tiempocancelacion: any
) => {
  try {
    const response = await axios.post(`${API_URL}comision/update_comision`, {
      params: {
        porcentaje: porcentaje,
        tiempocancelacion: tiempocancelacion,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error al actualizar la comisión: ${error}`);
  }
};

export const getComision = async () => {
  try {
    const response = await axios.get(`${API_URL}comision/get_comision`);
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener la información de langport: ${error}`);
  }
};
export const removeIdiomaProfesor = async (
  idiomaBanderasItem: any,
  id_usuario: any
): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}idioma_profesor/deshabilitar_idioma_profesor_ensenha`,
      {
        params: {
          idioma_profesor: idiomaBanderasItem,
          id_usuario: id_usuario,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error al obtener mascotas: ${error}`);
  }
};
