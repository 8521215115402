import React, { MouseEventHandler } from "react";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
} from "@chakra-ui/react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { RootState } from "../app/store";
import { useDispatch, useSelector } from "react-redux";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { setSelectedLanguage } from "../features/language/languageSlice";
import { env } from "./utils/env";
import { Info } from "../utils/buttons";
const MenuDesktop = () => {
  const URL = env("REACT_APP_URL");
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isActive = (path: string): boolean => {
    return pathname === path;
  };
  const { selectedLanguage, languageData, icon } = useSelector(
    (state: RootState) => state.language
  );
  const handleLanguageChange: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const lang = event.currentTarget.getAttribute("data-value");
    dispatch(setSelectedLanguage(lang!));
  };
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <RouterLink to="/">
            <img src="/images/Langport70x70.png" alt="Langport" />
          </RouterLink>
        </Flex>
        <Flex alignItems="center">
          <Flex pl="100%">
            <RouterLink
              to="/"
              className={isActive("/") ? "active" : ""}
              style={{
                color: isActive("/") ? "#2E3192" : "inherit",
                marginLeft: "20px",
                fontWeight: "bold",
                borderBottom: `2px solid ${
                  isActive("/") ? "#2E3192" : "transparent"
                }`,
              }}
            >
              {languageData.home}
            </RouterLink>
            <RouterLink
              to="/profesores"
              className={isActive("/profesores") ? "active" : ""}
              style={{
                color: isActive("/profesores") ? "#2E3192" : "inherit",
                marginLeft: "20px",
                fontWeight: "bold",
                borderBottom: `2px solid ${
                  isActive("/profesores") ? "#2E3192" : "transparent"
                }`,
              }}
            >
              {languageData.profesores}
            </RouterLink>
          </Flex>
        </Flex>
        <Flex alignItems="center" paddingRight="25%">
          {!token ? (
            <>
              <RouterLink to="/login">
                <Info message={languageData.iniciar_sesion}></Info>
              </RouterLink>
              <Box mr="2%"></Box>
              <RouterLink to="/registrarse">
                <Info message={languageData.registrarse}></Info>
              </RouterLink>
            </>
          ) : (
            <RouterLink to="/dashboard">
              <Button backgroundColor="#5b70f9" color="white" mr="1%">
                {languageData.mi_perfil}
              </Button>
            </RouterLink>
          )}
          <Menu>
            <MenuButton color="red">
              <Image
                src={`${URL}images/flags/${icon}`}
                alt={selectedLanguage}
                width="35px"
                style={{ marginRight: "10px" }}
              />
              <ChevronDownIcon style={{ display: "initial" }} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleLanguageChange} data-value="es">
                <Image
                  src={`${URL}images/flags/es.png`}
                  alt="Español"
                  width="35px"
                  style={{ marginRight: "10px" }}
                ></Image>
                Español
              </MenuItem>
              <MenuItem onClick={handleLanguageChange} data-value="en">
                <Image
                  src={`${URL}images/flags/us.png`}
                  alt="English"
                  width="35px"
                  style={{ marginRight: "10px" }}
                ></Image>
                English
              </MenuItem>
              <MenuItem onClick={handleLanguageChange} data-value="ua">
                <Image
                  src={`${URL}images/flags/ua.png`}
                  alt="Українська"
                  width="35px"
                  style={{ marginRight: "10px" }}
                ></Image>
                Українська
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </>
  );
};

export default MenuDesktop;
