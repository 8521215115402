import React from "react";
import { Box, Grid, Image, Text, Link } from "@chakra-ui/react";
import { env } from "../utils/env";

const ProfesoresList = ({ profesores }: any) => {
  const URL = env("REACT_APP_URL");
  const desktopColumns = 4; // columnas desktop
  const tabletColumns = 3; //  columnas tablet
  const mobileColumns = 2; //  columnas mobile

  let width = `repeat(${desktopColumns}, 1fr)`;

  if (window.innerWidth <= 1024) {
    width = `repeat(${tabletColumns}, 1fr)`;
  }

  if (window.innerWidth <= 768) {
    width = `repeat(${mobileColumns}, 1fr)`;
  }

  return (
    <>
      <Grid templateColumns={width} gap={4}>
        {profesores?.map((profesor: any, index: number) => (
          <Box width="100%" key={profesor.id}>
            <Box className="card el-card">
              <Link
                href={`${URL}profesor/${profesor?.nombre_slug}/${profesor?.id}`}
                key={index}
              >
                <Box className="inner" p={4}>
                  <Box className="avatar">
                    <Image
                      src={`${URL}images/profesores/${profesor?.foto}`}
                      alt="alt"
                      boxSize="100px"
                    />
                  </Box>
                  <Box className="details" mt={4}>
                    <Text as="h3">{profesor?.nombre}</Text>
                  </Box>
                  <Box className="other" mt={4}>
                    <Text textAlign="center">
                      <span>Precio desde</span>{" "}
                      <strong className="text-color new-price">
                        ${profesor?.info_profe}
                      </strong>
                    </Text>
                  </Box>
                  <ul className="icons icons-left">
                    {profesor?.idiomas_profe?.map((idioma: any) => (
                      <li key={idioma.id}>
                        {" "}
                        <Box>
                          <Image
                            src={`${URL}images/flags-medium/${idioma.bandera}`}
                            alt={idioma?.nombre}
                            className="icon-lang"
                          />
                        </Box>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Link>
            </Box>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default ProfesoresList;
