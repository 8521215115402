import React from "react";
import { Button } from "@chakra-ui/react";

const DescargarPDF = ({ curso }: any) => {
  return (
    <>
      {curso?.pdf ? (
        <Button
          as="a"
          href={curso.pdf}
          download={`curso_${curso.anho}_${curso.nombre_curso}.pdf`}
        >
          Descargar PDF
        </Button>
      ) : (
        "No posee archivo PDF"
      )}
    </>
  );
};

export default DescargarPDF;
