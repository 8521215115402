import React from "react";
import {
  Table,
  Thead,
  Th,
  Button,
  Tbody,
  Tr,
  Td,
  Text,
} from "@chakra-ui/react";

import { Usuario } from "../../interfaces/Usuario.interface";
const TablaUsuarios = ({ dataTabla }: any) => {
  return (
    <Table variant="striped">
      <Thead>
        <Tr>
          {dataTabla?.tableHeaders?.map(({ label, key }: any) => (
            <Th key={key}>{label}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {dataTabla?.usuarios?.map((usuario: Usuario, index: number) => (
          <Tr key={index}>
            <Td>{usuario.id}</Td>
            <Td>
              {usuario.nombre} {usuario.apellido}
            </Td>
            <Td>{usuario.email}</Td>
            <Td>
              {usuario.celular && (
                <Text>
                  {usuario.celular} ({usuario.tipo_contacto})
                </Text>
              )}
            </Td>
            {/*
            <Td>
              <Button colorScheme="teal" size="sm">
                Action
              </Button>
            </Td>
            */}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default TablaUsuarios;
