import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { UpdateCurso } from "../../utils/api";

const EliminarCurso = ({ curso, setCursos }: any) => {
  const toast = useToast();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const handleDelete = async () => {
    try {
      const data = await UpdateCurso(curso);
      setCursos(data.cursos);
      toast({
        title: "Éxito",
        description: "Se ha eliminado el curso " + curso.nombre_curso,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Hubo un error al eliminar el curso " + curso.nombre_curso,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(error);
    }
  };
  const removeCurso = async () => {
    setShowConfirmationDialog(true);
  };
  return (
    <>
      <Button colorScheme="red" onClick={() => removeCurso()}>
        Eliminar
      </Button>
      {showConfirmationDialog && (
        <AlertDialog
          isOpen={showConfirmationDialog}
          leastDestructiveRef={cancelRef}
          onClose={() => setShowConfirmationDialog(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Eliminar idioma que enseña
              </AlertDialogHeader>

              <AlertDialogBody>
                ¿Estás seguro de que deseas eliminar el curso{" "}
                <b>'{curso.nombre_curso}'?</b>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => setShowConfirmationDialog(false)}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    handleDelete();
                    setShowConfirmationDialog(false);
                  }}
                  ml={3}
                >
                  Eliminar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default EliminarCurso;
