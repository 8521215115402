import { Box } from "@chakra-ui/react";
import Languages from "../../components/language/Languages";

function Idiomas() {
  return (
    <Box marginLeft="5%" marginTop="5%" marginRight="5%">
      <Languages></Languages>
    </Box>
  );
}

export default Idiomas;
