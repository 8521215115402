import ProtectedRoute from "../../../components/ProtectedRoute";
import InfoHorario from "../../../components/dashboard/horario/InfoHorario";
import { perfilUsuario } from "../../../interfaces/TipoUsuario.interface";
const Horario = () => {
  const allowedTypes = [
    perfilUsuario.profesor,
    perfilUsuario.superAdministrador,
  ];
  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <InfoHorario></InfoHorario>
    </ProtectedRoute>
  );
};

export default Horario;
