import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import TablaAlumnos from "../../../components/common/TablaAlumnos";
import { Usuario } from "../../../interfaces/Usuario.interface";
import { fetchAlumnos } from "../../../components/utils/userApi";
import { perfilUsuario } from "../../../interfaces/TipoUsuario.interface";
import ProtectedRoute from "../../../components/ProtectedRoute";
const Alumnos = () => {
  const [usuarios, setusuarios] = useState<Usuario[]>([]);
  const allowedTypes = [perfilUsuario.profesor];
  const isFirstRender = useRef(true);

  const tableHeaders = [
    { label: "ID", key: "id" },
    { label: "Alumno", key: "alumno" },
    { label: "Profesor", key: "profesor" },
    { label: "Idioma", key: "idioma" },
    {
      label: "DÍAS PARA CAMBIO DE PRECIO (360 DÍAS)",
      key: "dias_cambio_precio",
    },
    { label: "Acción", key: "accion" },
  ];

  const dataTabla = {
    usuarios: usuarios,
    tableHeaders: tableHeaders,
  };

  const obtenerAlumnos = useCallback(async () => {
    try {
      const data = await fetchAlumnos();
      setusuarios(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      obtenerAlumnos();
      isFirstRender.current = false;
    }
  }, [obtenerAlumnos, usuarios]);

  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box marginLeft="5%" marginTop="5%" marginRight="5%">
        <Box className="container" mb={3}>
          <TablaAlumnos dataTabla={dataTabla}></TablaAlumnos>
        </Box>
      </Box>
    </ProtectedRoute>
  );
};

export default Alumnos;
