import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { pageViewed } from "../../mixpanel/mixpanel";

const TerminosCondiciones = () => {
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      pageViewed("");
      isFirstRender.current = false;
    }
  }, []);
  const { languageData } = useSelector((state: RootState) => state.language);
  return (
    <div className="page page-inferior page-faq">
      <section className="section section-faq section-faq-1">
        <div className="display-spacing">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="el-panel">
                  <div className="el-panel-body">
                    <div className="el-panel-title">
                      <h3>{languageData.terminos_y_condiciones_titulo}</h3>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: languageData.terminos_y_condiciones_texto,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TerminosCondiciones;
