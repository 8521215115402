import { iconsByCode } from "./codes";
import { withIcon } from "./Wrapper";

export * from "./brands";
export const AngleRight = withIcon(null, iconsByCode.angleRight);
export const CircleInfo = withIcon(null, iconsByCode.circleInfo);
export const CircleXmark = withIcon(null, iconsByCode.circleXmark);
export const ClockRotateLeft = withIcon(null, iconsByCode.clockRotateLeft);
export const DoorOpen = withIcon(null, iconsByCode.doorOpen);
export const FilePen = withIcon(null, iconsByCode.filePen);
export const Loader = withIcon(null, iconsByCode.loader);
export const PenField = withIcon(null, iconsByCode.penField);
export const PenToSquare = withIcon(null, iconsByCode.penToSquare);
export const MagnifyingGlass = withIcon(null, iconsByCode.magnifyingGlass);
export const SquarePlus = withIcon(null, iconsByCode.squarePlus);
export const Timer = withIcon(null, iconsByCode.timer);
export const UpToLine = withIcon(null, iconsByCode.upToLine);
export const Xmark = withIcon(null, iconsByCode.xmark);
export const ChevronDown = withIcon(null, iconsByCode.chevronDown);
export const CircleCheck = withIcon(null, iconsByCode.circleCheck);
export const TrashCan = withIcon(null, iconsByCode.trashCan);
export const GripLines = withIcon(null, iconsByCode.gripLines);
export const CirclePlus = withIcon(null, iconsByCode.circlePlus);
export const CircleChevronLeft = withIcon(null, iconsByCode.circleChevronLeft);
export const Compass = withIcon(null, iconsByCode.compass);
export const Pen = withIcon(null, iconsByCode.pen);
export const ChevronLeft = withIcon(null, iconsByCode.chevronLeft);
export const SlidersUp = withIcon(null, iconsByCode.slidersUp);
export const Bars = withIcon(null, iconsByCode.bars);
export const RightToBracket = withIcon(null, iconsByCode.rightToBracket);
export const CloudArrowUp = withIcon(null, iconsByCode.cloudArrowUp);
export const ChevronsRight = withIcon(null, iconsByCode.chevronsRight);
export const ChevronsLeft = withIcon(null, iconsByCode.chevronsLeft);
export const ChevronRight = withIcon(null, iconsByCode.chevronRight);
export const ChevronUp = withIcon(null, iconsByCode.chevronUp);
export const Minus = withIcon(null, iconsByCode.minus);
export const Plus = withIcon(null, iconsByCode.plus);
export const UserShield = withIcon(null, iconsByCode.userShield);
export const CircleChevronDown = withIcon(null, iconsByCode.circleChevronDown);
export const CircleChevronUp = withIcon(null, iconsByCode.circleChevronUp);
export const CircleMinus = withIcon(null, iconsByCode.circleMinus);
export const FlaskRoundPotion = withIcon(null, iconsByCode.flaskRoundPotion);
export const Envelope = withIcon(null, iconsByCode.envelope);
export const CirclePlay = withIcon(null, iconsByCode.circlePlay);
export const BackwardStep = withIcon(null, iconsByCode.backwardStep);
export const Building = withIcon(null, iconsByCode.building);
export const Circle = withIcon(null, iconsByCode.circle);
export const ForwardStep = withIcon(null, iconsByCode.forwardStep);
export const ListUl = withIcon(null, iconsByCode.listUl);
export const Pencil = withIcon(null, iconsByCode.pencil);
export const Play = withIcon(null, iconsByCode.play);
export const Check = withIcon(null, iconsByCode.check);
export const CaretDown = withIcon(null, iconsByCode.caretDown);
export const CircleExclamation = withIcon(null, iconsByCode.circleExclamation);
export const Stopwatch = withIcon(null, iconsByCode.stopwatch);
export const ArrowRightLong = withIcon(null, iconsByCode.arrowRightLong);
export const Clone = withIcon(null, iconsByCode.clone);
export const Comments = withIcon(null, iconsByCode.comments);
export const ArrowLeft = withIcon(null, iconsByCode.arrowLeft);
export const CircleQuestion = withIcon(null, iconsByCode.circleQuestion);
export const Asterisk = withIcon(null, iconsByCode.asterisk);
export const Calendar = withIcon(null, iconsByCode.calendar);
export const SquareCheck = withIcon(null, iconsByCode.squareCheck);
export const Square = withIcon(null, iconsByCode.square);
export const ArrowRight = withIcon(null, iconsByCode.arrowRight);
export const Book = withIcon(null, iconsByCode.book);
export const BookOpen = withIcon(null, iconsByCode.bookOpen);
export const CalendarDays = withIcon(null, iconsByCode.calendarDays);
export const CaretLeft = withIcon(null, iconsByCode.caretLeft);
export const Comment = withIcon(null, iconsByCode.comment);
export const Download = withIcon(null, iconsByCode.download);
export const UpRightFromSquare = withIcon(null, iconsByCode.upRightFromSquare);
export const Eye = withIcon(null, iconsByCode.eye);
export const Fax = withIcon(null, iconsByCode.fax);
export const Globe = withIcon(null, iconsByCode.globe);
export const GraduationCap = withIcon(null, iconsByCode.graduationCap);
export const Info = withIcon(null, iconsByCode.info);
export const Language = withIcon(null, iconsByCode.language);
export const Link = withIcon(null, iconsByCode.link);
export const LocationDot = withIcon(null, iconsByCode.locationDot);
export const Medal = withIcon(null, iconsByCode.medal);
export const PhoneFlip = withIcon(null, iconsByCode.phoneFlip);
export const Print = withIcon(null, iconsByCode.print);
export const CircleArrowRight = withIcon(null, iconsByCode.circleArrowRight);
export const Clipboard = withIcon(null, iconsByCode.clipboard);
export const CommentDots = withIcon(null, iconsByCode.commentDots);
export const Star = withIcon(null, iconsByCode.star);
export const SquareXmark = withIcon(null, iconsByCode.squareXmark);
export const Share = withIcon(null, iconsByCode.share);
export const RightFromBracket = withIcon(null, iconsByCode.rightFromBracket);
export const BuildingColumns = withIcon(null, iconsByCode.buildingColumns);
export const AlarmClock = withIcon(null, iconsByCode.alarmClock);
export const Ballot = withIcon(null, iconsByCode.ballot);
export const UsersLine = withIcon(null, iconsByCode.usersLine);
export const ArrowTurnDownLeft = withIcon(null, iconsByCode.arrowTurnDownLeft);
export const NewsPaper = withIcon(null, iconsByCode.newsPaper);
export const EllipsisVertical = withIcon(null, iconsByCode.ellipsisVertical);
export const ShareAll = withIcon(null, iconsByCode.shareAll);
export const ChartMixed = withIcon(null, iconsByCode.chartMixed);
export const FileLines = withIcon(null, iconsByCode.fileLines);
