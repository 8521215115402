import {
  Box,
  Flex,
  Alert,
  Input,
  Text,
  Textarea,
  Button,
  useDisclosure,
  Center,
  useToast,
} from "@chakra-ui/react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { fetchTimezones } from "../utils/api";
import UploadImagePerfil from "../common/UploadImagePerfil";
import FormLabelInput from "./FormLabelInput";
import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { UpdateUsuario, useUser } from "../utils/userApi";
import CountrySelect from "../common/CountrySelect";
import VideoPlatformSelect from "../common/VideoPlatformSelect";
import ContactApplicationSelect from "../common/ContactUserSelect";
import TextAreaWithCharacterLimit from "../common/TextAreaWithCharacterLimit";
import TimezoneSelect from "../common/TimezoneSelect ";
import { fecthPaises } from "../utils/paises";
import { useSelector } from "react-redux";
import { RootState, store } from "../../app/store";
import { useAuthStore } from "../../app/authStore";
function PerfilUsuario() {
  const toast = useToast();
  const { user } = useAuthStore((state: any) => state);
  const { data: usuario } = useUser(user?.id);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onToggle } = useDisclosure();
  const isFirstRender = useRef(true);
  const [paises, setPaises] = useState([]);
  const [timezones, setTimezones] = useState<undefined | any[]>(undefined);
  const [respuestaUpdate, setrespuestaUpdate] = useState("");

  interface Country {
    countrycode: number;
    countryname: string;
  }
  const AddUsuarioClick = async (usuarioData: any) => {
    try {
      const data = await UpdateUsuario(usuarioData);
      toast({
        title: "Éxito",
        description: "El usuario ha sido actualizado",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Hubo un error al actualizar el usuario",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  const obtenerPaises = async () => {
    try {
      const data = await fecthPaises();
      setPaises(data);
    } catch (error) {
      console.error(error);
    }
  };

  const obtenerTimezones = async () => {
    try {
      const data = await fetchTimezones();
      setTimezones(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      obtenerPaises();
      obtenerTimezones();
      //pageViewed("");
      isFirstRender.current = false;
    }
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Campo requerido"),
    //apellido: Yup.string().required("Campo requerido"),
    tipo_contacto: Yup.string().required("Campo requerido"),
    celular: Yup.string().required("Campo requerido"),
    email: Yup.string()
      .required("Campo requerido")
      .email("Dirección de correo electrónico inválida"),
    pais_origen: Yup.string().required("Campo requerido"),
    pais_residencia: Yup.string().required("Campo requerido"),
    timezone: Yup.string().required("Campo requerido"),
    tipo_video: Yup.string().required("Campo requerido"),
    video: Yup.string().required("Campo requerido"),
    descripcion: Yup.string().required("Campo requerido"),
    texto: Yup.string().required("Campo requerido"),
  });

  const handleSubmit = (values: any) => {
    const {
      id,
      name,
      apellido,
      tipo_contacto,
      celular,
      email,
      pais_origen,
      pais_residencia,
      timezone,
      tipo_video,
      video,
      descripcion,
      texto,
    } = values as {
      id: number;
      name: string;
      apellido: string;
      tipo_contacto: string;
      celular: number;
      email: string;
      pais_origen: string;
      pais_residencia: string;
      timezone: number;
      tipo_video: string;
      video: string;
      descripcion: string;
      texto: string;
    };
    const paisOrigen = paises.find(
      (pais: Country) => pais.countryname === pais_origen
    ) as Country | undefined;

    const paisResidencia = paises.find(
      (pais: Country) => pais.countryname === pais_residencia
    ) as Country | undefined;

    const selectedTimezone = timezones?.find(
      (tz: any) => tz.timezone === timezone
    );

    const usuarioData = {
      id,
      name,
      apellido,
      tipo_contacto,
      celular,
      email,
      pais_origen: paisOrigen?.countrycode || "",
      pais_residencia: paisResidencia?.countrycode || "",
      timezone: selectedTimezone?.id || "",
      tipo_video,
      video,
      descripcion,
      texto,
    };
    // Handle form submission
    AddUsuarioClick(usuarioData);
    onToggle();
  };

  useEffect(() => {
    if (usuario?.nombre) {
      setIsLoading(false);
    }
  }, [usuario]);

  return (
    <Box marginLeft="5%" marginTop="5%" marginRight="5%">
      <Box borderWidth={1} borderRadius="lg" overflow="hidden">
        <Box p="6">
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: usuario?.id || "",
              name: usuario?.nombre || "",
              apellido: usuario?.apellido || "",
              tipo_contacto: usuario?.tipo_contacto || "",
              celular: usuario?.celular || "",
              email: usuario?.email || "",
              pais_origen: usuario?.pais_origen_name || "",
              pais_residencia: usuario?.pais_residencia_name || "",
              timezone: usuario?.timezone || "",
              tipo_video: usuario?.tipo_video || "",
              video: usuario?.video || "",
              descripcion: usuario?.descripcion || "",
              texto: usuario?.texto || "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Flex direction="column">
                  <Box mb={3}>
                    <Text fontSize="2xl">Perfil</Text>
                  </Box>

                  <Flex
                    direction={{ base: "column", md: "row" }}
                    alignItems="center"
                  >
                    {usuario && (
                      <UploadImagePerfil usuario={usuario}></UploadImagePerfil>
                    )}
                    <Flex
                      direction={{ base: "column", md: "row" }}
                      flexGrow={1}
                      gap={{ base: 3, md: 5 }}
                    >
                      <Box flex={{ md: 1 }}>
                        <FormLabelInput required>Nombre </FormLabelInput>
                        <Field name="name" id="input-name" as={Input} />
                        <ErrorMessage name="name">
                          {(errorMsg) => (
                            <Text color="red.500" mt={2}>
                              {errorMsg}
                            </Text>
                          )}
                        </ErrorMessage>
                      </Box>

                      <Box flex={{ md: 1 }}>
                        <FormLabelInput>Apellido</FormLabelInput>
                        <Field name="apellido" id="input-apellido" as={Input} />
                      </Box>
                    </Flex>
                  </Flex>

                  {/* Contact fields */}
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    mt={5}
                    gap={{ base: 3, md: 5 }}
                  >
                    <Box flex={{ md: 1 }}>
                      <ContactApplicationSelect name="tipo_contacto" />
                    </Box>

                    <Box flex={{ md: 1 }}>
                      <FormLabelInput required>Celular </FormLabelInput>
                      <Field id="input-celular" as={Input} name="celular" />
                      <ErrorMessage name="celular">
                        {(errorMsg) => (
                          <Text color="red.500" mt={2}>
                            {errorMsg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </Box>

                    <Box flex={{ md: 1 }}>
                      <FormLabelInput required>E-Mail </FormLabelInput>
                      <Field id="input-email" as={Input} name="email" />
                      <ErrorMessage name="email">
                        {(errorMsg) => (
                          <Text color="red.500" mt={2}>
                            {errorMsg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </Box>
                  </Flex>

                  {/* Country and time zone fields */}
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    mt={5}
                    gap={{ base: 3, md: 5 }}
                  >
                    <Box flex={{ md: 1 }}>
                      <FormLabelInput required>País De Origen </FormLabelInput>
                      <CountrySelect
                        id={"iinput-origin-country"}
                        name={"pais_origen"}
                        placeholder={"Seleccione País de Origen"}
                        title={"País de Origen"}
                        paises={paises}
                      ></CountrySelect>
                    </Box>

                    <Box flex={{ md: 1 }}>
                      <FormLabelInput required>
                        País de Residencia
                      </FormLabelInput>
                      <CountrySelect
                        id={"input-residence-country"}
                        name={"pais_residencia"}
                        placeholder={"Seleccione País de Residencia"}
                        title={"País de Residencia"}
                        paises={paises}
                      ></CountrySelect>
                    </Box>

                    <Box flex={{ md: 1 }}>
                      <FormLabelInput required>Zona Horaria</FormLabelInput>
                      <TimezoneSelect
                        name="timezone"
                        timezones={timezones}
                        disabled={""}
                      />
                    </Box>
                  </Flex>

                  {/* Video fields */}
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    mt={5}
                    gap={{ base: 3, md: 5 }}
                  >
                    <VideoPlatformSelect name="tipo_video" />
                  </Flex>
                  {/* About and short description fields */}
                  <Flex direction="column" mt={5} gap={5}>
                    <Box>
                      <FormLabelInput required>Acerca de mi </FormLabelInput>
                      <Field
                        id="input-acerca-de-mi"
                        as={Textarea}
                        name="descripcion"
                        h="200px"
                      />
                      <ErrorMessage name="descripcion">
                        {(errorMsg) => (
                          <Text color="red.500" mt={2}>
                            {errorMsg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </Box>

                    <Box>
                      <TextAreaWithCharacterLimit
                        label="Descripción Corta (Máximo 60 Caracteres)"
                        id="input-descripcion"
                        name="texto"
                        h="100px"
                        maxCharacters="60"
                        setFieldValue={setFieldValue}
                        texto={values.texto}
                      />
                      <ErrorMessage name="texto">
                        {(errorMsg) => (
                          <Text color="red.500" mt={2}>
                            {errorMsg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </Box>
                  </Flex>

                  {respuestaUpdate && (
                    <Alert
                      id="success-alert"
                      status="success"
                      display={isOpen ? "flex" : "none"}
                      mt={5}
                    >
                      <Center>
                        <Text id="mensaje_alerta">{respuestaUpdate}</Text>
                      </Center>
                    </Alert>
                  )}
                  <Button colorScheme="blue" mt="5" type="submit">
                    Save
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default PerfilUsuario;
