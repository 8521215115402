import React from "react";
import { Button, Flex, Center } from "@chakra-ui/react";

const BotonSaldo = () => {
  const buttonStyles = {
    size: "lg",
    width: "50%",
    height: "100px",
    color: "#fff",
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    _hover: {
      backgroundColor: "#007bff",
      borderColor: "#007bff",
    },
    _active: {
      backgroundColor: "#007bff",
      borderColor: "#007bff",
    },
  };

  return (
    <Center>
      <Button style={buttonStyles} as="button" variant="solid">
        <Flex>
          <span className="text">
            Dinero <br /> $ X USD
          </span>
        </Flex>
      </Button>
    </Center>
  );
};

export default BotonSaldo;
