import React, { useEffect, useState } from "react";
import { updateImage } from "../../components/utils/api";
import { env } from "../utils/env";

const UploadImagePerfil = ({ usuario }: any) => {
  const apiUrl = env("REACT_APP_API_URL");
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const uploadImage = async (base64String: string) => {
    try {
      const data = await updateImage(base64String, usuario); // Enviar el Base64 al servidor
      // Aquí puedes manejar la respuesta del servidor si es necesario
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      // Validar el tamaño del archivo (1 MB máximo)
      if (file.size <= 1024 * 1024) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setImageUrl(reader.result as string);
          uploadImage(reader.result as string); // Enviar el Base64 al servidor
        };

        reader.readAsDataURL(file);
      } else {
        // Aquí puedes manejar el caso en que el archivo sea demasiado grande
        console.error("El tamaño del archivo supera 1 MB.");
      }
    }
  };

  useEffect(() => {
    setImageUrl(usuario?.foto);
  }, [apiUrl, usuario]);

  return (
    <div>
      <input
        type="file"
        id="imageUpload"
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />
      <label htmlFor="imageUpload">
        <div
          style={{
            width: "150px",
            height: "150px",
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        ></div>
      </label>
    </div>
  );
};

export default UploadImagePerfil;
