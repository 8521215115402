import React from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

const TablaIdiomas = ({ title, data }: any) => (
  <Table>
    <Thead>
      <Tr>
        <Th>{title}</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td>Cantidad</Td>
        <Td>Minutos</Td>
        <Td>Precio</Td>
      </Tr>
      {data?.map((item: any, index: any) => (
        <Tr key={index}>
          <Td>{item.cantidad_clase}</Td>
          <Td>{item.tiempo}</Td>
          <Td>{item.precio_final} USD</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

export default TablaIdiomas;
