import React, { useState, useEffect } from "react";
import { Box, Input, Button, Text, useToast } from "@chakra-ui/react";
import { updateContacto, getComision } from "../../utils/api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormLabelInput from "../FormLabelInput";

const buttonSize = {
  width: "160px",
  height: "40px",
};

// Radio de los bordes de los botones
const buttonBorderRadius = "6px";

const Contactos = ({ comision }: any) => {
  const toast = useToast();
  const handleSubmit = async (values: any) => {
    try {
      await updateContacto(values);
      toast({
        title: "Éxito",
        description: "La informacion de langport ha sido actualizada",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Hubo un error al actualizar la informacion de langport",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error("Error al actualizar contactos del sitio web:", error);
    }
  };
  const validationSchema = Yup.object({
    facebook: Yup.string().required("Campo requerido"),
    whatsapp: Yup.string().required("Campo requerido"),
    instagram: Yup.string().required("Campo requerido"),
    telegram: Yup.string().required("Campo requerido"),
    email_contacto: Yup.string()
      .required("Campo requerido")
      .email("Email inválido"),
  });

  return (
    <Box>
      <Box
        p={6}
        borderWidth={1}
        borderStyle="solid"
        borderRadius="10px"
        borderColor="#e2e8f0"
        fontFamily='"Poppins", sans-serif'
      >
        <Box mb={3}>
          <Text fontSize="2xl" color="gray.400">
            Información de contacto de Langport
          </Text>
        </Box>

        <Formik
          initialValues={comision}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box mb="5">
                <FormLabelInput required>Facebook</FormLabelInput>
                <Field
                  name="facebook"
                  type="text"
                  as={Input}
                  value={values.facebook}
                />
                <ErrorMessage name="facebook">
                  {(errorMsg) => {
                    return (
                      <Text color="red.500" mt={2}>
                        {errorMsg}
                      </Text>
                    );
                  }}
                </ErrorMessage>
              </Box>
              <Box mb="5">
                <FormLabelInput required>WhatsApp</FormLabelInput>
                <Field
                  name="whatsapp"
                  type="text"
                  as={Input}
                  value={values.whatsapp}
                />
                <ErrorMessage name="whatsapp">
                  {(errorMsg) => {
                    return (
                      <Text color="red.500" mt={2}>
                        {errorMsg}
                      </Text>
                    );
                  }}
                </ErrorMessage>
              </Box>
              <Box mb="5">
                <FormLabelInput required>Instagram</FormLabelInput>
                <Field
                  name="instagram"
                  type="text"
                  as={Input}
                  value={values.instagram}
                />
                <ErrorMessage name="instagram">
                  {(errorMsg) => {
                    return (
                      <Text color="red.500" mt={2}>
                        {errorMsg}
                      </Text>
                    );
                  }}
                </ErrorMessage>
              </Box>
              <Box mb="5">
                <FormLabelInput required>Telegram</FormLabelInput>
                <Field
                  name="telegram"
                  type="text"
                  as={Input}
                  value={values.telegram}
                />
                <ErrorMessage name="telegram">
                  {(errorMsg) => {
                    return (
                      <Text color="red.500" mt={2}>
                        {errorMsg}
                      </Text>
                    );
                  }}
                </ErrorMessage>
              </Box>
              <Box mb="5">
                <FormLabelInput required>Email</FormLabelInput>
                <Field
                  name="email_contacto"
                  type="text"
                  as={Input}
                  value={values.email_contacto}
                />
                <ErrorMessage name="email_contacto">
                  {(errorMsg) => {
                    return (
                      <Text color="red.500" mt={2}>
                        {errorMsg}
                      </Text>
                    );
                  }}
                </ErrorMessage>
              </Box>
              <Box>
                <Button
                  variant="solid"
                  backgroundColor="#28a745"
                  color="white"
                  size="sm"
                  w={buttonSize.width}
                  h={buttonSize.height}
                  borderRadius={buttonBorderRadius}
                  borderColor="#28a745"
                  type="submit"
                >
                  Actualizar Contacto
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default Contactos;
