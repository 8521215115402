import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { addCurso } from "../../utils/api";
import PdfInput from "../../common/PdfInput";
import LastXYearsSelect from "../../common/LastXYearsSelect";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormLabelInput from "../FormLabelInput";
import useUserIdFromToken from "../../../utils/useUserIdFromToken";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useAuthStore } from "../../../app/authStore";

interface Curso {
  id: number;
  nombre_escuela: string;
  anho: number;
  nombre_curso: string;
  id_profesor: string | undefined;
  pdf: string;
  motivo_rechazo: string;
  reg_estado: number;
  fecha_creacion: string;
}

const AgregarCurso = ({ cursos, setCursos }: any) => {
  const toast = useToast();
  const { user } = useAuthStore((state: any) => state);
  const [isOpen, setIsOpen] = useState(false);
  const [nombreEscuela, setNombreEscuela] = useState("");
  const [anho, setAnho] = useState(0);
  const [nombreCurso, setNombreCurso] = useState("");
  const [pdfFile, setPdfFile] = useState<string>("");

  const currentYear = new Date().getFullYear();
  const handleSave = async (values: any) => {
    const newCurso: Curso = {
      id: 0,
      nombre_escuela: values.nombreEscuela,
      anho: values.anho,
      nombre_curso: values.nombreCurso,
      id_profesor: user?.id,
      pdf: pdfFile,
      motivo_rechazo: "",
      reg_estado: pdfFile ? 1 : 2,
      fecha_creacion: "",
    };
    try {
      const data = await addCurso(newCurso);
      setCursos(data.cursos);
      toast({
        title: "Éxito",
        description: "Se ha agregado el curso",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Ha ocurrido un error al agregar el curso",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(error);
    }
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setPdfFile("");
  };
  const validationSchema = Yup.object({
    nombreEscuela: Yup.string().required(
      "El nombre de la escuela es obligatorio"
    ),
    nombreCurso: Yup.string().required("El nombre del curso es obligatorio"),
    anho: Yup.number()
      .required("Selecciona un año")
      .min(currentYear - 20),
  });
  return (
    <>
      <Button colorScheme="green" onClick={() => setIsOpen(true)}>
        Agregar +
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Agregar Curso</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                nombreEscuela: "",
                nombreCurso: "",
                PdfFile: "",
                anho: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSave(values);
              }}
            >
              <Form>
                <Field name="nombreEscuela">
                  {({ field }: any) => (
                    <FormControl mb="5">
                      <FormLabelInput required>
                        Nombre de la Escuela
                      </FormLabelInput>
                      <Input {...field} type="text" />
                      <ErrorMessage name="nombreEscuela">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <LastXYearsSelect year={currentYear} />
                <Field name="nombreCurso">
                  {({ field }: any) => (
                    <FormControl mb="5">
                      <FormLabelInput required>
                        Nombre de la Curso
                      </FormLabelInput>
                      <Input {...field} type="text" />
                      <ErrorMessage name="nombreCurso">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <PdfInput onChange={(file) => setPdfFile(file)} />
                <Button colorScheme="green" type="submit">
                  Guardar
                </Button>
              </Form>
            </Formik>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AgregarCurso;
