import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "./utils/api";
import { isUserTypeAllowed } from "../helper/authUtils";
import { perfilUsuario } from "../interfaces/TipoUsuario.interface";
import { useAuthStore } from "../app/authStore";
import { fetchUsuario } from "./utils/userApi";

interface ProtectedRouteProps {
  allowedTypes: any;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  allowedTypes,
  children,
}) => {
  const token = useAuthStore((state: any) => state.token);
  const navigate = useNavigate();

  const getRoleName = (tipo_usuario: any) => {
    switch (tipo_usuario) {
      case "1":
        return perfilUsuario.superAdministrador;
      case "2":
        return perfilUsuario.profesor;
      case "3":
        return perfilUsuario.alumno;
      case "4":
        return perfilUsuario.administrador;
      default:
        return "Invitado"; // O cualquier valor predeterminado que desees asignar
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        const userInfo = await getToken(token);
        if (userInfo) {
          const role = getRoleName(userInfo?.userType);
          await fetchUsuario(userInfo?.userId);
          if (!isUserTypeAllowed(role, allowedTypes)) {
            navigate("/unauthorized"); // Redirect to unauthorized page
          }
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
        } else {
          navigate("/login"); // Redirect to login page
        }
      } else {
        navigate("/login"); // Redirect to login page
      }
    };

    fetchData();
  }, [allowedTypes, navigate, token]);

  return <>{children}</>;
};

export default ProtectedRoute;
