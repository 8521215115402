import React, { useCallback, useEffect, useRef, useState } from "react";
import TablaUsuarios from "../../../components/common/TablaUsuarios";
import { Box } from "@chakra-ui/react";
import { Usuario } from "../../../interfaces/Usuario.interface";
import ModalAgregarUsuario from "../../../components/common/ModalAgregarUsuario";
import { fetchUsuarios } from "../../../components/utils/userApi";
import ProtectedRoute from "../../../components/ProtectedRoute";
import { perfilUsuario } from "../../../interfaces/TipoUsuario.interface";

const Usuarios = () => {
  const allowedTypes = [perfilUsuario.superAdministrador];
  const [usuarios, setusuarios] = useState<Usuario[]>([]);
  const isFirstRender = useRef(true);

  const obtenerProfesores = useCallback(async () => {
    try {
      const data = await fetchUsuarios();
      setusuarios(data);
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    if (isFirstRender.current) {
      obtenerProfesores();
      isFirstRender.current = false;
    }
  }, [obtenerProfesores, usuarios]);

  const tableHeaders = [
    { label: "ID", key: "id" },
    { label: "Nombre", key: "Nombre" },
    { label: "Email", key: "email" },
    { label: "Celular", key: "celular" },
    //{ label: "Acción", key: "accion" },
  ];

  const dataTabla = {
    usuarios: usuarios,
    tableHeaders: tableHeaders,
  };

  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box marginLeft="5%" marginTop="5%" marginRight="5%">
        <Box className="container" mb={3}>
          <ModalAgregarUsuario usuarios={usuarios} setusuarios={setusuarios} />
          <TablaUsuarios dataTabla={dataTabla}></TablaUsuarios>
        </Box>
      </Box>
    </ProtectedRoute>
  );
};

export default Usuarios;
