import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Center,
  Text,
  tokenToCSSVar,
} from "@chakra-ui/react";
import { pageViewed } from "../../mixpanel/mixpanel";
import Cookies from "js-cookie";
import { login } from "../../components/utils/api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userLogin } from "../../features/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, AppDispatch } from "../../app/store";
import { useAuthStore } from "../../app/authStore";

const IniciarSesion = () => {
  const { login } = useAuthStore();
  const user = useAuthStore((state: any) => state.user);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  if (token && user) {
    navigate("/dashboard");
  }
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      pageViewed("");
      isFirstRender.current = false;
    }
  }, []);

  const handleLogin = async (values: any) => {
    try {
      const { usuario, password } = values;
      login(usuario, password);
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
    }
  };

  const validationSchema = Yup.object({
    usuario: Yup.string().required("Please enter your username"),
    password: Yup.string().required("Please enter your password"),
  });

  return (
    <Box>
      {/* Form */}
      <Flex align="center" justify="center" minHeight="100vh">
        <Box p={8} borderWidth={1} borderRadius="md" shadow="md" bg="white">
          <Center mb={4}>
            <h2>Iniciar sesión</h2>
          </Center>
          <Formik
            initialValues={{
              usuario: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <Box>
                <label>Usuario</label>
                <Field type="text" name="usuario" as={Input} />
                <ErrorMessage name="usuario">
                  {(errorMsg) => (
                    <Text color="red.500" mt={2}>
                      {errorMsg}
                    </Text>
                  )}
                </ErrorMessage>
              </Box>
              <Box>
                <label>Contraseña</label>
                <Field type="password" name="password" as={Input} />
                <ErrorMessage name="password">
                  {(errorMsg) => (
                    <Text color="red.500" mt={2}>
                      {errorMsg}
                    </Text>
                  )}
                </ErrorMessage>
              </Box>
              <Box mt={4}>
                <Button colorScheme="blue" type="submit">
                  Iniciar sesión
                </Button>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Flex>
    </Box>
  );
};

export default IniciarSesion;
