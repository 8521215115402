import { extendTheme } from "@chakra-ui/react";
export const langportColor = {
  //green: "#09974D",
  //blue: "#2E3191",
  yellow: "#DEE953",
  red: "#dc3545",
  blue: "#5b70f9",
  green: "#00c9b7",
};

export const primary = {
  yellow: "#ffce00",
  white: "#fff",
  black: "#2D3136",
  blue: "#4193F4",
  lightBlue: "#ebf8ff",
  blueContrast: "#3075CF", // AX
  blueDarkContrast: "#2950E6", // AX
  darkBlue: "#1C2530",
  mainBackground: "#e3e9f3",
  background: "#F8F8F8",
  border: "#E9E9E9",
  grey: "#F6F7FB",
  green: "#45d4a1",
  greyText: "#858E93",
  greyTextContrast: "#737373", // AX
  greyLine: "#e6e6e6",
  red: "#D90000",
  darkRed: "#ac0101",
  gold: "#856404",
  goldContrast: "#986F00", // AX
  backgroundLightYellow: "#FFF3CD",
  brand: "#006680",
};

export const secondary = {
  purple: "#2e008e",
  badge: "#F94B68",
  shadow: "#2d31364d",
  lightShadow: "#ffffff99",
  background: "#1d2530",
  grey: "#3D3D3D",
  greyText: "#3A3A3A",
  greenAccess: "#00C975",
  darkGreenAccess: "#03884F",
  backgroundRed: "#FCEDED",
  backgroundGreen: "rgba(0, 201, 117, .2)",
  darkBackgroundGreen: "#00663B",
  blueShadow: "rgba(65, 147, 244, 0.39)",
  grayShadow: "rgba(255, 255, 255, 0.3)",
  blackShadow: "rgba(0,0,0,0.8)",
};

export default extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  fonts: {
    heading: "Roboto",
    body: "Roboto",
  },
  colors: {
    primary,
    secondary,
    quiz: {
      primaryBlue: "#2183ED",
      backgroundBlue: "#E3F2FE",
      borderBlue: "#8FC8FE",
      primaryGrey: "#617180",
      tableHeaderGrey: "#666666",
      tableContentsBlack: "#24292E",
      tableRowHighlightGrey: "#F7F7F7",
      primaryWhite: "#ffffff",
      primaryBlack: "#000000",
      filterButtonBlack: "#202020",
      borderPrimaryGrey: "#CDCDCD",
      borderSecondaryGrey: "#EDEDED",
      checkboxBorderGrey: "#B4B4B4",
    },
  },
  zIndices: {
    contact: 10001,
    spinner: 10000,
    navigation: 9999,
    playlistSideNavigation: 9998,
    languageAndShare: 99,
    header: 99,
    explorenavigation: 99,
    alternatenavigation: 99,
    annonce: 99,
  },
  components: {
    Button: {
      variants: {
        genericSubmit: {
          bgColor: primary.blue,
          color: primary.white,
          fontSize: "1rem",
          minH: "2.25rem",
          _hover: {
            bgColor: "#inherit",
            _disabled: {
              bgColor: primary.blue,
              color: primary.white,
            },
          },
        },
        loginOption: {
          display: "flex",
          border: `1px solid ${primary.border}`,
          width: "full",
          bgColor: primary.white,
          borderRadius: "3px",
          boxShadow: "0 2px 4px 0 rgb(0 0 0 / 7%)",
          color: primary.greyTextContrast,
          fontSize: "15px",
          fontWeight: "bold",
          letterSpacing: ".42px",
          lineHeight: "39px",
          textAlign: "center",
          padding: "3.5px 0",
          textDecoration: "none",
          _hover: {
            bgColor: "#inherit",
            _disabled: {
              bgColor: primary.blue,
              color: primary.white,
            },
          },
        },
        authBtn: {
          bgColor: primary.blue,
          color: primary.white,
          borderRadius: "3px",
          boxShadow: "0 9px 18px 0 rgb(65 147 244 / 39%)",
          fontSize: "14px",
          fontWeight: "bold",
        },
        createPlaylistBtn: {
          fontSize: "16px",
          bgColor: primary.blue,
          padding: "0 20px",
          height: "36px",
          borderRadius: "20px",
          color: primary.white,
          _loading: {
            _hover: {
              background: primary.blue,
            },
          },
        },
        playlistSmBtn: {
          backgroundColor: primary.white,
          fontSize: ".875rem",
          color: primary.blue,
          fontWeight: 600,
          border: `1px solid ${primary.border}`,
          cursor: "pointer",
          borderRadius: "3px",
          height: "auto",
          padding: ".25rem .5rem",
        },
        playlistSmPreviewBtn: {
          backgroundColor: primary.blue,
          fontSize: ".875rem",
          color: primary.white,
          fontWeight: 600,
          border: `1px solid ${primary.border}`,
          cursor: "pointer",
          borderRadius: "3px",
          height: "auto",
          padding: ".25rem .5rem",
        },
        dashedOutline: {
          border: `1px dashed ${primary.border}`,
          borderRadius: "2px",
          lineHeight: "34px",
          fontSize: "14px",
          color: primary.blue,
        },
        themeLg: {
          minHeight: "48px",
          borderRadius: "3px",
          fontSize: "18px",
          fontWeight: "bold",
          color: primary.white,
          bgColor: primary.blue,
          boxShadow: `0 9px 18px 0 ${secondary.blueShadow}`,
        },
        addVideoModalFilter: {
          fontWeight: 500,
          lineHeight: "0.875rem",
          fontSize: "0.875rem",
          border: `1px solid ${primary.border}`,
          borderRadius: "20px",
          padding: "0.4375rem 1.6875rem",
          color: primary.black,
          height: "30px",
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          color: primary.white,
          bg: primary.blueContrast,
          fontSize: "14px",
          fontWeight: "semibold",
          mr: "10px",
          _selected: {
            color: primary.black,
            bg: primary.white,
            boxShadow: "none",
          },
        },
        tabpanel: {
          p: "30px",
          bg: primary.white,
          minH: "100vh",
        },
      },
    },
  },
});
