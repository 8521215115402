// authActions.js
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../../components/utils/api";
import { env } from "../../components/utils/env";
const API_URL = env("REACT_APP_API_URL");
interface AxiosError {
  response?: {
    data?: {
      message?: string;
    };
  };
  message?: string;
}

export const registerUser = createAsyncThunk(
  "auth/register",
  async (userInfo: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post(`${API_URL}login/registrarse`, { userInfo }, config);
    } catch (rawError) {
      // return custom error message from backend if present
      const error = rawError as AxiosError;
      if (error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error?.message || "Unknown error occurred");
      }
    }
  }
);
export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }: any, { rejectWithValue }) => {
    try {
      const data = await login(email, password);
      localStorage.setItem("token", data.token);
      return data;
    } catch (rawError) {
      // return custom error message from backend if present
      const error = rawError as AxiosError;
      // return custom error message from API if any
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue(error?.message);
      }
    }
  }
);
