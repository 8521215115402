import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  VStack,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { AddIdiomaEnsenhaProfesor } from "../../../../components/utils/api";
import ClasesPrecios from "./ClasesPrecios";
import { useIdiomas } from "../../../../components/utils/languageApi";
import SelectIdioma from "../../../../components/common/idiomasDisponibles";
import Niveles from "../../../../components/common/Niveles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useUserIdFromToken from "../../../../utils/useUserIdFromToken";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useAuthStore } from "../../../../app/authStore";

const buttonSize = {
  width: "132px",
  height: "40px",
};

// Radio de los bordes de los botones
const buttonBorderRadius = "6px";

type Clase = {
  cantidad_clase: number;
  precio: string;
  precio_final: any;
  tiempo: number;
};

type Clases = {
  [key: string]: {
    data: Clase[];
    set: React.Dispatch<React.SetStateAction<Clase[]>>;
  };
};

const AgregarEnsenha = ({ usuario, setUsuario }: any) => {
  const toast = useToast();
  const { user } = useAuthStore((state: any) => state);
  const [isModalOpen, setModalOpen] = useState(false);
  const { data: idiomasData } = useIdiomas();
  const [selectedIdioma, setSelectedIdioma] = useState<{
    value: string;
    id: string;
  }>({ value: "", id: "" });
  const [clases, setClases] = useState<Clases>({});

  //Modal dialog
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  /*clases*/
  const onChangeClase = (newClases: Clases) => {
    setClases(newClases); // Update the clases state with the latest changes
  };
  /* fin clases */

  const nivelesEnsenha = [
    { id: 3, nombre: "Avanzado", selected: 0 },
    { id: 4, nombre: "Nativo", selected: 0 },
  ];

  const handleGuardar = async (values: any) => {
    const nivel = nivelesEnsenha.find((nivel) => {
      return nivel.nombre === values.nivel;
    });

    let idioma;
    if (idiomasData) {
      const idiomasArray = Object.values(idiomasData);
      idioma = idiomasArray.find((idioma: any) => {
        return idioma.idioma === values.idioma;
      });
    }

    try {
      const newLanguage = {
        idioma: Number(idioma?.id),
        nivel: nivel?.id,
        pruebaClase: clases?.ClasedePrueba.data,
        unaClase: clases?.UnaClase.data,
        paqueteClases: clases?.PaquetedeClases.data,
      };
      const data = await AddIdiomaEnsenhaProfesor(newLanguage, user?.id);

      toast({
        title: "Éxito",
        description: "El idioma " + idioma.idioma + " ha sido agregado",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setUsuario(() => ({
        ...usuario,
        idiomas_ensenha: data?.idiomas_ensenha,
      }));
    } catch (error) {
      toast({
        title: "Error",
        description: "Hubo un error al agregar el idioma " + idioma.idioma,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(error);
    }
    handleCloseModal();
  };

  const validationSchema = Yup.object({
    idioma: Yup.string().required("Idioma is required"),
    nivel: Yup.string().required("Nivel is required"),
    clasesPrecios: Yup.array().of(
      Yup.object().shape({
        precio: Yup.number()
          .typeError("Precio debe ser un número")
          .required("Precio es requerido"),
        /*
          .test("is-pruebaClase", "precio pruebaClase is required", (value) => {
            // Add your custom validation logic here
            // You can check if the value is required based on the "clase_prueba" field
            //const clasePrueba = value?.clase_prueba;
            //return clasePrueba === "1" ? !!value?.precio : true;
          }),
          */
      })
    ),
  });

  return (
    <>
      <Button
        variant="solid"
        backgroundColor="#28a745"
        color="white"
        size="sm"
        w={buttonSize.width}
        h={buttonSize.height}
        borderRadius={buttonBorderRadius}
        borderColor="#28a745"
        onClick={handleOpenModal}
      >
        Agregar +
      </Button>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Agregar idiomas que enseña</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Box w="100%">
                <HStack spacing={4}>
                  <Formik
                    initialValues={{
                      idioma: "",
                      nivel: "",
                      clasesPrecios: "",
                    }}
                    onSubmit={handleGuardar}
                    validationSchema={validationSchema}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <SelectIdioma
                          id="idioma"
                          name="idioma"
                          placeholder={"Seleccione Idioma"}
                          idiomasData={idiomasData}
                          selectedIdioma={selectedIdioma}
                          usuario={usuario}
                          ensenha={1}
                          isEditing={false}
                        />
                        <Niveles
                          id="nivel"
                          name="nivel"
                          placeholder={"Seleccione Nivel"}
                          idiomaBanderasItem={[]}
                          ensenha={1}
                          selectedNivel={[]}
                        ></Niveles>
                        <ClasesPrecios
                          id="clasesPrecios"
                          name="clasesPrecios"
                          onChangeClase={onChangeClase}
                          idioma_ensenha={[]}
                        ></ClasesPrecios>
                        <Button type="submit" colorScheme="green">
                          Guardar
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </HStack>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCloseModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AgregarEnsenha;
