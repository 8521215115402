import React, { useEffect, useState, useMemo } from "react";
import { FormControl, FormLabel, Input, HStack, Text } from "@chakra-ui/react";
import { Field, ErrorMessage } from "formik";

type Clase = {
  cantidad_clase: number;
  precio: string;
  precio_final: any;
  tiempo: number;
};

type Clases = {
  [key: string]: {
    data: Clase[];
    set: React.Dispatch<React.SetStateAction<Clase[]>>;
  };
};

interface ClasesPreciosProps {
  id: any;
  name: any;
  onChangeClase: (newClases: Clases) => void;
  idioma_ensenha: any;
}
const ClasesPrecios = ({
  id,
  name,
  onChangeClase,
  idioma_ensenha,
}: ClasesPreciosProps) => {
  /* Clases */
  const pruebaClaseInit = [
    {
      id: "",
      id_idioma_profesor: "",
      cantidad_clase: "1",
      precio: "",
      precio_langport: "",
      precio_final: "",
      tiempo: "25",
      clase_prueba: "0",
      reg_estado: "1",
      fecha_creacion: "",
    },
    {
      id: "",
      id_idioma_profesor: "",
      cantidad_clase: "1",
      precio: "",
      precio_langport: "",
      precio_final: "",
      tiempo: "55",
      clase_prueba: "0",
      reg_estado: "1",
      fecha_creacion: "",
    },
  ];
  const [pruebaClase, setPruebaClase] = useState(
    idioma_ensenha.clase_prueba ?? pruebaClaseInit
  );
  const unaClaseInit = [
    {
      id: "",
      id_idioma_profesor: "",
      cantidad_clase: "1",
      precio: "",
      precio_langport: "",
      precio_final: "",
      tiempo: "25",
      clase_prueba: "0",
      reg_estado: "1",
      fecha_creacion: "",
    },
    {
      id: "",
      id_idioma_profesor: "",
      cantidad_clase: "1",
      precio: "",
      precio_langport: "",
      precio_final: "",
      tiempo: "55",
      clase_prueba: "0",
      reg_estado: "1",
      fecha_creacion: "",
    },
  ];
  const [unaClase, setUnaClase] = useState(
    idioma_ensenha.una_clase || unaClaseInit
  );
  const paqueteClaseInit = [
    {
      id: "",
      id_idioma_profesor: "",
      cantidad_clase: "8",
      precio: "",
      precio_langport: "",
      precio_final: "",
      tiempo: "25",
      clase_prueba: "0",
      reg_estado: "1",
      fecha_creacion: "",
    },
    {
      id: "",
      id_idioma_profesor: "",
      cantidad_clase: "8",
      precio: "",
      precio_langport: "",
      precio_final: "",
      tiempo: "55",
      clase_prueba: "0",
      reg_estado: "1",
      fecha_creacion: "",
    },
  ];
  const [paqueteClases, setPaqueteClases] = useState(
    idioma_ensenha.ocho_clases || paqueteClaseInit
  );

  const clases = useMemo(
    () => ({
      ClasedePrueba: { data: pruebaClase, set: setPruebaClase },
      UnaClase: { data: unaClase, set: setUnaClase },
      PaquetedeClases: { data: paqueteClases, set: setPaqueteClases },
    }),
    [pruebaClase, unaClase, paqueteClases]
  );

  const comision = 3;

  const onChangePrecio = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    label: string
  ) => {
    const precio = e.target.value;
    if (!isNaN(parseFloat(precio)) || !precio || precio.trim() === "") {
      let precio_final = "";
      if (precio === null || precio === "" || Number(precio) <= 0) {
        precio_final = "";
      } else {
        precio_final = ((parseFloat(precio) * (100 + comision)) / 100).toFixed(
          2
        );
      }
      if (label === "ClasedePrueba") {
        setPruebaClase((prevState: any) =>
          prevState?.map((item: any, idx: any) =>
            idx === index ? { ...item, precio: precio, precio_final } : item
          )
        );
      } else if (label === "UnaClase") {
        setUnaClase((prevState: any) =>
          prevState?.map((item: any, idx: any) =>
            idx === index ? { ...item, precio: precio, precio_final } : item
          )
        );
      } else if (label === "PaquetedeClases") {
        setPaqueteClases((prevState: any) =>
          prevState?.map((item: any, idx: any) =>
            idx === index ? { ...item, precio: precio, precio_final } : item
          )
        );
      }
    }
  };

  useEffect(() => {
    onChangeClase(clases);
  }, [pruebaClase, unaClase, paqueteClases, onChangeClase, clases]);

  return (
    <>
      {Object?.entries(clases)?.map(
        ([label, { data: claseArray, set: setState }]) => (
          <React.Fragment key={label}>
            {claseArray?.map((clase: Clase, index: number) => {
              // Obtener el precio inicial desde la estructura correcta

              return (
                <React.Fragment key={index}>
                  <Field
                    id={id}
                    name={`${label}[${index}].precio`}
                    validate={(value: string) => {
                      let error;
                      if (!value || value === "" || value === undefined) {
                        error = "Precio es requerido";
                      } else if (isNaN(parseFloat(value))) {
                        error = "Precio debe ser un número";
                      } else if (Number(value) <= 0) {
                        error = "Precio debe ser mayor que 0";
                      }
                      return error;
                    }}
                  >
                    {({ field, form }: any) => (
                      <HStack key={index} spacing={4}>
                        <FormControl>
                          <FormLabel>Clases</FormLabel>
                          <Input
                            type="text"
                            value={clase?.cantidad_clase}
                            isDisabled
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Precio (USD)</FormLabel>
                          <Input
                            type="text"
                            value={(clase?.precio || "") ?? field.value}
                            onChange={(e) => {
                              form.setFieldValue(field.name, e.target.value);
                              onChangePrecio(e, index, label);
                            }}
                            onBlur={(e) => {
                              form.setFieldValue(field.name, e.target.value);
                              onChangePrecio(e, index, label);
                            }}
                          />
                          <ErrorMessage
                            name={`${label}[${index}].precio`}
                            component="div"
                          >
                            {(errorMsg) => {
                              return errorMsg ? (
                                <Text color="red.500" mt={2}>
                                  {errorMsg}
                                </Text>
                              ) : null;
                            }}
                          </ErrorMessage>
                        </FormControl>
                        <FormControl>
                          <FormLabel>Precio final (USD)</FormLabel>
                          <Input
                            type="text"
                            value={clase?.precio_final}
                            isDisabled
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Tiempo</FormLabel>
                          <Input type="text" value={clase?.tiempo} isDisabled />
                        </FormControl>
                      </HStack>
                    )}
                  </Field>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )
      )}
    </>
  );
};

export default ClasesPrecios;
