import React, { useEffect, useState } from "react";
import { FormControl, Select, Text } from "@chakra-ui/react";
import { Idioma } from "../../interfaces/Usuario.interface";
import FormLabelInput from "../dashboard/FormLabelInput";
import { ErrorMessage, Field } from "formik";

interface Props {
  id: any;
  name: any;
  placeholder: string;
  idiomasData: any;
  selectedIdioma: any;
  usuario: any;
  ensenha: number;
  isEditing: boolean;
}

const SelectIdioma: React.FC<Props> = ({
  id,
  name,
  placeholder,
  idiomasData,
  selectedIdioma,
  usuario,
  ensenha,
  isEditing,
}: any) => {
  const [idiomasDisponibles, setIdiomasDisponibles] = useState<Idioma[]>([]);
  const [disabled, setdisabled] = useState("");
  useEffect(() => {
    const newIdioma: Idioma = {
      idioma: selectedIdioma.value,
      id: selectedIdioma.id,
    };
    if (isEditing === true) {
      setIdiomasDisponibles([newIdioma]);
      setdisabled("disabled");
    } else {
      let idiomasUsuarioIds: any = [];
      if (ensenha === 1) {
        idiomasUsuarioIds =
          usuario?.idiomas_ensenha?.map((idioma: any) => idioma.id_idioma) ||
          [];
      } else {
        idiomasUsuarioIds =
          usuario?.idiomas_habla?.map((idioma: any) => idioma.id_idioma) || [];
      }
      const difference = idiomasData?.filter(
        (idioma: any) => !idiomasUsuarioIds.includes(idioma.id)
      );
      setIdiomasDisponibles(difference);
    }
  }, [
    idiomasData,
    usuario,
    ensenha,
    isEditing,
    selectedIdioma.value,
    selectedIdioma.id,
  ]);

  return (
    <FormControl>
      <FormLabelInput required>Idioma </FormLabelInput>
      <Field
        id={id}
        as={Select}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
      >
        {idiomasDisponibles?.map((idioma: Idioma) => (
          <option key={idioma.id} value={idioma.idioma} data-id={idioma.id}>
            {idioma.idioma}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name}>
        {(errorMsg) => (
          <Text color="red.500" mt={2}>
            {errorMsg}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  );
};

export default SelectIdioma;
