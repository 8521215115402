import React, { useState } from "react";
import {
  Table,
  Thead,
  Th,
  Button,
  Tbody,
  Tr,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import DescargarPDF from "./DescargarPDF";
import { aprobarCurso, rechazarCurso } from "../utils/api";

const TablaCursos = ({ dataTabla }: any) => {
  const toast = useToast();
  const [cursoToHandle, setCursoToHandle] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleValidarCurso = (curso: any) => {
    setCursoToHandle(curso);
    onOpen();
  };

  const handleCourseAction = async (action: "aprobar" | "rechazar") => {
    onClose();
    if (cursoToHandle) {
      if (action === "aprobar") {
        try {
          const data = await aprobarCurso(cursoToHandle);
          dataTabla.setCursos(data.cursos);
          toast({
            title: "Éxito",
            description:
              "El curso " + cursoToHandle?.nombre_curso + " ha sido aprobado",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Éxito",
            description:
              "Hubo un error al aprobar el curso " +
              cursoToHandle?.nombre_curso,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          console.error(error);
        }
      } else if (action === "rechazar") {
        try {
          const data = await rechazarCurso(cursoToHandle);
          dataTabla.setCursos(data.cursos);
          toast({
            title: "Éxito",
            description:
              "El curso " + cursoToHandle?.nombre_curso + " ha sido rechazado",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Éxito",
            description:
              "Hubo un error al rechazar el curso " +
              cursoToHandle?.nombre_curso,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          console.error(error);
        }
      }
    }
    setCursoToHandle(null);
  };

  return (
    <>
      <Table variant="striped">
        <Thead>
          <Tr>
            {dataTabla?.tableHeaders?.map(({ label, key }: any) => (
              <Th key={key}>{label}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {dataTabla?.cursos?.map((curso: any, index: number) => (
            <Tr key={index}>
              <Td>{curso.nombre_escuela}</Td>
              <Td>{curso.anho}</Td>
              <Td>{curso.nombre_curso}</Td>
              <Td>
                <DescargarPDF curso={curso}></DescargarPDF>
              </Td>
              <Td>{curso.nombre_estado}</Td>
              <Td>
                <Button
                  colorScheme="teal"
                  size="sm"
                  onClick={() => handleValidarCurso(curso)}
                >
                  Validar
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {`Haga click en el boton para validar o rechazar el curso "${cursoToHandle?.nombre_curso}"`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => handleCourseAction("aprobar")}
            >
              Aprobar
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleCourseAction("rechazar")}
            >
              Rechazar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TablaCursos;
