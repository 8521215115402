import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import Language from "../language/Language";
import { useParams } from "react-router-dom";
import ProfesoresList from "./ProfesoresList";
import { pageViewed } from "../../mixpanel/mixpanel";
import { fetchProfesoresIdIdioma } from "../utils/teacherApi";

const Idioma = () => {
  const { id } = useParams(); // Obtiene el valor del parámetro id
  const numericId = parseInt(id || "0", 10);
  const isFirstRender = useRef(true);
  const [profesores, setprofesores] = useState([]);
  const qtyProfesores = profesores.length;
  const obtenerProfesoresIdIdioma = useCallback(async () => {
    try {
      const data = await fetchProfesoresIdIdioma(numericId);
      setprofesores(data?.slice(0, 10));
    } catch (error) {
      console.error(error);
    }
  }, [numericId]);
  useEffect(() => {
    if (isFirstRender.current) {
      obtenerProfesoresIdIdioma();
      pageViewed("");
      isFirstRender.current = false;
    }
  }, [obtenerProfesoresIdIdioma]);
  return (
    <>
      <section className="section section-teachers section-teachers-slider">
        <div className="display-spacing">
          <div className="container">
            <Box marginLeft="5%" marginRight="5%">
              <Language id={numericId} qtyProfesores={qtyProfesores}></Language>
            </Box>
            <Box marginLeft="5%" marginRight="5%">
              <ProfesoresList profesores={profesores || []} />
            </Box>
          </div>
        </div>
      </section>
    </>
  );
};

export default Idioma;
