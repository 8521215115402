import { Text, Box, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { addDays, isSameDay } from "date-fns";
import { fetchProfesor } from "../../utils/teacherApi";

const TableHeader = ({ profesor, updateProfesorHorario }: any) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const handlePrevClick = () => {
    // Restar 7 días a la fecha actual para obtener los días anteriores
    const newDate = addDays(currentDate, -7);
    setCurrentDate(newDate);
  };

  const handleNextClick = () => {
    // Sumar 7 días a la fecha actual para obtener los días siguientes
    const newDate = addDays(currentDate, +7);
    setCurrentDate(newDate);
  };

  const handleTodayClick = () => {
    // Establecer la fecha actual del sistema
    const newDate = new Date();
    setCurrentDate(newDate);
  };

  const fetchProfesorApi = useCallback(async () => {
    try {
      const data = await fetchProfesor(
        profesor?.profesor?.id,
        currentDate.toISOString().split("T")[0]
      );
      updateProfesorHorario(data);
    } catch (error) {
      console.error(error);
    }
  }, [profesor, currentDate, updateProfesorHorario]);

  const getDaysOfWeek = () => {
    const daysOfWeek = [];
    const currentDayOfWeek = currentDate.getDay(); // 0 (Domingo) a 6 (Sábado)

    // Obtener la fecha del último lunes restando los días correspondientes
    const lastMonday = new Date(currentDate);
    lastMonday.setDate(currentDate.getDate() - currentDayOfWeek + 1);

    // Agregar los días del lunes al domingo al array
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(lastMonday);
      newDate.setDate(lastMonday.getDate() + i);
      const dayNumber = newDate.getDate().toString().padStart(2, "0"); // Agregar el cero si es necesario

      daysOfWeek.push({
        dayName: getDayName(newDate.getDay()),
        dayNumber,
        month: getMonthName(newDate.getMonth()),
        year: newDate.getFullYear(), // Agregar el año correspondiente a cada día
        isToday: isSameDay(newDate, new Date()), // Verificar si es la fecha actual
      });
    }

    return daysOfWeek;
  };

  const getMonthName = (month: any) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return months[month];
  };

  const getDayName = (dayOfWeek: any) => {
    const days = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    return days[dayOfWeek];
  };

  const daysOfWeek = getDaysOfWeek();
  useEffect(() => {
    fetchProfesorApi();
  }, [currentDate]);
  return (
    <Thead>
      <Tr>
        <Td colSpan={7}>
          <Box display="flex" justifyContent="space-between">
            <Box textAlign="left">
              <button onClick={handlePrevClick}>{"< Anterior"}</button>{" "}
            </Box>
            <Box textAlign="center">
              <button onClick={handleTodayClick}>{"Hoy"}</button>{" "}
            </Box>
            <Box textAlign="right">
              <button onClick={handleNextClick}>{"Siguiente >"}</button>
            </Box>
          </Box>
        </Td>
      </Tr>
      <Tr>
        {daysOfWeek?.map((day) => (
          <Th
            key={day.dayName}
            bgColor={day.isToday ? "green.300" : "transparent"}
            color={day.isToday ? "white" : ""}
          >
            <Box textAlign="center">
              <Text>
                {day.dayName} {day.dayNumber}
              </Text>
              <Text>{day.month}</Text>
              <Text>{day.year}</Text>
            </Box>
          </Th>
        ))}
      </Tr>
    </Thead>
  );
};

export default TableHeader;
