import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { updateIdiomaProfesor } from "../../../../components/utils/api";

import { useIdiomas } from "../../../../components/utils/languageApi";
import Niveles from "../../../../components/common/Niveles";
import SelectIdioma from "../../../../components/common/idiomasDisponibles";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const EditarHabla = ({ idiomaBanderasItem, setUsuario, usuario }: any) => {
  const toast = useToast();
  const nivelesEnsenha = [
    { id: 3, nombre: "Avanzado", selected: 0 },
    { id: 4, nombre: "Nativo", selected: 0 },
  ];
  const nivelesHabla = [
    { id: 1, nombre: "Basico", selected: 0 },
    { id: 2, nombre: "Intermedio", selected: 0 },
    ...nivelesEnsenha,
  ];
  const { data: idiomasData } = useIdiomas();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIdioma, setSelectedIdioma] = useState<{
    value: string;
    id: string;
  }>({ value: "", id: "" });
  const [selectedNivel, setSelectedNivel] = useState<{
    value: string;
    id: string;
  }>({ value: "", id: "" });

  const handleClose = () => {
    setIsOpen(false);
    setSelectedNivel({ value: "", id: "" });
    setSelectedIdioma({ value: "", id: "" });
  };

  const handleOpen = async (idiomaBanderasItem: any) => {
    setIsOpen(true);
    const selectedIdioma = {
      value: idiomaBanderasItem.idioma,
      id: idiomaBanderasItem.id_idioma,
    };
    setSelectedIdioma(selectedIdioma);
    const selectedNivel = {
      value: idiomaBanderasItem.idioma_nivel,
      id: idiomaBanderasItem.nivel,
    };
    setSelectedNivel(selectedNivel);
  };

  const handleGuardar = async (values: any) => {
    const nivel = nivelesHabla.find((nivel) => {
      return nivel.nombre === values.nivel;
    });
    try {
      const data = await updateIdiomaProfesor(
        idiomaBanderasItem,
        nivel?.id,
        idiomaBanderasItem.id_usuario
      );
      setUsuario(() => ({
        idiomas_habla: data?.idiomas_habla,
      }));
      toast({
        title: "Éxito",
        description:
          "Se ha actualizado el nivel, del idioma " + selectedIdioma.value,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          "Hubo un error al actualizar el nivel, del idioma " +
          selectedIdioma.value,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(error);
    }
    handleClose();
  };

  const validationSchema = Yup.object({
    //nivel: Yup.string().required("Nivel is required"),
  });

  return (
    <>
      <Button
        colorScheme="green"
        onClick={() => handleOpen(idiomaBanderasItem)}
      >
        Editar
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Idioma</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                nivel: selectedNivel.value,
                idioma: selectedIdioma.value,
              }}
              onSubmit={handleGuardar}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <SelectIdioma
                    id="idioma"
                    name="idioma"
                    placeholder={""}
                    idiomasData={idiomasData}
                    selectedIdioma={selectedIdioma}
                    usuario={usuario}
                    ensenha={0}
                    isEditing={true}
                  />
                  <Niveles
                    id="nivel"
                    name="nivel"
                    idiomaBanderasItem={idiomaBanderasItem}
                    placeholder={""}
                    ensenha={0}
                    selectedNivel={selectedNivel}
                  ></Niveles>
                  <Button type="submit" colorScheme="green">
                    Guardar
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" ml={2} onClick={handleClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarHabla;
