import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import ProximasClases from "./ProximasClases";
import ProtectedRoute from "../ProtectedRoute";
import { perfilUsuario } from "../../interfaces/TipoUsuario.interface";
import { obtenerHorariosAlumno } from "../utils/userApi";
interface HorarioAlumnoItem {
  id: string;
  id_horario_profesor: string;
  id_alumno: string;
  id_precio_clase_profesor_alumno: string;
  fecha: string;
  hora_inicio: string;
  hora_fin: string;
  tiempo: string;
  motivo_cancelacion: string;
  tipo_clase: string;
  id_idioma_profe: string;
  reg_estado: string;
  fecha_creacion: string;
  nombre_profesor: string;
  pais: string;
  bandera: string;
  idioma: string;
  english: string;
  id_idioma_profesor: string;
  cantidad_clase: string;
  precio: string;
  precio_langport: string;
  precio_final: string;
  clase_prueba: string;
  tipo: "Historial" | "ProximasClases";
}

export const ProximasClasesView = () => {
  const allowedTypes = [perfilUsuario.profesor, perfilUsuario.alumno];

  const [horarioAlumno, sethorarioAlumno] = useState<HorarioAlumnoItem[]>([]);

  const getHorarioAlumno = useCallback(async () => {
    try {
      // Guardar los horarios seleccionados
      const horarioAlumno = await obtenerHorariosAlumno(2);
      sethorarioAlumno(horarioAlumno);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const historialClases = horarioAlumno.filter(
    (item) => item.tipo === "Historial"
  );

  const proximasClases = horarioAlumno.filter(
    (item) => item.tipo === "ProximasClases"
  );

  useEffect(() => {
    getHorarioAlumno();
  }, [getHorarioAlumno]);
  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <ProximasClases
        horarioAlumno={proximasClases}
        title="Próximas Clases"
      ></ProximasClases>
      <ProximasClases
        horarioAlumno={historialClases}
        title="Historial de Clases"
      ></ProximasClases>
    </ProtectedRoute>
  );
};
export default ProximasClasesView;
