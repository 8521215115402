import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Button,
  Text,
  FormControl,
  useToast,
} from "@chakra-ui/react";
import { updateComision, getComision } from "../../utils/api";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import FormLabelInput from "../FormLabelInput";

const buttonSize = {
  width: "160px",
  height: "40px",
};

// Radio de los bordes de los botones
const buttonBorderRadius = "6px";

const Comision = ({ comision }: any) => {
  const toast = useToast();
  const handleSubmit = async (values: any) => {
    try {
      await updateComision(values.comision, values.tiempocancelacion);
      toast({
        title: "Éxito",
        description: "La comisión ha sido actualizada",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Hubo un error al actualizar la comisión",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const validationSchema = Yup.object({
    comision: Yup.number().required("Porcentaje is required"),
    tiempocancelacion: Yup.string().required("Porcentaje is required"),
  });

  return (
    <Box
      p={6}
      borderWidth={1}
      borderStyle="solid"
      borderRadius="10px"
      borderColor="#e2e8f0"
      fontFamily='"Poppins", sans-serif'
      mb={3}
    >
      <Text fontSize="2xl" color="gray.400">
        Configuraciones
      </Text>
      <Formik
        initialValues={{
          comision: comision.comision,
          tiempocancelacion: comision.tiempocancelacion,
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box>
              <FormControl mb="5">
                <FormLabelInput required>Porcentaje Comisión</FormLabelInput>
                <Field>
                  {({ field }: any) => (
                    <Input
                      {...field}
                      type="text"
                      value={values.comision}
                      name="comision"
                    />
                  )}
                </Field>

                <ErrorMessage name="{comision}">
                  {(errorMsg) => {
                    return (
                      <Text color="red.500" mt={2}>
                        {errorMsg}
                      </Text>
                    );
                  }}
                </ErrorMessage>
              </FormControl>
              <FormControl mb="5">
                <FormLabelInput required>
                  Tiempo minimo cancelacion
                </FormLabelInput>
                <Field>
                  {({ field }: any) => (
                    <Input
                      {...field}
                      type="text"
                      value={values.tiempocancelacion}
                      name="tiempocancelacion"
                    />
                  )}
                </Field>

                <ErrorMessage name="{tiempocancelacion}">
                  {(errorMsg) => {
                    return (
                      <Text color="red.500" mt={2}>
                        {errorMsg}
                      </Text>
                    );
                  }}
                </ErrorMessage>
              </FormControl>
            </Box>
            <Box mt="3%">
              <Button
                variant="solid"
                backgroundColor="#28a745"
                color="white"
                size="sm"
                w={buttonSize.width}
                h={buttonSize.height}
                borderRadius={buttonBorderRadius}
                borderColor="#28a745"
                type="submit"
              >
                Actualizar Comisión
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Comision;
