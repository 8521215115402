import React from "react";
import { Box, Select, Text } from "@chakra-ui/react";
import { Field, ErrorMessage } from "formik"; //
import FormLabelInput from "../dashboard/FormLabelInput";

interface ContactApplicationSelectProps {
  name: string;
}

const ContactApplicationSelect: React.FC<ContactApplicationSelectProps> = ({
  name,
}) => {
  return (
    <Box flex={{ md: 1 }}>
      <FormLabelInput required>Aplicación de contacto</FormLabelInput>
      <Field
        name={name}
        id="select-tipo_contacto"
        as={Select}
        placeholder="Seleccione"
      >
        <option value="WhatsApp">WhatsApp</option>
        <option value="Viber">Viber</option>
        <option value="Telegram">Telegram</option>
      </Field>
      <ErrorMessage name={name}>
        {(errorMsg) => (
          <Text color="red.500" mt={2}>
            {errorMsg}
          </Text>
        )}
      </ErrorMessage>
    </Box>
  );
};

export default ContactApplicationSelect;
