import { Box } from "@chakra-ui/react";
import { pageViewed } from "../../mixpanel/mixpanel";
import { useEffect, useRef } from "react";
import ProximasClases from "./ProximasClases";
import Calendario from "./calendario/Calendario";
import ProtectedRoute from "../ProtectedRoute";
import { perfilUsuario } from "../../interfaces/TipoUsuario.interface";

function Dashboard() {
  const allowedTypes = [
    perfilUsuario.superAdministrador,
    perfilUsuario.administrador,
    perfilUsuario.profesor,
    perfilUsuario.alumno,
  ];

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      pageViewed("");
      isFirstRender.current = false;
    }
  }, []);
  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box marginLeft="5%" marginTop="5%" marginRight="5%">
        <Box className="container">
          <Calendario profesor="" alumno=""></Calendario>
          <ProximasClases></ProximasClases>
        </Box>
      </Box>
    </ProtectedRoute>
  );
}

export default Dashboard;
