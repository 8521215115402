import { Box, Text } from "@chakra-ui/react";
import React from "react";

const TituloSubtitulo = ({ titulo, subtitulo }: any) => {
  return (
    <Box textAlign="center">
      <Text
        fontSize="30px"
        letterSpacing="-1.2px"
        marginBottom="12px"
        fontWeight="600"
        fontFamily="Poppins, sans-serif"
        mt="5%"
      >
        {titulo}
      </Text>
      <Text fontSize="18px" fontWeight="300" color="gray.500">
        {subtitulo}
      </Text>
      <Box
        width="63px"
        height="4px"
        display="inline-block"
        position="relative"
        marginRight="9px"
        marginLeft="9px"
        backgroundColor="#5b70f9"
        mb="5%"
        mt="2%"
      ></Box>
    </Box>
  );
};

export default TituloSubtitulo;
