import React from "react";
import { Box } from "@chakra-ui/react";

export const VideoProfesor = ({ profesor }: any) => {
  const url = profesor?.profesor?.video;

  if (!url || (!url.includes("youtube") && !url.includes("vimeo"))) {
    console.log("video no es correcto");
    return null;
  }

  return (
    <Box my={5} borderRadius="md" overflow="hidden">
      <iframe
        width="100%"
        height="500"
        src={url}
        frameBorder="0"
        allowFullScreen
        title="Video del Profesor"
      ></iframe>
    </Box>
  );
};
