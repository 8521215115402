import {
  Box,
  Table,
  Thead,
  Th,
  Textarea,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tbody,
  Tr,
  Td,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { obtenerHorariosAlumno } from "../utils/userApi";

function ProximasClases({ horarioAlumno, title }: any) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => setModalOpen(false);
  return (
    <Box borderWidth={1} borderRadius="lg" overflow="hidden" mb="5">
      <Box p="6">
        <Box mb={3}>
          <Text fontSize="2xl">{title}</Text>
        </Box>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Profesor</Th>
              <Th>Fecha</Th>
              <Th>Hora Inicio</Th>
              <Th>Hora Fin</Th>
              <Th>Precio</Th>
              <Th>Tiempo</Th>
              <Th>Idioma</Th>
              <Th>Acción</Th>
            </Tr>
          </Thead>
          <Tbody>
            {horarioAlumno?.map((hora: any, index: any) => (
              <Tr key={index}>
                <Td>{hora?.nombre_profesor}</Td>
                <Td>{hora?.fecha}</Td>
                <Td>{hora?.hora_inicio}</Td>
                <Td>{hora?.hora_fin}</Td>
                <Td>{hora?.precio_final}</Td>
                <Td>{hora?.tiempo}</Td>
                <Td>{hora?.idioma}</Td>
                <Td>
                  <Button colorScheme="teal" size="sm">
                    Action
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancelación clase</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box>
                <Textarea placeholder="Motivo" />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleModalClose}>
              Cerrar
            </Button>
            <Button colorScheme="red">Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reagendar clase</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box></Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleModalClose}>
              Cerrar
            </Button>
            <Button colorScheme="yellow">Reagendar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ProximasClases;
