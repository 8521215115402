import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "../features/language/languageSlice";
import authReducer from "../features/auth/authSlice";
import { authApi } from "./services/auth/authService";

export const store = configureStore({
  reducer: {
    language: languageReducer,
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    // Aquí se pueden agregar otros reducers de la aplicación
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
