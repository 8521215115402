import React, { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@chakra-ui/react";
import { pageViewed } from "../../mixpanel/mixpanel";
import Teachers from "../../components/teacher/Teachers";
import { Usuario } from "../../interfaces/Usuario.interface";
import { useProfesores } from "../../components/utils/teacherApi";

const Profesores = () => {
  const { data: profesoresQuery } = useProfesores();
  const isFirstRender = useRef(true);
  const [profesores, setprofesores] = useState<Usuario>("");

  useEffect(() => {
    if (Array.isArray(profesoresQuery)) {
      setprofesores(profesoresQuery.slice(0, 10));
    }
  }, [profesoresQuery]);

  useEffect(() => {
    if (isFirstRender.current) {
      pageViewed("");
      isFirstRender.current = false;
    }
  }, []);

  return (
    <Grid bg="blackAlpha.50" pb="2%" mb="2%">
      <Box marginLeft="5%" marginRight="5%">
        <Teachers profesores={profesores}></Teachers>
      </Box>
    </Grid>
  );
};

export default Profesores;
