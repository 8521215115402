import React, { useEffect, useState } from "react";
import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import EliminarCurso from "./EliminarCurso";
import DescargarPDF from "../../common/DescargarPDF";

const TablaCursos = ({ cursos, setCursos }: any) => {
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const fetchPdf = async (pdfUrl: string) => {
    try {
      const response = await fetch(pdfUrl);
      const data = await response.json();
      setSelectedPdf(data.pdf_base64);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedPdf) {
      fetchPdf(selectedPdf);
    }
  }, [selectedPdf]);

  return (
    <>
      <Table>
        <Tbody>
          <Tr>
            <Td>Nombre escuela</Td>
            <Td>Año</Td>
            <Td>Nombre del curso</Td>
            <Td>PDF</Td>
            <Td>Estado</Td>
            <Td>Acción</Td>
          </Tr>
          {cursos?.map((curso: any) => (
            <Tr key={curso.id}>
              <Td>{curso.nombre_escuela}</Td>
              <Td>{curso.anho}</Td>
              <Td>{curso.nombre_curso}</Td>
              <Td>
                <DescargarPDF curso={curso}></DescargarPDF>
              </Td>
              <Td>{curso.nombre_estado}</Td>
              <Td>
                {curso.reg_estado > 0 && (
                  <EliminarCurso
                    curso={curso}
                    setCursos={setCursos}
                  ></EliminarCurso>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default TablaCursos;
