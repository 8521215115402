import React, { useCallback, useEffect, useState } from "react";
import { Textarea, Box, Text } from "@chakra-ui/react";
import { Field } from "formik";
import FormLabelInput from "../dashboard/FormLabelInput";

const TextAreaWithCharacterLimit = ({
  label,
  id,
  name,
  h,
  maxCharacters,
  setFieldValue,
  texto,
}: any) => {
  const [remainingChars, setRemainingChars] = useState(maxCharacters);

  const calculateRemaining = useCallback(
    (value: string) => {
      const remaining = maxCharacters - value.length;
      setRemainingChars(remaining);
    },
    [maxCharacters]
  );

  useEffect(() => {
    calculateRemaining(texto);
  }, [calculateRemaining, maxCharacters, texto]);

  const handleChange = (event: any) => {
    const { value } = event.target;
    const remaining = maxCharacters - value.length;
    setRemainingChars(remaining);
    setFieldValue(name, value);
  };

  return (
    <Box>
      <FormLabelInput required>{label}</FormLabelInput>
      <Field id={id} as={Textarea} name={name} h={h} onChange={handleChange} />
      <Text color={remainingChars >= 0 ? "green.500" : "red.500"} mt={2}>
        {remainingChars >= 0
          ? `Caracteres disponibles: ${remainingChars}`
          : `Has excedido el límite de caracteres por ${Math.abs(
              remainingChars
            )}`}
      </Text>
    </Box>
  );
};

export default TextAreaWithCharacterLimit;
