import React from "react";
import { Box, Select, Text, Input, Flex } from "@chakra-ui/react";
import { Field, ErrorMessage } from "formik"; // Assuming you're using Formik for form management
import FormLabelInput from "../dashboard/FormLabelInput";

interface VideoPlatformSelectProps {
  name: string;
}

const VideoPlatformSelect: React.FC<VideoPlatformSelectProps> = ({ name }) => {
  return (
    <>
      <Box flex={{ base: 1, md: "0.25" }}>
        <FormLabelInput required>Plataforma de Video</FormLabelInput>
        <Field
          id="select-video-platform"
          as={Select}
          placeholder="Seleccione"
          name={name}
        >
          <option value="youtube">Youtube</option>
          <option value="vimeo">Vimeo</option>
        </Field>
        <ErrorMessage name={name}>
          {(errorMsg) => (
            <Text color="red.500" mt={2}>
              {errorMsg}
            </Text>
          )}
        </ErrorMessage>
      </Box>
      <Box flex={{ base: 1, md: "0.75" }}>
        <FormLabelInput required>Link del vídeo</FormLabelInput>
        <Field id="input-video-link" as={Input} name="video" />
        <ErrorMessage name="video">
          {(errorMsg) => (
            <Text color="red.500" mt={2}>
              {errorMsg}
            </Text>
          )}
        </ErrorMessage>
      </Box>
    </>
  );
};

export default VideoPlatformSelect;
