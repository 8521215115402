import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Image } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { pageViewed } from "../../mixpanel/mixpanel";

function Nosotros() {
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      pageViewed("");
      isFirstRender.current = false;
    }
  }, []);
  const { languageData } = useSelector((state: RootState) => state.language);

  return (
    <div className="page page-inferior page-faq">
      <section className="section section-faq section-faq-1">
        <div className="display-spacing">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="el-panel">
                  <div className="el-panel-body">
                    <div className="el-panel-title">
                      <h3>{languageData.acerca_de_nosotros}</h3>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: languageData.acerca_de_nosotros_texto,
                      }}
                    ></p>
                    <div
                      className="contact-info"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src="/images/facebook.png"
                        style={{ marginRight: "10px" }}
                        alt="Facebook"
                      />
                      <Image
                        src="/images/whatsapp.png"
                        style={{ marginRight: "10px" }}
                        alt="WhatsApp"
                      />
                      <Image
                        src="/images/instagram.png"
                        style={{ marginRight: "10px" }}
                        alt="Instagram"
                      />
                      <Image
                        src="/images/telegram.png"
                        style={{ marginRight: "10px" }}
                        alt="Telegram"
                      />
                      <Image
                        src="/images/email.png"
                        style={{ marginRight: "10px" }}
                        alt="Email"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Nosotros;
