import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { VideoProfesor } from "../common/VideoProfesor";

export const AcercaDelProfesor = ({ profesor }: any) => {
  return (
    <Box>
      <VideoProfesor profesor={profesor} />
      <Text fontSize="2xl" color="gray.400">
        Acerca de mi
      </Text>
      <Text color="#717f94" fontSize="12">
        {profesor?.profesor?.descripcion}
      </Text>
    </Box>
  );
};
