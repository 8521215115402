import React from "react";
import { Box, Alert, Text } from "@chakra-ui/react";
import HeaderUsuario from "./HeaderUsuario";
//import FooterUsuario from "./Footer";
import { useUser } from "./utils/userApi";
import { RootState } from "../app/store";
import { useSelector } from "react-redux";
import { useAuthStore } from "../app/authStore";

const LayoutUsuario: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuthStore((state: any) => state);
  const { data: usuario } = useUser(user?.id);

  const validaciones = [
    {
      name: "msg_estudios",
      msg_validacion: usuario?.validaciones?.msg_estudios,
    },
    {
      name: "msg_horarios",
      msg_validacion: usuario?.validaciones?.msg_horarios,
    },
    {
      name: "msg_idiomas_ensenha",
      msg_validacion: usuario?.validaciones?.msg_idiomas_ensenha,
    },
    {
      name: "msg_idiomas_habla",
      msg_validacion: usuario?.validaciones?.msg_idiomas_habla,
    },
    { name: "msg_perfil", msg_validacion: usuario?.validaciones?.msg_perfil },
  ];
  const cantidadMensajes = validaciones.filter(
    (validacion) => validacion.msg_validacion
  ).length;

  const isTipoUsuario2 = Number(usuario?.tipo_usuario) === 2;

  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      <HeaderUsuario />
      <Box>
        <>
          {isTipoUsuario2 && cantidadMensajes > 0 && (
            <Alert status="warning" flexDirection="column">
              <Text textAlign="center" mb={7}>
                Tienes pendiente completar la siguiente información:
              </Text>
              {validaciones?.map(
                (item: any) =>
                  item.msg_validacion && (
                    <Box key={item.name} mb={2}>
                      <Text>{item.msg_validacion}</Text>
                    </Box>
                  )
              )}
            </Alert>
          )}
        </>
      </Box>
      <Box>{children}</Box>
      {/*<FooterUsuario />*/}
    </Box>
  );
};

export default LayoutUsuario;
