import React, { useState } from "react";
import { Select, FormControl, FormLabel } from "@chakra-ui/react";
import { ErrorMessage, Field } from "formik";
import FormLabelInput from "../dashboard/FormLabelInput";

interface LastXYearsSelectProps {
  year: number;
}

const LastXYearsSelect: React.FC<LastXYearsSelectProps> = ({ year }) => {
  const years = Array.from({ length: 20 }, (_, index) => year - index);
  return (
    <FormControl mb="5">
      <FormLabelInput required>Año</FormLabelInput>
      <Field id="anho" as={Select} name="anho" placeholder="Seleccione año">
        {years?.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Field>
      <ErrorMessage name="anho">
        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
      </ErrorMessage>
    </FormControl>
  );
};

export default LastXYearsSelect;
