import { Button } from "@chakra-ui/react";
//src\utils\theme.ts
import { langportColor } from "./theme";
// Tamaño de los botones (ancho y alto)
const buttonSize = {
  width: "170px",
  height: "45px",
};

// Radio de los bordes de los botones
const buttonBorderRadius = "6px";
export const Info = ({ message }: { message: string }) => (
  <Button
    variant="solid"
    backgroundColor={langportColor.blue}
    color="white"
    size="sm"
    w={buttonSize.width}
    h={buttonSize.height}
    borderRadius={buttonBorderRadius}
    borderColor={langportColor.blue}
    _hover={{ filter: "brightness(0.8)" }}
    mr="2%"
  >
    {message}
  </Button>
);

export const Warning = ({ message }: { message: string }) => (
  <Button
    variant="outline"
    backgroundColor={langportColor.yellow}
    size="sm"
    w={buttonSize.width}
    h={buttonSize.height}
    borderRadius={buttonBorderRadius}
    borderColor={langportColor.yellow}
    fontWeight="bold"
    _hover={{ filter: "brightness(0.8)" }}
    mr="2%"
  >
    {message}
  </Button>
);

export const Success = ({ message }: { message: string }) => (
  <Button
    variant="solid"
    backgroundColor={langportColor.green}
    color="white"
    size="sm"
    w={buttonSize.width}
    h={buttonSize.height}
    borderRadius={buttonBorderRadius}
    borderColor={langportColor.green}
    _hover={{ filter: "brightness(0.8)" }}
    mr="2%"
  >
    {message}
  </Button>
);

export const Danger = ({ message }: { message: string }) => (
  <Button
    variant="outline"
    backgroundColor={langportColor.red}
    color="white"
    size="sm"
    w={buttonSize.width}
    h={buttonSize.height}
    borderRadius={buttonBorderRadius}
    borderColor={langportColor.red}
    _hover={{ filter: "brightness(0.8)" }}
    mr="2%"
  >
    {message}
  </Button>
);
