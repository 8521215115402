import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { pageViewed } from "../../mixpanel/mixpanel";
import Teachers from "../teacher/Teachers";
import { Usuario } from "../../interfaces/Usuario.interface";
import { useProfesores } from "../utils/teacherApi";
import { perfilUsuario } from "../../interfaces/TipoUsuario.interface";
import ProtectedRoute from "../ProtectedRoute";

const ProfesoresView = () => {
  const isFirstRender = useRef(true);
  const { data: profesoresQuery } = useProfesores();
  const [profesores, setprofesores] = useState<Usuario>([]);

  const allowedTypes = [
    perfilUsuario.superAdministrador,
    perfilUsuario.administrador,
    perfilUsuario.profesor,
    perfilUsuario.alumno,
  ];

  useEffect(() => {
    if (profesoresQuery) {
      setprofesores(profesoresQuery);
    }
  }, [profesoresQuery]);

  useEffect(() => {
    if (isFirstRender.current) {
      pageViewed("");
      isFirstRender.current = false;
    }
  }, [profesores]);

  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box marginLeft="5%" marginTop="5%" marginRight="5%">
        <Box borderWidth={1} borderRadius="lg" overflow="hidden">
          <Box p="6">
            <Teachers profesores={profesores}></Teachers>
          </Box>
        </Box>
      </Box>
    </ProtectedRoute>
  );
};

export default ProfesoresView;
