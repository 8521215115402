import { Box, Text, Flex, Spacer, Badge, Grid } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";

import React from "react";
import RatingStars from "../common/RatingStars";

export const ComentariosProfesor = ({ profesor }: any) => {
  const tieneComentarios =
    profesor?.comentarios && profesor?.comentarios?.length > 0;

  return (
    <Box>
      <Text fontSize="2xl" color="gray.400">
        Comentarios
      </Text>

      {tieneComentarios ? (
        <Grid
          templateColumns={{
            base: "1fr", // 1 comentario por fila en móvil
            md: "1fr 1fr", // 2 comentarios por fila en tablet y desktop
          }}
          gap={6} // Espaciado entre los comentarios
        >
          {profesor?.comentarios?.map((comentario: any) => (
            <Box
              key={comentario.id}
              ml="5%"
              mt="5%"
              mr="5%"
              p={6}
              borderWidth={1}
              borderRadius="10px"
              borderColor="#e2e8f0"
              fontFamily='"Poppins", sans-serif'
            >
              <Flex direction="column" mb={4}>
                <Text fontSize="xs" fontWeight="bold" mb={10}>
                  {comentario.comentario}
                </Text>
                <Flex align="center">
                  <Text fontSize="md" mr={2}>
                    {comentario.nombre} {comentario.apellido}
                  </Text>
                  <Spacer />
                  <Text fontSize="sm" color="gray.500">
                    {comentario.fecha_creacionDDMMAAAA}
                  </Text>
                </Flex>
                <Flex>
                  <RatingStars rating={comentario.rating} />
                </Flex>
              </Flex>
            </Box>
          ))}
        </Grid>
      ) : (
        <Text color="red.500">No posee comentarios ingresados.</Text>
      )}
    </Box>
  );
};
