import { MouseEventHandler, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { env } from "./utils/env";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { setSelectedLanguage } from "../features/language/languageSlice";
import { useLocation, Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
import useUserIdFromToken from "../utils/useUserIdFromToken";
import { useAuthStore } from "../app/authStore";

const Links = ["Home", "Profesores"];

const NavLink = ({ children }: { children: string }) => {
  const { pathname } = useLocation();
  const linkFinal: any =
    children === "Home" ? "/" : `/${children.toLowerCase()}`;

  const isActive = (linkFinal: string): boolean => {
    return pathname === linkFinal;
  };
  return (
    <Link
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      href={linkFinal}
      className={isActive(linkFinal) ? "active" : ""}
      style={{
        color: isActive(linkFinal) ? "#2E3192" : "inherit",
        marginLeft: "20px",
        fontWeight: isActive(linkFinal) ? "bold" : "normal",
        borderBottom: `2px solid ${
          isActive(linkFinal) ? "#2E3192" : "transparent"
        }`,
      }}
    >
      {children}
    </Link>
  );
};

const MenuMobile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const URL = env("REACT_APP_URL");
  const dispatch = useDispatch();

  const { selectedLanguage, languageData, icon } = useSelector(
    (state: RootState) => state.language
  );

  const bg = useColorModeValue("white", "gray.800");
  const { user } = useAuthStore((state: any) => state);

  const handleLanguageChange: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const lang = event.currentTarget.getAttribute("data-value");
    dispatch(setSelectedLanguage(lang!));
  };

  useEffect(() => {
    const languageFromCookie = Cookies.get("language");
    const iconFromCookie = Cookies.get("icon");
    if (languageFromCookie && iconFromCookie) {
      dispatch(setSelectedLanguage(languageFromCookie));
    }
  }, [dispatch]);

  return (
    <Box bg={bg} boxShadow="md">
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <RouterLink to="/">
                <img src="/images/Langport-70x70nobg.png" alt="Langport" />
              </RouterLink>
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "none" }}
            >
              {Links?.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </HStack>
          </HStack>
        </Flex>

        {isOpen ? (
          <Box pb={4}>
            <Stack as={"nav"} spacing={4}>
              {Links?.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
              {!user?.id && (
                <>
                  <Button backgroundColor="#5b70f9" color="white">
                    <RouterLink to="/login">
                      {languageData.iniciar_sesion}
                    </RouterLink>
                  </Button>
                  <Button backgroundColor="#5b70f9" color="white">
                    <RouterLink to="/registrarse">
                      {languageData.registrarse}
                    </RouterLink>
                  </Button>
                </>
              )}
              {user?.id && (
                <RouterLink to="/dashboard">
                  <Button backgroundColor="#5b70f9" color="white" mr="1%">
                    {languageData.mi_perfil}
                  </Button>
                </RouterLink>
              )}
              <Button
                variant="ghost"
                onClick={handleLanguageChange}
                data-value="es"
                colorScheme={selectedLanguage === "es" ? "purple" : "gray"}
                fontWeight={selectedLanguage === "es" ? "bold" : "normal"}
                borderBottom={selectedLanguage === "es" ? "2px" : "none"}
                borderColor={
                  selectedLanguage === "es" ? "purple.500" : "transparent"
                }
              >
                <img
                  src={`${URL}images/flags/es.png`}
                  alt="Español"
                  width="35px"
                  style={{ marginRight: "10px" }}
                />
                Español
              </Button>
              <Button
                variant="ghost"
                onClick={handleLanguageChange}
                data-value="en"
                colorScheme={selectedLanguage === "en" ? "purple" : "gray"}
                fontWeight={selectedLanguage === "en" ? "bold" : "normal"}
                borderBottom={selectedLanguage === "en" ? "2px" : "none"}
                borderColor={
                  selectedLanguage === "en" ? "purple.500" : "transparent"
                }
              >
                <img
                  src={`${URL}images/flags/us.png`}
                  alt="English"
                  width="35px"
                  style={{ marginRight: "10px" }}
                />
                English
              </Button>
              <Button
                variant="ghost"
                onClick={handleLanguageChange}
                data-value="ua"
                colorScheme={selectedLanguage === "ua" ? "purple" : "gray"}
                fontWeight={selectedLanguage === "ua" ? "bold" : "normal"}
                borderBottom={selectedLanguage === "ua" ? "2px" : "none"}
                borderColor={
                  selectedLanguage === "ua" ? "purple.500" : "transparent"
                }
              >
                <img
                  src={`${URL}images/flags/ua.png`}
                  alt="Українська"
                  width="35px"
                  style={{ marginRight: "10px" }}
                />
                Українська
              </Button>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default MenuMobile;
