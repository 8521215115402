import React from "react";
import Ensenha from "./Ensenha/Ensenha";
import Habla from "./Habla/Habla";
import { Box } from "@chakra-ui/react";
import { perfilUsuario } from "../../../interfaces/TipoUsuario.interface";
import ProtectedRoute from "../../../components/ProtectedRoute";

const Idiomas = () => {
  const allowedTypes = [
    perfilUsuario.profesor,
    perfilUsuario.superAdministrador,
  ];
  return (
    <ProtectedRoute allowedTypes={allowedTypes}>
      <Box>
        <Ensenha />
        <Habla />
      </Box>
    </ProtectedRoute>
  );
};

export default Idiomas;
